import './Models.scss'
import Tree from "../Tree"
import React, { useCallback } from 'react'
import ColladaList from '../Models/ColladaList'
import Loading from '../Loading'
import { Catalog, CatalogItem } from '../../../../types'
import LocalData from '../../../../utils/LocalData'
import { fillItems } from '../../../../utils/functions'
import { useMainState } from '../../../../recoil/hooks'

const saveNewContents = (catalogs: Catalog[]) => {
  return catalogs.forEach(catalog => {
    const {items, uuid} = catalog
    LocalData[`catalog.${uuid}`] = items
  })
}
const updateContentFromSaved = (catalogs: Catalog[]) => {
  return catalogs.map(catalog => {
    const {items, uuid} = catalog
    const saved = (LocalData[`catalog.${uuid}`] || []) as CatalogItem[]
    items.splice(0, items.length, ...saved)
    return catalog
  })
}

const Models = () => {
  const { models, updateState } = useMainState()
  const update = useCallback(async (catalogs: Catalog[]) => {
    updateContentFromSaved(catalogs)
    updateState({ models: {...models} as Catalog })
    for (const catalog of catalogs) {
      try{ await fillItems(catalog) }
      catch(err){ console.log(err, 'CANNOT GET DATA') }
    }
    updateState({ models: {...models} as Catalog })
    saveNewContents(catalogs)
  }, [models])

  return (
    <div className="Models">
      {models ? <Tree update={update} component={ColladaList} catalogs={models.catalogs}/> : <Loading/>}
    </div>
  )
}

export default Models