import session from "./session"

export default (id: number) => {
  return {
    command: {
      sessionId: session,
      name: "SelectObject",
      id: "50",
      params: { id: `${id}` }
    }
  }
}
