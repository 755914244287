import './common';
import { Wood3DVisu } from './3d';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from '../views/App'
import '../css/app-member.scss';
const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

var Wood = {
    init: () => {
        Wood.setup_product_form();
        Wood.setup_cart_form();
        Wood.setup_order_form();
        Wood.setup_delivery_info();
        Wood.setup_filters();
        Wood.setup_download();
        Wood.setup_menu();
        Wood.setup_autocomplete();
        Wood.setup_effects();
        Wood.setup_order_history_filter();
        Wood.setup_3d_visu();
        Wood.setup_3d_builder();
    },
    setup_effects: () => {
        if($('.wconline').length == 0) {
            $('input').iCheck();
        }

        function formatState (state) {
          if (!state.id) {
            return state.text;
          }

          var $state = $(
            '<span><img class="img img-fluid float-left m-1" /> <span></span></span>'
          );

          // Use .text() instead of HTML string concatenation to avoid script injection issues
          $state.find("span").html(state.text);
          $state.find("img").attr("src", $(state.element).data('content'));

          return $state;
        };
        $('.select_me').select2({
            allowClear: true,
            placeholder: "",
            minimumResultsForSearch: -1,
            templateResult: formatState,
            templateSelection: formatState
        });
        $('.slider_kosmic').slick({
            rows: 0,
            slidesToShow: 4,
            appendArrows: $('.slider_kosmic'),
            prevArrow : '<a href="#" title="" class="slick-prev"><i class="fa fa-lg fa-angle-left pink"></i></a>',
            nextArrow : '<a href="#" title="" class="slick-next"><i class="fa fa-lg fa-angle-right pink"></i></a>'
        });

        $(document)
            .off('click', '.slider_kosmic .item')
            .on('click', '.slider_kosmic .item', function(e) {
                $(".main_img img").attr('src', $(e.currentTarget).data('src'));
            });

        $('.slider_base').slick({
            rows: 0,
            appendArrows: $('.slider_base'),
            prevArrow : '<a href="#" title="" class="left"><i class="fa fa-angle-left"></i></a>',
            nextArrow : '<a href="#" title="" class="right"><i class="fa fa-angle-right"></i></a>'
        });
        $('.slider_model').slick({
            rows: 0,
            appendArrows: $('.slider_model'),
            prevArrow : '<a href="#" title="" class="left"><i class="fa fa-angle-left"></i></a>',
            nextArrow : '<a href="#" title="" class="right"><i class="fa fa-angle-right"></i></a>',
          });
        $('#step6').on('shown.bs.collapse', function() {   
            $('.slider_model').slick('resize');
        });
        $('.slider_base_dots').slick({
            dots: true,
            rows: 0,
            infinite: false,
            appendDots: $('.navigation_slider'),
            appendArrows: $('.navigation_slider'),
            prevArrow: '<a href="#" title="" class="left"><i class="fa fa-angle-left"></i></a>',
            nextArrow: '<a href="#" title="" class="right"><i class="fa fa-angle-right"></i></a>'
        });

        for (let i = 0; i <= 4; i++) {
            $('.slider_base_dots'+i).slick({
                dots: true,
                rows: 0,
                infinite: false,
                appendDots: $('.navigation_slider'+i),
                appendArrows: $('.navigation_slider'+i),
                prevArrow: '<a href="#" title="" class="left"><i class="fa fa-angle-left"></i></a>',
                nextArrow: '<a href="#" title="" class="right"><i class="fa fa-angle-right"></i></a>'
            });
        }

        $('#lightgallery').lightGallery();
        for (let i = 0; i <= 20; i++) {
            $('#selector'+i).lightGallery({
                selector: '.item'
            });
        }

        $('.save-button').popover({
            container: 'body',
            title: 'Commentaire de Sauvegarde',
            html: true,
            placement: 'bottom',
            sanitize: false,
            content: function () {
                return $("#popoverContent").html();
            }
        });

        $('.import-3d-button').popover({
            container: 'body',
            title: 'Importer une scène',
            html: true,
            placement: 'bottom',
            sanitize: false,
            content: function () {
                return $("#popoverContent3d").html();
            }
        });
        $('.lost-button').popover({
            container: 'body',
            title: 'Commentaire',
            html: true,
            placement: 'bottom',
            sanitize: false,
            content: function () {
                return $("#popoverContent").html();
            }
        });
        $('[data-toggle="popover"]').popover();
        
        $(document)
            .on("ajaxSend", function(){
                $('.overlay').loading(true);
            })
            .on("ajaxComplete", function(){
                $('.overlay').loading(false);
            })
            .off('click', '.phone-menu-down')
            .on('click', '.phone-menu-down', (e) =>{
                e.preventDefault();
                $('#secondary_menu').slideToggle();
            })
            .off('click', '.btn-popover-close')
            .on('click', '.btn-popover-close', (e) => {
                $('[data-toggle="popover"]').popover('hide');
            })
            .off('click', '.toggle_me > .toggle h4')
            .on('click', '.toggle_me > .toggle h4', (e) => {
                e.preventDefault();
                $(e.currentTarget).parent().toggleClass('active');
            })
            .off('click', '.toggle_lien > h4')
            .on('click', '.toggle_lien > h4', (e) => {
                e.preventDefault();
                $(e.currentTarget).toggleClass('active');
                $(e.currentTarget).next().slideToggle();
            });    
    
        $('.datepicker').datepicker({
            language: "fr",
            daysOfWeekDisabled: "0,6",
            autoclose: true
        });

        // Custom select box
        $(document)
            .off('click', '.custom-select-wrapper')
            .on('click', '.custom-select-wrapper', (e) => {
                if(!$(e.target).hasClass("myautocomplete")) {
                    $(e.currentTarget).find('.select:not([disabled])').toggleClass('open');
                }
            })
            .on('click', null, (e) => {
                $('.select').each((i, elt) => {
                    if (!elt.contains(e.target) && !$(e.target).hasClass('myautocomplete')) {
                        $(elt).removeClass('open');
                    }
                });
            })
            .off('click', '.custom-option')
            .on('click', '.custom-option', (e) => {
                let option = $(e.currentTarget);
                if(!option.hasClass('selected')) {
                    option.parent().find('.custom-option').removeClass('selected');
                    option.addClass('selected');
                    option.closest('.select').find('.custom-select__trigger').html(option.html());
                    option.closest('.custom-select-wrapper')
                          .find('select option[value="'+option.data('value')+'"]')
                          .attr('selected', 'selected');
                    option.closest('.custom-select-wrapper')
                          .find('select').trigger('change');
                }
            })
            .off('keyup', '.myautocomplete')
            .on('keyup', '.myautocomplete', (e) => {
                let myautocomplete = $(e.currentTarget);
                myautocomplete.closest('.custom-options').find('.custom-option').each((i, elt) => {                    
                    if(!$(elt).text().toLowerCase().includes(myautocomplete.val().toLowerCase())) {
                        $(elt).hide();
                    } else {
                        $(elt).show();
                    }
                });
            })
            .off('change', '.custom-file-input')
            .on('change', '.custom-file-input', (e) => {
                var file = $(e.currentTarget)[0].files[0].name;
                $(e.currentTarget).next('label').text(file);
            });
    },
    setup_menu: () => {
        $(document)
            .off('click', '.icon_burger')
            .on('click', '.icon_burger', (e) => {
                e.preventDefault();
                $('#main_menu').slideToggle();
            })
            .off('click', '#icon_fermer')
            .on('click', '#icon_fermer', (e) => {
                e.preventDefault();
                $('#main_menu').slideToggle();
            })
            .off('click', '.menu_burger')
            .on('click', '.menu_burger', (e) =>{
                e.preventDefault();
                $('#main_menu').slideToggle();
                if( $('.menu_burger i').hasClass('burger')) {
                    $('.menu_burger i').removeClass('burger');
                    $('.menu_burger i').addClass('close');
                } else {

                    $('.menu_burger i').addClass('burger');
                    $('.menu_burger i').removeClass('close');
                }
            })
            .off('click', '.menu_burger_lg')
            .on('click', '.menu_burger_lg', (e) => {
                e.preventDefault();
                if($("#main_menu").hasClass('open')){
                    $("#main_menu").removeClass('open');
                    $("#main_menu").addClass('fermer');
                }else if($("#main_menu").hasClass('fermer')){
                    $("#main_menu").removeClass('fermer');
                    $("#main_menu").addClass('open');
                }
            });

        var widthScreen = $(window).width();
        if(widthScreen < 1200){
            return;
        }
        var p = $("#header-black .logo img").last();
        var offset = p.offset();
        $("#main_menu.open").width($("#main_menu").hasClass('open') ? offset.left + 100 : 81);
    },
    setup_cart_form: () => {
        $(document)
            .off('change', '.import-3d-file-input')
            .on('change', '.import-3d-file-input', (evt) => {
                $(evt.currentTarget).closest("form").submit();
            });
    },
    setup_order_form: () => {
        let isClicked = false;
        let fieldAddress = {'addressid': null , 'name': null , 'street': null , 'city': null , 'postalcode': null , 'country': null , 'comment': null };
        let fieldContact = {'id': null, 'civility': null, 'firstname': null, 'lastname': null, 'fonction': null, 'email': null, 'phone': null, 'cellphone': null, 'telecopy': null };
        
        $(document)
            .off('ifChanged', 'input[id^="order_deliveryMode_"]')
            .on('ifChanged', 'input[id^="order_deliveryMode_"]', (evt) => {
                let deliveryMode = $(evt.currentTarget);
                // Change event is triggered even for the unchecked radio button, ignore it
                if (!$(deliveryMode).is(':checked')) {
                    return;
                }
                let form = deliveryMode.closest('form');
                let data = {};

                $.each($(form).serializeArray(), function (i, input) {
                    data[input.name] = input.value;
                });

                // Disabled field are not submitted so manually bind delivery address fields
                $(Object.keys(fieldAddress)).each((i, field) => {
                    data[$('.delivery-'+field).attr('name')] = $('.delivery-'+field).val();
                });
                $.ajax({
                    url : form.attr('action'),
                    type: form.attr('method'),
                    data : data,
                    success: function(html) {
                        $('.order-create-form').html(html);
                        Wood.init();
                    }
                });
            })
            .off('click', '.btn-validate-address')
            .on('click', '.btn-validate-address', (evt) => {
                let deliveryName = $('#order_deliveryName');
                let deliveryStreet = $('#order_deliveryStreet');
                let deliveryPostalCode = $('#order_deliveryPostalCode');
                let deliveryCity = $('#order_deliveryCity');
                let deliveryCountry = $('#order_deliveryCountry');

                let error = false;
                if(!deliveryName[0].checkValidity()) {
                    deliveryName.css('border-color', 'red');
                    error = true;
                }
                if(!deliveryStreet[0].checkValidity()) {
                    deliveryStreet.css('border-color', 'red');
                    error = true;
                }
                if(!deliveryPostalCode[0].checkValidity()) {
                    deliveryPostalCode.css('border-color', 'red');
                    error = true;
                }
                if(!deliveryCity[0].checkValidity()) {
                    deliveryCity.css('border-color', 'red');
                    error = true;
                }
                if(!deliveryCountry[0].checkValidity()) {
                    deliveryCountry.css('border-color', 'red');
                    error = true;
                }

                if(!error) {
                    $('#deliveryName').html(deliveryName.val().toUpperCase());
                    $('#deliveryStreet').html(deliveryStreet.val());
                    $('#deliveryPostalCode').html(deliveryPostalCode.val());
                    $('#deliveryCity').html(deliveryCity.val().toUpperCase());
                    $('#deliveryCountry').html(deliveryCountry.val().toUpperCase());
                    $(Object.keys(fieldAddress)).each((i, field) => {
                        fieldAddress[field] = $('.delivery-'+field).val();
                    });

                    $('#addressModal').modal('hide');
                }
            })
            .off('click', '.btn-validate-contact')
            .on('click', '.btn-validate-contact', (evt) => {
                $('#deliveryContactCivility').html($('#order_deliveryContactCivility').val());
                $('#deliveryContactFirstname').html($('#order_deliveryContactFirstname').val());
                $('#deliveryContactLastname').html($('#order_deliveryContactLastname').val().toUpperCase());
                $('#deliveryContactFonction').html($('#order_deliveryContactFonction').val());
                $('#deliveryContactEmail').html($('#order_deliveryContactEmail').val());
                $('#deliveryContactPhone').html($('#order_deliveryContactPhone').val());
                $('#deliveryContactCellphone').html($('#order_deliveryContactCellphone').val());
                $('#deliveryContactTelecopy').html($('#order_deliveryContactTelecopy').val());
                $(Object.keys(fieldContact)).each((i, field) => {
                    fieldContact[field] = $('.deliverycontact'+field).val();
                });
                
                $('#contactModal').modal('hide');
            })
            .off('change', '.select-address')
            .on('change', '.select-address', (evt) => {
                let elt = $('.select-address option:selected');
                $(Object.keys(fieldAddress)).each((i, field) => {
                    $('.delivery-'+field).val(elt.data(field));
                    if(elt.data('default') == 1) {
                        $('.delivery-'+field).attr('disabled', 'disabled');   
                    } else {
                        $('.delivery-'+field).removeAttr('disabled');   
                    }
                });
            
                $('#newAddress').removeAttr('checked').iCheck('update');
                $('#newAddress').iCheck('uncheck');
            })
            .off('change', '.select-contact')
            .on('change', '.select-contact', (evt) => {
                let elt = $('.select-contact option:selected');
                $(Object.keys(fieldContact)).each((i, field) => {
                    $('.deliverycontact'+field).val(elt.data('deliverycontact'+field));
                });
            
                $('#newContact').removeAttr('checked').iCheck('update');
                $('#newContact').iCheck('uncheck');
            })
            .off('ifChanged', '#newAddress')
            .on('ifChanged', '#newAddress', (evt) => {
                // Change event is triggered even for the unchecked radio button, ignore it
                if (!$('#newAddress').is(':checked')) {
                    return;
                }
                $(Object.keys(fieldAddress)).each((i, field) => {
                    $('.delivery-'+field)
                        .val('')
                        .removeAttr('disabled');
                });
                $('.select-address').val('');
            })
            .off('ifChanged', '#newContact')
            .on('ifChanged', '#newContact', (evt) => {
                // Change event is triggered even for the unchecked radio button, ignore it
                if (!$('#newContact').is(':checked')) {
                    return;
                }
                $(Object.keys(fieldContact)).each((i, field) => {
                    $('.deliverycontact'+field)
                        .val('')
                        .removeAttr('disabled');
                });
                $('.select-contact').val('');
            })
            .off('click', '.btn-send')
            .on('click', '.btn-send', (evt) => {
                if(!$('#form-cgv')[0].reportValidity()) {
                    evt.preventDefault();
                    return false;
                }
                if(isClicked){
                    evt.preventDefault();
                    return false;
                }
                isClicked = true; 
            })
            .on('shown.bs.modal', '#addressModal', (e) => {
                $(Object.keys(fieldAddress)).each((i, field) => {
                    fieldAddress[field] = $('.delivery-'+field).val();
                });
            })
            .on('hidden.bs.modal', '#addressModal', (e) => {
                $('.select-address').val(fieldAddress['addressid']);
                if(fieldAddress['addressid'] != "") {
                    $('#newAddress').removeAttr('checked').iCheck('update');
                    $('#newAddress').iCheck('uncheck');
                } else {
                    $('#newAddress').attr('checked', 'checked').iCheck('update');
                    $('#newAddress').iCheck('check');
                }
                $(Object.keys(fieldAddress)).each((i, field) => {
                    $('.delivery-'+field).val(fieldAddress[field]);
                    if($('.select-address option:selected').data('default') == 1) {
                        $('.delivery-'+field).attr('disabled', 'disabled');
                    }
                });
            })
            .on('shown.bs.modal', '#contactModal', (e) => {
                $(Object.keys(fieldContact)).each((i, field) => {
                    fieldContact[field] = $('.deliverycontact'+field).val();
                });
            })
            .on('hidden.bs.modal', '#contactModal', (e) => {
                $('.select-contact').val(fieldContact['id']);
                if(fieldContact['id'] != "") {
                    $('#newContact').removeAttr('checked').iCheck('update');
                    $('#newContact').iCheck('uncheck');
                } else {
                    $('#newContact').attr('checked', 'checked').iCheck('update');
                    $('#newContact').iCheck('check');
                }
                $(Object.keys(fieldContact)).each((i, field) => {
                    $('.deliverycontact'+field).val(fieldContact[field]);
                });
            });
    },
    fieldHasError: (elt) => {
        if(!elt.checkValidity()) {
            if($(elt).prop("tagName") == 'SELECT') {
                $(elt).next().css('box-shadow', '0px 0px 5px red');
            } else if($(elt).attr('type') == 'radio') {
                $(elt).parent().css('box-shadow', '0px 0px 5px red');
            } else {
                $(elt).css('box-shadow', '0px 0px 5px red');
            }
            return true;
        } 

        if($(elt).prop("tagName") == 'SELECT') {
            $(elt).next().removeAttr('style');
        } else if($(elt).attr('type') == 'radio') {
            $(elt).parent().parent().find('div').removeAttr('style');
        } else {
            $(elt).removeAttr('style');
        }
        
        return false;
    },
    setup_product_form: () => {
        $(document)
            .off('click', '.btn-step')
            .on('click', '.btn-step', (evt) => {
                let form = $(evt.currentTarget).closest('form');
                Wood.handle_plinth_thickness();
                let error = false;
                form.find('.step'+step+' input, .step'+step+' select').each((i, elt) => {
                    if(Wood.fieldHasError(elt)) {
                        error = true;
                    }
                });

                if(error) {
                    return;
                }

                let data = {};
                $.each($(form).serializeArray(), function (i, input) {
                    data[input.name] = input.value;
                });

                $.ajax({
                    url: urlOrderItem,
                    data: data,
                    method: 'POST',
                    success: function (resp) {
                        $('#order-form').html(resp.html);
                        Wood.init();
                    }
                });
            })
            .off('click', '.btn-step-modify')
            .on('click', '.btn-step-modify', (evt) => {
                $.ajax({
                    url: urlResetOrderItem,
                    data: {
                        'step': $(evt.currentTarget).closest('.card-body').attr('id')
                    },
                    method: 'POST',
                    success: function (resp) {
                        $('#order-form').html(resp.html);
                        Wood.init();
                    }
                });
            })
            .off('change', '.model select, .matiere select, .poignee select')
            .on('change', '.model select, .matiere select, .poignee select', (evt) => {
                if($(evt.currentTarget).val() != '') {
                    Wood.send_update_form(evt, () => step == 5 ? $('.finishPoignee').removeClass('d-none') : '');
                }
            })
            .off('change ifChanged', '.refresh select, .slider_model input')
            .on('change ifChanged', '.refresh select, .slider_model input', (evt) => {
                if($(evt.currentTarget).attr('type') == "radio" && !$(evt.currentTarget).is(':checked')) {
                    // Change event is triggered even for the unchecked radio button, ignore it
                    return;
                }

                Wood.send_update_form(evt);
            })
            .off('change ifChanged', '.plinth select')
            .on('change ifChanged', '.plinth select', (evt) => {
                if($(evt.currentTarget).find('option:selected').data('code') == 'O') {
                    $('.thickness').removeClass('d-none');
                } else {
                    $('.thickness').addClass('d-none');
                }
            })
            .off('change ifChanged', 'form[name="order_item_facade_sur_mesure"] select, form[name="order_item_facade_sur_mesure"] input, form[name="order_item_facade_sur_mesure"] input[type="radio"]')
            .on('change ifChanged', 'form[name="order_item_facade_sur_mesure"] select, form[name="order_item_facade_sur_mesure"] input, form[name="order_item_facade_sur_mesure"] input[type="radio"]', (evt) => {

                Wood.fieldHasError(evt.currentTarget);
            })
            .off('change', '.width input, .height input, .length input')
            .on('change', '.width input, .height input, .length input', (evt) => { // Handle sizing
                
                let currentWidth = parseInt($('.width input').val());
                let currentHeight = parseInt($('.height input').val());
                let currentLength = parseInt($('.length input').val());
                
                let defaultWidth = null;
                if($('.width input').val()) {
                    defaultWidth = parseInt($('.width').attr('class').split(' ')
                                                                .filter(className => className.startsWith('def-'))[0]
                                                                .split('-')[1]);
                }

                // Height use only if product is Konsilio
                if($(evt.currentTarget).closest('.height').length > 0 && category != 'rangementKonsilio') {
                    return;
                }

                if(currentWidth > defaultWidth) {
                    // enlargment
                    $('.sizing select').val($('.sizing select option:nth-child(2)').val()).trigger('change');
                    let regexpPrice = /<strong>(.*)<\/strong>/;
                    let price = $('.sizing select option:nth-child(2)').text().match(regexpPrice)[1];
                    $('.sizing-price').text(' '+price);
                } else if(currentWidth == defaultWidth) {
                    // nothing
                    $('.sizing select').val('').trigger('change');
                    $('.sizing-price').text(' 0€ PP TTC');
                } else {
                    // reduction
                    $('.sizing select').val($('.sizing select option:last').val()).trigger('change');
                    $('.sizing-price').text(' 0€ PP TTC');
                } 

                $.ajax({
                    url: urlEcotax,
                    method: 'POST',
                    data: {
                        'width': currentWidth,
                        'height': currentHeight,
                        'length': currentLength,
                    },
                    success: function (html) {
                        $('.ecotax').html(html);
                    }
                });
            })
            .off('click', '[data-target^="#opt-"]')
            .on('click', '[data-target^="#opt-"]', (evt) => {
                if(!$(evt.currentTarget).hasClass('collapsed')) {
                    $(evt.currentTarget).next().find('select').select2('open');
                }
            })
            .on('click', 'button[type="submit"]', (evt) => {
                Wood.handle_plinth_thickness();
            })
            .on('submit', 'form', (evt) => {
                $(':disabled').each(function(e) {
                    $(this).removeAttr('disabled');
                });
                $('.overlay').loading(true);
            })
            .on("keydown", "form", function(evt) {
                if(evt.target.tagName == "TEXTAREA") {
                    return true;
                } 
                return evt.key != "Enter";
            });
        if($('.plinth select option:selected').data('code') == 'O') {
            $('.thickness').removeClass('d-none');   
        }
        if($('.poignee select').val() != '') {
            $('.finishPoignee').removeClass('d-none');
        }
    },
    send_update_form: (evt, callback) => {
        let data = {};
        let form = $(evt.currentTarget).closest('form');
        $.each($(form).serializeArray(), function (i, input) {
            data[input.name] = input.value;
        });

        data['refresh'] = true;

        $.ajax({
            url: urlOrderItem,
            data: data,
            method: 'POST',
            success: function (resp) {
                $('#order-form').html(resp.html);
                Wood.init();
                if(callback) {
                    callback();
                }
            }
        });
    },
    handle_plinth_thickness: () => {
        $('.thickness input').each((idx, elt) => {
            if($(elt).val() == 0) {
                $(elt).val('');
            }
        });
        
        let thicknessLeft = $('.thickness input').eq(0);
        let thicknessRight = $('.thickness input').eq(1); 

        // If plinth selected and both input are empty
        if("O" == $('.plinth select').find('option:selected').data('code') && 
            thicknessLeft.val() == "" && thicknessRight.val() == "") 
        {
            $('.thickness input').attr('required', 'required');
        } else {
            $('.thickness input').removeAttr('required');
        }
        if(thicknessLeft.val() != "" && (thicknessLeft.val() < 0 || thicknessLeft.val() > 20)) {
            thicknessLeft
                .attr('min', 0)
                .attr('max', 20);
        } 
        if(thicknessRight.val() != "" && (thicknessRight.val() < 0 || thicknessRight.val() > 20)) {
            thicknessRight
                .attr('min', 0)
                .attr('max', 20);
        } 
        setTimeout(function(){ 
            $('.thickness input').removeAttr('required'); 
            $('.thickness input').removeAttr('min'); 
            $('.thickness input').removeAttr('max'); 
        }, 3000);
    },
    setup_delivery_info: () => {
        let formName = 'order_delivery';
        $('.delivery .contact').on('click', (evt) => {
            let contact = $(evt.currentTarget);
            $(['deliveryContactCivility', 'deliveryContactFirstname', 'deliveryContactLastname', 'deliveryContactFonction', 'deliveryContactEmail', 
             'deliveryContactPhone', 'deliveryContactCellPhone', 'deliveryContactTelecopy']).each((i, elt) => {
                $('#'+formName+'_'+elt).val(contact.find('.'+elt).html()).trigger('change');
            });            
        });
        $('.delivery .address').on('click', (evt) => {
            let address = $(evt.currentTarget);
            $(['deliveryName', 'deliveryStreet', 'deliveryPostalCode', 'deliveryCity', 'deliveryCountry']).each((i, elt) => {
                $('#'+formName+'_'+elt).val(address.find('.'+elt).html()).trigger('change');
            });
        });
    },
    setup_filters: () => {
        $(document)
            .off('click', '.filter-delete')
            .on('click', '.filter-delete', (evt) => {
                $('[id^="filter-item-'+filterItem+'"]').trigger('click');
                evt.preventDefault();
                let filterItem = $(evt.currentTarget).data('id').toString();
                $('#filter-item-'+filterItem).iCheck('uncheck');
            })
            .off('ifChanged', '[id^="filter-item-"]')
            .on('ifChanged', '[id^="filter-item-"]', (evt) => {
                let filterItem = $(evt.currentTarget).val();
                let action = $(evt.currentTarget).is(':checked') ? 'add' : 'remove';
                handleFilter(action, filterItem);
            });

        function handleFilter(action, filterItem) {
            const urlParams = new URLSearchParams(window.location.search);
            var filterItems = urlParams.has('fi') ? urlParams.get('fi').split(',') : [];
            if($.inArray(filterItem, filterItems) != -1) {
                filterItems.splice($.inArray(filterItem, filterItems), 1);
            } else {
                filterItems.push(filterItem);
            }

            urlParams.delete('fi');
            urlParams.set('fi', filterItems);
            urlParams.set('page', 1);

            var newUrl = decodeURIComponent(`${window.location.pathname}?${urlParams}`);
            window.history.pushState('', '', newUrl);
            
            $.ajax({
                url: newUrl,
                method: 'POST',
                data: {
                    'action': action,
                    'filterItem': filterItem
                },
                success: function (response) {
                    $('.products').html(response.listBlock);
                    Wood.init();
                }
            });
        }
    },
    setup_download: () => {
        $(document)
            .off('click', '.btn-submit')
            .on('click', '.btn-submit', (evt) => {
                var searchKeyword = $('.input_keyword').val();

                $('.selectType').val('');
                $('.selectFamily').val('');
                $('.selectGamme').addClass('d-none'); 
                $('.selectGamme').val('');

                $.ajax({
                    url: urlDownloadSearch,
                    data: {
                        searchKeyword: searchKeyword
                    },
                    success: function (html) {
                        $('.results').html(html);
                        Wood.init();
                    }
                });
            })
            .off('change', '.selectType, .selectFamily, .selectGamme')
            .on('change', '.selectType, .selectFamily, .selectGamme', (evt) => {
                var searchType = $('.selectType').val();
                var searchFamily = $('.selectFamily').val();
                var searchGamme = $('.selectGamme').val();
                
                if($('.selectFamily').val() == "") {
                    $('.selectGamme').addClass('d-none'); 
                } else {
                    $('.selectGamme').removeClass('d-none');     
                }
                
                $('.input_keyword').val("");
                
                $.ajax({
                    url: urlDownloadSearch,
                    data: {
                        searchType: searchType,
                        searchFamily: searchFamily,
                        searchGamme: searchGamme
                    },
                    success: function (html) {
                        $('.results').html(html);
                        Wood.init();
                    }
                });
            })
            .off('change', '.selectFamily')
            .on('change', '.selectFamily', (evt) => {
                var searchFamily = $(evt.currentTarget).val();
                
                if($('.selectFamily').val() == "") {
                    $('.selectGamme').addClass('d-none'); 
                } else {
                    $('.selectGamme').removeClass('d-none');     
                }
                
                $('.input_keyword').val("");
                
                $.ajax({
                    url: urlGetGamme,
                    method: 'POST',
                    data: {
                        searchFamily: searchFamily,
                    },
                    success: function (html) {
                        $('.selectGamme').html('<option value="" disabled selected>Gamme</option>'+html);
                        Wood.init();
                    }
                });
            });
    },
    setup_autocomplete: () => {
        $('.autocomplete').autocomplete({hint: false}, [
            {
                source: function(query, cb) {
                    $.ajax({
                        url: urlSearch+'/'+query
                    }).then(function(data) {
                        cb(data.results);
                    });
                },
                templates: {
                    suggestion: function(suggestion, answer) {
                        return '<img src="'+suggestion.media+'" width="150" /> ' + suggestion.label;
                    }
                },
                displayKey: 'label',
                highlight: true,
                debounce: 500
            }
        ]).on('autocomplete:selected', function(event, suggestion, dataset, context) {
            window.location.href = urlShow.replace('-1', suggestion.id) + (isPieceDetacheeStandard ? '/pd' : '');
        });
    },
    setup_order_history_filter: () => {
        $(document)
            .off('change', '.order-history #status, .order-history #type')
            .on('change', '.order-history #status, .order-history #type', (evt) => {
                let field = $(evt.currentTarget).attr('id');

                let status = $('.order-history #status').val();
                let type = $('.order-history #type').val();

                const urlParams = new URLSearchParams(window.location.search);

                urlParams.set('status', status);
                urlParams.set('type', type);
                urlParams.set('page', 1);

                let url = decodeURIComponent(`${window.location.pathname}?${urlParams}`);
                window.history.pushState('', '', url);
                window.location.href = url;
            });
    },
    setup_3d_visu: () => {
        $(document)
            .off('click', '.button-3d')
            .on('click', '.button-3d', (evt) => {
                let elt = $(evt.currentTarget);
                $.ajax({
                    url: elt.data('href'),
                    success: function(response) {
                        new Wood3DVisu(response);
                    }
                });
            });
    },
    setup_3d_builder: () => {
        if(!document.getElementById('root')) {
            return;
        }
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(<App />);
    }
};

Wood.init();
$(window).resize(() => {
    Wood.setup_menu();
});