import 'admin-lte/plugins/iCheck/icheck.js';

global.iCheck = {
    init: () => {
        $('input[type="checkbox"], input[type="radio"]')
            .iCheck({
                checkboxClass: 'icheckbox_square-pink',
                radioClass: 'iradio_square-pink'
            })
            .on('ifToggled', function (e) {
                $(e.target).trigger('change');
            });
    }
};