import { Number3 } from '../../types/index';
import session from "./session"

export default (size: Number3) => {
  return {
    command: {
      sessionId: session,
      name: "SetElementSize",
      id: "90",
      params: {
        size: size.join(',')
      }
    }
  }
}
