import React, { Fragment, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import SpaceDimension from "../SpaceDimensions";
import SpacePosition from "../SpacePosition";
import SpaceOrientation from "../SpaceOrientation";
import clsx from "clsx";
import { Number3, Space, SpaceType } from "../../../../types";
import request from "../../../../utils/request";
import SelectObject from "../../../../utils/json/SelectObject";
import EditSpace from "../../../../utils/json/EditSpace";
import { PartType } from "../../../../types/parts";
import AddSpace from "../../../../utils/json/AddSpace";
import Delete from "../../../../utils/json/Delete";
import SpaceWrapper from "../SpaceWrapper";
import { useRecoilValue } from "recoil";
import { multipliedSize } from "../../../../utils/functions";
import { useMainState } from "../../../../recoil/hooks";
import { Popup } from "../../Three/Collada/Popup";
import './SpaceGround.scss'
import  { SpaceEditType } from '../../../../types'


type Props = {
  floorItem: Space,
}

const SpaceGround: React.FC<Props> = ({ floorItem }) => {
  const {updateConfiguration, updateComponent, selectedTarget, configuration, selected, updateState, measure: measure} = useMainState()

  const inputRef = useRef<HTMLInputElement>()
  const [activeTab, setActiveTab] = useState<SpaceEditType>()
  // const measure = useRecoilValue(measureState)
  // const [spaceValues, setSpaceValues] = useState<(string | number)[]>([])
  const spaceValues = useRef<(string | number)[]>([])
  
  const space = useMemo(() => {
    if (selected?.type === PartType.SPACE) return selected as Space
  }, [selected])

  const isSelected = useMemo(() =>  space?.id === selected?.id, [selected])

  const handleEnter = useCallback(async (e: KeyboardEvent<HTMLInputElement>, title: string) => {
    if (e.key === 'Enter') {
      if (space) {
        updateConfiguration(await request(EditSpace(
          title, space.spaceType, space.color,
          space.size, space.position, space.orientation
        )))
      }
    }
  }, [space])

  useEffect(() => {
    const switchSpace = async (e: any): Promise<void> => {
      if (space) {
        if (e.altKey) {
          e.preventDefault()
          e.stopPropagation()

          const cancel = Popup.waiting(`Transposition du ${space.spaceType === SpaceType.FLOOR ? 'sol en mur' : 'mur en sol'}`)
          const newType = space.spaceType === SpaceType.FLOOR ? SpaceType.WALL : SpaceType.FLOOR
          const res = await request(EditSpace(
            space.name, newType, space.color, multipliedSize(space), space.position, space.orientation
          ))
          cancel()
          updateConfiguration(res)
        }
      }
    }
    document.addEventListener('keydown', switchSpace)

    return () => document.removeEventListener('keydown', switchSpace)
  }, [space, space?.spaceType])

  useEffect(() => {
    spaceValues.current = [
      floorItem.name, floorItem.type, floorItem.color,
      ...floorItem.size, ...floorItem.position, ...floorItem.orientation
    ]
  }, [configuration?.parsingId])

  return (
    <SpaceWrapper
      onBlur={async () => {
        if (floorItem) {
          const newSize = floorItem.size.map((s, i) => Math.round(s * (floorItem.multiplier ? floorItem.multiplier[i] : 1))) as Number3
          const valueToCompare = [floorItem.name, floorItem.type, floorItem.color,
          ...newSize, ...floorItem.position, ...floorItem.orientation]

          const result = valueToCompare.reduce((acc, val, i) => {
            return acc || val !== spaceValues.current[i]
          }, false)

          if (result) {
            updateConfiguration(await request(EditSpace(
              floorItem.name as string, floorItem.spaceType as string,
              floorItem.color as string, newSize as Number3,
              floorItem.position as Number3, floorItem.orientation as Number3
            )))
          }
        }
      }}
      className="sub"
      onClick={async (e) => {
        if (!measure) {
          if (isSelected) {
            const cancel = Popup.waiting(`Sélection de "${floorItem.name}"`)
            const selection = await request(SelectObject(floorItem.id))
            if(selection) updateConfiguration(selection)
            cancel()
          }
        }
        else {
          Popup.error({
            message: `Impossible de sélectionner le sol`, duration: 3000
          })
        }
      }}
    >
      <div className={clsx("top-sub", { selected: selectedTarget === floorItem.id, confirmed: isSelected })}>
        <div className="sub-option-container">
          <input
            type="color"
            className='color-picker'
            readOnly={!space}
            value={floorItem.color}
            onChange={(e: any) => {
              space && updateComponent(space, {color: e.target.value})
            }}
            onBlur={async () => {
              if (isSelected && space) {
                updateConfiguration(await request(EditSpace(
                  space.name, space.spaceType, space.color,
                  space.size, space.position, space.orientation
                )))
              }
            }}
          />
          <input
            ref={ref => { if (ref) inputRef.current = ref }}
            onClick={() => inputRef.current?.select()}
            readOnly={!isSelected}
            onChange={(e) => {
              if (space && isSelected) {
                updateComponent(floorItem, {name: e.target.value})
              }
            }}
            onKeyDown={(e) => handleEnter(e, floorItem.name)}
            value={floorItem.name || ''}
            autoFocus
            className='sub-text input-sub-text'
            placeholder={selected?.name || ''}
          />
        </div>

        <div className='sub-option'>
          <div
            title="Cloner"
            className={clsx("sub-option-icon copy")}
            onClick={async () => {
              if (space) {
                const cancel = Popup.waiting('Clonage de l\'espace')
                updateConfiguration(await request(AddSpace(
                  space.name, space.spaceType, space.color,
                  space.size, space.position, space.orientation
                )))
                cancel()
                Popup.success('Espace cloné avec succès')
              }
            }}
          ></div>
          <div
            title="Supprimer"
            className={clsx("sub-option-icon delete")}
            onClick={async () => {
              const res = await request(Delete())
              updateConfiguration(res)
            }}
          ></div>
        </div>
      </div>

      {
        space && space.id === floorItem?.id &&
        <Fragment >
          <div className="edit-categories">
            <div className="rubriques">
              <div
                className={clsx("rubrique-item", { 'active': activeTab === SpaceEditType.Position })}
                onClick={() => setActiveTab(SpaceEditType.Position)}>Positions</div>
              <div
                className={clsx("rubrique-item", { 'active': activeTab === SpaceEditType.Dimension })}
                onClick={() => setActiveTab(SpaceEditType.Dimension)}>Dimensions</div>
              <div
                className={clsx("rubrique-item", { 'active': activeTab === SpaceEditType.Orientation })}
                onClick={() => setActiveTab(SpaceEditType.Orientation)}>Orientations</div>

            </div>
          </div>


          {activeTab === SpaceEditType.Dimension &&
            <SpaceDimension />}
          {activeTab === SpaceEditType.Position &&
            <SpacePosition />}
          {activeTab === SpaceEditType.Orientation &&
            <SpaceOrientation />}
        </Fragment>
      }
    </SpaceWrapper>
  )
}

export default SpaceGround