import React, { useEffect, useState } from "react";
import './Disconnected.scss'
import request, { Request } from "../../../utils/request";
import { useMainState } from "../../../recoil/hooks";
import OpenConfiguration from "../../../utils/json/OpenConfiguration";
import { Popup, PopupType } from "../Three/Collada/Popup";

export const Disconnected = {
  unConnect: (data: string) => {},
}

const DisconnectedComponent: React.FC = () => {
  const {updateConfiguration} = useMainState()
  const [data, setData] = useState('')
  useEffect(() => {
    Disconnected.unConnect = (data: string) => {
      setData(data)
    }
  }, [])
  useEffect(() => Popup.globalize(!!data), [data])

  return data ? <div className="DisconnectedContainer">
      <div className="Disconnected">
        {<h3>Vous avez été déconnecté(e) de votre session</h3>}

        <div className="disconnected-btns">
          <div className="di-btn"
            onClick={(e) => {
              const div = e.target as HTMLDivElement
              Request.init()
                const cancel = Popup.popup({
                message: `Chargement de votre configuration`, type: PopupType.WAITING
              })
              div.style.pointerEvents = 'none'
              request(OpenConfiguration(new Date().getTime() + "", data)).then(async res => {
                updateConfiguration(res)
                cancel()
                setData('')
                div.style.pointerEvents = ''
              })
            }}
          >Ré-ouvrir et continuer le projet</div>
          <div className="di-btn"
            onClick={() => {
              const queryParameters = new URLSearchParams(window.location.search);
              const codprojet = queryParameters.get("codprojet");
              window.parent.postMessage(
                {
                  type: 'fermerIframeWCO',
                  codprojet: codprojet,
                  save: false,
                  haserror: false
                }, "*"
              )
              setData('')
            }}
          >Fermer l'utilitaire</div>
        </div>
      </div>
    
  </div> : null
}

export default DisconnectedComponent