import session from "./session"

export default () => {

  return {
    command: {
      sessionId: session,
      name: "GetMachinedParts",
      id: "730"
    }
  }
}