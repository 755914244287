import session from "./session"

export default (finish: any) => {
  return {command: {
    sessionId : session,
    name: "SetSelectionFinish",
    id: "570",
    params: {
        finish: finish
    }
  }}
}

