import React, { useEffect, useMemo, useRef } from "react"

type Props = Omit<JSX.IntrinsicElements['div'], 'onFocus' | 'onBlur'> & {
  children: React.ReactNode,
  onBlur?: () => void,
  onFocus?: () => void,
}

const Focusable: React.FC<Props> = ({ children, onBlur, onFocus,  ...props }) => {
  const ref = useRef<HTMLDivElement>(null)
  const activeRef = useRef(false)
  const handlerRef = useRef({onBlur, onFocus})

  useEffect(() => { handlerRef.current = {onBlur, onFocus} }, [onBlur, onFocus])
  
  useEffect(() => {
    document.addEventListener('focusin', () => {
      const ae = document.activeElement
      if(ae && document.body !== ae){
        const active = ref.current?.contains(ae)
        if(active){
          if(!activeRef.current){
            activeRef.current = true
            handlerRef.current.onFocus && handlerRef.current.onFocus()
          }
          const onblur = (ev: any) => {
            ae.removeEventListener('blur', onblur)
            if(!ref.current?.contains(ev.relatedTarget)){
              activeRef.current = false
              handlerRef.current.onBlur && handlerRef.current.onBlur()
            }
          }
          ae.addEventListener('blur', onblur)
        }
      }
    }, true)
  }, [])

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  )
}

export default Focusable;