import React from "react"
import HeadOption from "../HeadOption"
import './Design.scss'
import { useMainState } from "../../../../recoil/hooks"


type Props = {
  active: boolean,
  className?: string
}

type Design = {
  grid: boolean,
  zones: boolean,
  transparent: boolean
}

const Design: React.FC<Props> = (props) => {
  const {transparents, updateState, configuration, grids, hidden, zones, sky} = useMainState()

  return (
    <HeadOption
      title="Design"
      className={'Design'}
      active={props.active}
    >
      <div className="d-options">
        <label>
          <input
            type="checkbox"
            checked={!hidden.length}
            onChange={(e) => {
              const checked = (e.target as HTMLInputElement).checked
              if(checked){
                updateState({ hidden: [] })
              } else if(configuration) {
                updateState({ hidden: configuration.components.map(({id}) => id) })
              }
            }}
          />
          <span>AFFICHER TOUT</span>
        </label>
        <label>
          <input
            type="checkbox"
            checked={grids}
            onChange={e => {
              const checked = (e.target as HTMLInputElement).checked
              updateState({grids: checked})
            }}
          />
          <span>{grids ? "CACHER" : "AFFICHER"} LA GRILLE</span>
        </label>
        <label>
          <input
            type="checkbox"
            checked={zones}
            onChange={e => {
              const checked = (e.target as HTMLInputElement).checked
              updateState({zones: checked})
            }}
          />
          <span>{zones ? "CACHER" : "AFFICHER"} LES ZONES</span>
        </label>
        <label>
          <input
            type="checkbox"
            checked={transparents.length ? false : true}
            onChange={(e) => {
              const checked = (e.target as HTMLInputElement).checked
              if(checked){
                updateState({ transparents: [] })
              } else if(configuration) {
                updateState({ transparents: configuration.components.map(({id}) => id) })
              }
            }}
          />
          <span>OPAQUE / TRANSPARENT</span>
        </label>
        <label>
          <input
            type="checkbox"
            checked={sky}
            onChange={e => {
              const checked = (e.target as HTMLInputElement).checked
              updateState({sky: checked})
            }}
          />
          <span>{sky ? "ENLEVER" : "AFFICHER"} LE CIEL</span>
        </label>
      </div>
    </HeadOption>
  )
}

export default Design