import session from "./session"

export default (code: string, name: string) => {
  return {
    command: {
      sessionId : session,
      name: "AddComponent",
      id: "320",
      params: {
          catalogName: name,
          componentMetaCode: code
      }
  }
  }
}
