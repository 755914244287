import { Number3 } from "../../types";
import session from "./session";

export default (name: string, spaceType: string, color: string, size: Number3, position: Number3, orientation: Number3) => {
  return {
    command: {
      sessionId: session,
      requestId: "req01",
      name: "EditSpace",
      id: "610",
      params: {
        name, spaceType, color,
        position: position.toString(),
        size: size.toString(),
        orientation: orientation.toString()
      }
    }
  }
}