import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Component, MachinedPiece, Number3, Zone } from "../../../../../types";
import ZoneComponent from "./Zones";
import Part from "./Parts";
import { Euler, Group } from "three";
import Accessory from "./Accessory";
import { degToRad } from "three/src/math/MathUtils";
import { useMainState } from "../../../../../recoil/hooks";
import { useRecoilValue } from "recoil";
import { partPiecesState } from "../../../../../recoil/partPiecesState";
import Machined from "./Machined";
import Connector from "./Connector";
import { PartType } from "../../../../../types/parts";


type Props = {
  component: Component,
  groupPosition: Number3,
  isModulaire?: boolean
}

export type Pos = { x: number, y: number }

const Components: React.FC<Props> = ({ component, groupPosition, isModulaire }) => {

  const { zones: showZones, configuration, areParented, hidden,grids } = useMainState()
  const groupRef = useRef<Group>()
  const groupInvRef = useRef<Group>()
  const resettingAngle = (isModulaire ? [0, 0, 0] : component.orientation.map(angle => degToRad(-angle))) as Number3
  const piecesPart = useRecoilValue(partPiecesState)



  const { id, parts, zones, components, accessories, connectors, parentItem, multiplier } = component
  const { position } = parentItem as Zone

  const setRAngle = useCallback((rAngle = 0) => {
    groupRef.current?.rotation.set(0, 0, rAngle)
  }, [])

  useEffect(() => {
    setRAngle()
    if (groupRef.current && groupInvRef.current) {
      const pivotCtrlAngle = (isModulaire ? [0, 0, 0] : component.orientation.map(degToRad)) as Number3
      const resettingAngle = (isModulaire ? [0, 0, 0] : component.orientation.map(angle => degToRad(-angle))) as Number3
      // groupRef.current.rotation.set(0, 0, 0)
      // groupInvRef.current.rotation.set(0, 0, 0)

      groupRef.current.rotation.set(...pivotCtrlAngle)
      groupInvRef.current.rotation.set(...resettingAngle)
    }
  }, [component, configuration])

  const currentPartPieces = useMemo(() => {
    return(piecesPart as MachinedPiece[]).filter(piece => piece.component === id)
  }, [piecesPart, configuration])

  return (
    <group
      name={id + ''}
      type={PartType.COMPONENT}
      ref={groupRef as React.Ref<Group>}
      castShadow={grids}
      receiveShadow={grids}
      scale={multiplier ? multiplier : [1, 1, 1]}
      position={[(position[0] - groupPosition[0]), (position[1] - groupPosition[1]), position[2] - groupPosition[2]]}
    >
      <group
        ref={groupInvRef as React.Ref<Group>}
        rotation={new Euler(...(isModulaire ? [0, 0, 0] : resettingAngle))}
      >
        <group>
          {
            currentPartPieces && currentPartPieces.map((piece) => {
              const { id, component } = piece
              return <Machined key={id} componentId={component} piece={piece} groupPosition={position} />
            })
          }
        </group>
        {
          (parts.filter(part => {
            return currentPartPieces? !areParented(part.id, piecesPart.map(({ id }) => id)) : true
          })).map((part, key) => {
            return <Part key={key} groupPosition={position} id={id} part={part} />
          })
        }
        {
          showZones && zones.map((zone, key) => {
            return zone.visible && zone.selectable && <ZoneComponent key={key} groupPosition={position} zone={zone} />
          })
        }
        <group>
          {
            accessories.map((accessory, key) => {
              return <Accessory key={key} accessory={accessory} groupPosition={position} />
            })
          }
        </group>
        <group>
          {
            connectors.map((connector, key) => <Connector key={key} connector={connector} groupPosition={position} />)
          }
        </group>
        {
          components && components.filter(comp => !areParented(comp.id, hidden)).map(component => <Components isModulaire key={component.id} component={component} groupPosition={position} />)
        }
      </group>
    </group>
  )
}

export default Components