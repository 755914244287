import clsx from "clsx"
import SimpleInput from "../SimpleInput"
import React from "react"

type NIProps = Omit<JSX.IntrinsicElements['input'], 'onChange'> & {
  onChange?: (value: number) => void
  value?: number
} 
const NumberInput: React.FC<NIProps> = (props: NIProps) => {
  return <SimpleInput
    {...props}
    className={clsx("NumberInput", props.className)}
    onChange={(value: string) => {
      props.onChange && props.onChange(parseFloat(value))
    }}
    value={props.value || 0}
  />
}

export default NumberInput