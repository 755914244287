import session from "./session";

export default (uuid: string) => {
  return {
    command: {
      sessionId : session,
      name: "GetComponentListFromNode",
      id: "530",
      params: { uuid, type: "Component"}
    }
  }
}
