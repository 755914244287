import clsx from "clsx"
import React from "react"

type SIProps = Omit<JSX.IntrinsicElements['input'], 'onChange'> & {
  onChange?: (value: string) => void
  value?: any
} 
const SimpleInput: React.FC<SIProps> = (props: SIProps) => {
  return <input
    {...props}
    className={clsx("SimpleInput", props.className)}
    onChange={(e) => {
      props.onChange && props.onChange(e.target.value)
    }}
    value={props.value || ''}
  />
}

export default SimpleInput