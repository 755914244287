import React, { useEffect, useMemo, useRef } from "react";
import { Number3, MachinedPiece } from "../../../../../../types";
import * as THREE from 'three'
import { useLoader } from "@react-three/fiber";
import { ColladaLoader } from "three/examples/jsm/loaders/ColladaLoader";
import { PartType } from "../../../../../../types/parts";
import { useMainState } from "../../../../../../recoil/hooks";
import { getCloseColor, urlModifier } from "../../../../../../utils/functions";

type Props = {
  componentId: number,
  piece: MachinedPiece,
  groupPosition: Number3
}


const Machined: React.FC<Props> = ({ piece, groupPosition }) => {
  const { transparents, areParented, selected, selectedTarget, grids } = useMainState()
  const { collada, id } = piece
  const ref = useRef<THREE.Mesh>()

  const one = useLoader(ColladaLoader, collada, loader => {
    loader.manager.setURLModifier(urlModifier)
  })
  const { scene } = one

  const sceneClone = useMemo(() => scene.clone(), [scene])

  useEffect(() => {
    // RESET
    ref.current!.position.set(0, 0, 0)
    ref.current!.rotation.set(0, 0, 0)
    ref.current!.scale.set(1, 1, 1)
    // //
    // const m = matrix ? toMatrix4(matrix) : new THREE.Matrix4()
    // const m0 = matrixForTranslation([0, 0, 0])
    // ref.current!.applyMatrix4(m0)
    // ref.current!.applyMatrix4(m)

  }, [piece])

  useEffect(() => {
    const isRed = selectedTarget && areParented(piece.id)
    const isTransparent = areParented(piece.id, transparents)
    ref.current?.traverse(child => {
      if (child instanceof THREE.Mesh && child?.material) {
        const { material } = child
        const materials = Array.isArray(material) ? material : [material]
        for (let i = 0; i < materials.length; i++) {
          materials[i].needsUpdate = false
          if (!materials[i].name.startsWith('#')) {
            const defColor = '#' + materials[i].color.getHexString()
            const rededColor = getCloseColor(defColor, "#ffa7df", .65)
            materials[i].name = defColor + '|' + rededColor
          }
          // color
          materials[i].color.setStyle(materials[i].name.split('|')[isRed ? 1 : 0])
          // transparent
          materials[i].transparent = isTransparent
          materials[i].opacity = isTransparent ? .4 : 1

          materials[i].needsUpdate = true
        }
      }
    })
  }, [selected, selectedTarget, transparents])

  return (
    <group
      type={PartType.MACHINED}
      name={id + ''}
      castShadow={grids}
      receiveShadow={grids}

      position={[-groupPosition[0], -groupPosition[1], - groupPosition[2]]}
    >
      <primitive
        type={PartType.MACHINED}
        ref={ref}
        object={sceneClone}
      />
    </group>
  )
}

export default Machined