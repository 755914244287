import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.fr.js';
import 'slick-carousel';
import 'select2'; 
import 'autocomplete.js/dist/autocomplete.jquery.js'; 
import 'autocomplete.js/dist/autocomplete.js'; 
import './lightGallery';
import './icheck';
import './overlay';

$(document)
    .on('change', '.input_file', (e) => {
        var fileName = '';
        var label = $(e.currentTarget).next();
        if($(e.currentTarget).files && $(e.currentTarget).files.length > 1) {
            fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
        } else {
            fileName = e.target.value.split( '\\' ).pop();
        }

        if(fileName) {
            label.html(fileName);
        }
    });

$('.toggle_me > .toggle h3').click((elt) => {
    elt.preventDefault();
    $(elt.currentTarget).parent().toggleClass('active');
});

$('.toggle_lien > h3').click((elt) => {
    elt.preventDefault();
    $(elt.currentTarget).toggleClass('active');
    $(elt.currentTarget).next('ul').slideToggle();
});

$('.toggle_lien h4 i').click((elt) => {
    elt.preventDefault();
    $(elt.currentTarget).parent().toggleClass('active');
    $(elt.currentTarget).parent().next('ul').slideToggle();
});

$('li.toggle_lien a').click((elt) => {
    elt.preventDefault();
    $(elt.currentTarget).parent().toggleClass('active');
    $(elt.currentTarget).parent().find('>ul').slideToggle();
});