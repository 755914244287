import clsx from 'clsx'
import React, { CSSProperties } from 'react'
import { CatalogItem } from '../../../../../types'
import { pubUrl } from '../../../../../utils/functions'
import AddComponent from '../../../../../utils/json/AddComponent'
import request from '../../../../../utils/request'
import './ModuleList.scss'
import { Popup, PopupType } from '../../../Three/Collada/Popup'
import { useMainState } from '../../../../../recoil/hooks'
import { Pieces } from '../../..//Three/Cube/Components/Parts/partPiecesUtils'

type Props = { items: CatalogItem[] }

const ModuleList: React.FC<Props> = ({ items })  => {
  const { updateConfiguration } = useMainState()

  return (
    <div className='ModuleList'>
      {
        items.map(({ code, image, name }, index) => {
          return (
            <div
              key={index}
              className={clsx('module-item')}
              onClick={async (e) => {
                const div = (e.target as HTMLDivElement).closest('.module-item') as HTMLDivElement
                div.classList.add('loading')
                // const cancel = popup({message: "Ajout du composant"})
                const cancel = Popup.popup({
                  message: 'Ajout du composant',
                  type: PopupType.WAITING
                })
                const state = updateConfiguration(await request(AddComponent(code, name)))
                if(state){
                  Pieces.update(state.configuration!)
                }
                cancel()
                div.classList.remove('loading')
              }}
            >
              <div className="ci-container" style={{ '--image': `url(${pubUrl(image)})` } as CSSProperties}>
                <span>{code}</span>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default ModuleList