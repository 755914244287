import session from "./session"

export default (technical: boolean) => {
  return {
    command: {
      sessionId: session,
      name: "GetProductStructure",
      id: "540",
      params: {
        structureType: technical? "Technical" : "Commercial",
      }
    }
  }
}