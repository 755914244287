import clsx from "clsx"
import './ValuesPart.scss'
import React from "react"

type ValuesProps = Omit<JSX.IntrinsicElements['div'], 'onChange'> & {
  values: string[]
  value: string
  onChange: (value: string) => void
}

const ValuesPart: React.FC<ValuesProps> = (props: ValuesProps) => {
  const {value, values, onChange  } = props
  return (
    <React.Fragment>
      {
        values.map((val, i) => {
          return (
            <div
              key={i}
              className={clsx("value-part", { 'active': val == value })}
              onClick={() => {
                onChange(val)
              }} 
            >
              {val}
            </div>
          )
        })
      }
    </React.Fragment>
  )
}

export default ValuesPart