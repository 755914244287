import React, { useCallback, useEffect, useState } from 'react'
import './PriceInput.scss'
import GetProductStructure from '../../../utils/json/GetProductStructure'
import request from '../../../utils/request'
import { useMainState } from '../../../recoil/hooks'
import { Pieces } from '../Three/Cube/Components/Parts/partPiecesUtils'

export const Price = {
  update: async () => console.error('Price not mounted yet')
}

const PriceInput: React.FC = () => {
  const { configuration } = useMainState()
  const [price, setPrice] = useState<String>('')
  const update = useCallback(async () => {
    const price = await getPrice()
    setPrice(price)
  }, [])
  useEffect(() => {
    Price.update = async () => await update()
  }, [configuration])

  useEffect(() => {
    if(configuration?.components.length) {
      Pieces.update(configuration!)
    }
    else {
      setPrice('')
      Pieces.update(configuration!)
    }
  }, [configuration?.components.length])

  return (
    <div className="PriceInput">
      <span>Prix Tarif HT: </span>
      <div className="input-field">
        <input readOnly value={price ? `${price} €`: ""} type="text"></input>
        <div
          onClick={async (e) => {
            const div = e.target as HTMLDivElement
            div.classList.add('loading')
            await update()
            div.classList.remove('loading')
          }}
          className="reload"
        />
      </div>
      <div className='sizer'>{price ? `${price} €`: ""}</div>
    </div>
  )
}

const getPrice = async () => {
  const data: any = (await request(GetProductStructure(false)) as any).comProductStructure
  try{
    const response = await fetch("/api/3d/price", {
      method: "POST",
      headers: { "Content-Type": "application/json"},
      body: JSON.stringify(data),
    })
    if(!response) return ""
    const result = await response.json();
    if(!result.price) { // Debug info for dominique - DON'T REMOVE
    }
    return result.price ? toAmount((result.price as Number).toFixed(2)) : ""
  } catch(e){
    console.warn('Cannot get price : maybe we`re not into WoodCMS')
    return ""
  }
}

const toAmount = (amount: String) => {
  var parts = amount.split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  return parts.join(",")
}

export default PriceInput