import React from 'react'
import './SidePane.scss'

type Props = {
  children?: React.ReactNode,
  title: string
}

const SidePane: React.FC<Props> = (props) => {
  const { title, children } = props
  return (
    <div tabIndex={0} className='SidePane'>
      <div className="pane-header">{title}</div>
      <div className="pane-content">
        {children}
      </div>
    </div>
  )
}

export default SidePane