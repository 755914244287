
import { useLoader } from "@react-three/fiber";
import React from "react";
import * as THREE from "three";
import sky from "../../../../../wcassets/background_images/sky3.jpg";

const Sky = () => {
  const texture = useLoader(THREE.TextureLoader, sky)
  texture.encoding = THREE.sRGBEncoding
  return (
    <mesh position={[0, 0, 0]} name="sky" rotation={[- Math.PI / 2, 0, 0]}>
      <sphereGeometry attach="geometry" args={[30000, 10, 10]} />
      <meshBasicMaterial
        attach="material"
        map={texture}
        side={THREE.DoubleSide}
      />
    </mesh>
  )
}

export default Sky;
