import clsx from 'clsx'
import React, { CSSProperties } from 'react'
import './FinishList.scss'
import { CatalogItem } from '../../../../../types'
import SetSelectionFinish from '../../../../../utils/json/SetSelectionFinish'
import request from '../../../../../utils/request'
import { useMainState } from '../../../../../recoil/hooks'
import { Price } from '../../../PriceInput'
import { Pieces } from '../../../Three/Cube/Components/Parts/partPiecesUtils'
import SetGlobalFinish from '../../../../../utils/json/SetGlobalFinish'

type Props = { items: CatalogItem[] }

const FinishList : React.FC<Props> = ({ items }) => {
  const { updateConfiguration, configuration, selected } = useMainState()
  return (
    <div className='FinishList'>
      {
        items.map(({ code, path, name }, index) => {
          return (
            <div
              key={code + index}
              className={clsx('finish-item')}
              onClick={async (e) => {
                const div = (e.target as HTMLDivElement).closest('.finish-item') as HTMLDivElement
                div.classList.add('loading')
                const res = await request((selected ? SetSelectionFinish : SetGlobalFinish)(code))
                // await Price.update()
                Pieces.update(configuration!)
                updateConfiguration(res)
                div.classList.remove('loading')
              }}
              title={name || ''}
            >
              <div className="ci-container" style={{ borderRadius: '2px', '--image': `url(${path})`} as CSSProperties}>
                <div className="icon"></div>
                <span>{code}</span>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default FinishList