import session from "./session"

export default (range = "WoodConfigurator online") => {
  return {
    command: {
      sessionId: session,
      name: "CreateNewConfiguration",
      id: "10",
      params: {
        range
      }
    }
  }
}