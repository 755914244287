import React from "react";
import { Number3 } from "../../../types";

type Props = {
  angle: number,
  intensity: number,
  castShadow: boolean,
  position: Number3
}

const Light: React.FC<Props> = ({ angle, castShadow, position, intensity }) => {
  return (
    <spotLight
      castShadow={castShadow}
      shadow-mapSize-height={9028}
      shadow-mapSize-width={9028}
      shadow-camera-near={100}
      shadow-camera-far={30000}
      shadow-shadowBias={ -.000001}
      intensity={intensity}
      // color={''}
      penumbra={1}
      angle={angle}
      position={position}
    />
  )
}

export default Light