import { atom, selector } from "recoil";
import { Vector3 } from "three";

export const measureState = atom<Vector3[]>({
	key: 'measureState',
	default: []
})

export const measureValue = selector<number | undefined>({
	key: 'measureValue',
	get: ({ get }) => {
		const points = get(measureState)
		if (points.length === 2) {
			const [point1, point2] = points
			const a = new Vector3(point1.x, point1.y, point1.z)
			const b = new Vector3(point2.x, point2.y, point2.z)
			return a.distanceTo(b)
		}
		return undefined
	}
})

// export const measureControls = selector<Vector3[]>({
// 	key: 'measureControls',
// 	get:({get}) => get(measureState),
// 	set: ({set, get}, newPoints: Vector3[]) => {
// 		set(measureState, [...newPoints])
// 	}
// })