import React from "react";
import './TitleHeader.scss'
import { Action } from "../../../../types";
import { useMainState } from "../../../../recoil/hooks";

type Props = {
  text: string,
  background?: string,
}

const TitleHeader: React.FC<Props> = ({ text, background }) => {
  const { updateState } = useMainState()
  return (
    <div className="Title-Header">
      <div className="header-part">
        <div className="close"
          onClick={ () => updateState({ activePopup: Action.EMPTY })}></div>
        <div className="header-text">{text}</div>
      </div>
      <div
        className="header-icon"
        style={{ backgroundImage: `url(${background})` }}></div>
    </div>
  )
}


export default TitleHeader;