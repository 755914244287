import React, { useMemo } from "react";
import { Number3, Space } from "../../../../types";
import './SpacePosition.scss'
import { useMainState } from "../../../../recoil/hooks";


type Props = {
}

const labels = 'xyz'

const SpacePosition: React.FC<Props> = () => {
  const { updateComponent, selected, configuration } = useMainState()

  const space = useMemo(() => {
    if (selected?.type === 'SPACE') return selected as Space
  }, [selected])

  return (
    <div className="edit-zone">
      {
        space && space.position.map((pos, key) => <div key={key} className="edit-item">
          <label className='item-label'>{labels[key]}</label>
          <div className="inputs">
            <input
              type="number"
              className='item-input'
              readOnly={!space}
              value={space.position[key] || ''}
              placeholder="0"
              onChange={({ target: { value } }) => {
                const pos = [...space.position] as Number3
                pos[key] = parseInt(value) || 0

                // selected && setConfig(config => config ? ({...config, items: {
                //   ...config.items,
                //   [selected]: {
                //     ...config.items[selected], position: pos
                //   }
                // }}) : config)

                updateComponent(space, { position: pos })
              }}
            />
          </div>
        </div>)
      }
    </div>
  )
}

export default SpacePosition