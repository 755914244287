import React, { useEffect, useMemo, useState } from "react";
import AddProduct from "../../../../utils/json/AddProduct";
import request from "../../../../utils/request";
import Component from "./Components";
import { useThree } from "@react-three/fiber";
import { OrthographicCamera } from "@react-three/drei";
import { Mesh, Vector3 } from "three";
import Measure from "./Components/Measure";
import DAE from "./Components/DAE";
import { Popup, PopupType } from "../Collada/Popup";
import { useMainState } from "../../../../recoil/hooks";
import Spaces from "./Components/Space";
import Transforms from "./Components/Transforms";
import { Pieces, updatePiecesPart } from "./Components/Parts/partPiecesUtils";
import Configuration from "../../../../types/configuration";
import { useSetRecoilState } from "recoil";
import { partPiecesState } from "../../../../recoil/partPiecesState";
import { Number3, Vertices, Zone } from "../../../../types";
import { calculateRectangleVertices } from "../Collada/utilsForSnap";
import { degToRad } from "three/src/math/MathUtils";
import { positionAssignedState } from "../../../../recoil/contextState";
import { Price } from "../../PriceInput";

export type Validator = {
  name: string,
  code: string,
  x: number,
  y: number,
  z: number,
  zAngle: number,
  dae?: Mesh
}

const Cube = () => {
  const { updateState, updateConfiguration, configuration, hidden, cameraPos, measure: measureMode } = useMainState()
  const [validate, setValidate] = useState<Validator | undefined>()
  const { camera, scene } = useThree()
  const piecesPartUpdater = useSetRecoilState(partPiecesState)
  const setPositionAssigned = useSetRecoilState(positionAssignedState)

  useEffect(() => {
    if (validate) {
      const cancel = Popup.popup({ message: "Ajout du composant", type: PopupType.WAITING })
      request(AddProduct(validate)).then(data => {
        updateConfiguration(data)
        updateState({ daeWardrobe: undefined })
        cancel()
      })
    }
  }, [validate])

  useEffect(() => {
    console.log({ configuration })
  }, [configuration])

  useEffect(() => {
    if (cameraPos) {
      const { x, y, z } = cameraPos
      camera.position.set(x, y, z)
    }
  }, [cameraPos])

  useEffect(() => {
    Pieces.update = async (configuration: Configuration) => {
      await updatePiecesPart(configuration, piecesPartUpdater)
    } 
  }, [])

  useEffect(() => {
    if (configuration) {
      const components = configuration.components.filter(({ parentItem }) => !!parentItem).map(({ id, parentItem, parts, orientation: compOr }) => {
        const parent = parentItem as Zone
        return { id, position: parent.position, size: parent.size, orientation: compOr, type: parentItem?.type }

      })
      const assigned: { [id: number]: Vertices } = [...components].reduce((acc, { id, position, size, orientation, type }) => {
        const pos: Number3 = position.map((p: number, i: number) => (i === 1 && type === 'SPACE') ? -p : p) as Number3
        const point = calculateRectangleVertices(pos, size, degToRad(orientation[2]))
        const vertice = { [id]: { ...point } }
        return { ...acc, ...vertice }
      }, {})
      setPositionAssigned({ ...assigned })
    }
  }, [configuration, configuration?.components.length, configuration?.spaces.length])

  return (
    <React.Suspense>
      {
        configuration && configuration.components.filter(({ id }) => !hidden.includes(id)).map((component, key) => {
          return <Component key={key} groupPosition={[0, 0, 0]} component={component} />
        })
      }
      {/* {
        daeWardrobe && <Collada validation={(validate: Validator) => setValidate(validate)} />
      } */}
      {
        configuration && configuration.spaces && configuration.spaces.filter((({id}) => !hidden.includes(id))).map(item => {
          return <Spaces key={item.id} space={item} />
        })
      } 
      <DAE setValidate={(validate: Validator) => setValidate(validate)} />
      {
        cameraPos && cameraPos.z && <OrthographicCamera
          makeDefault
          zoom={1 / 10}
          near={1}
          far={100000}
          position={new Vector3(cameraPos.x, cameraPos.y, cameraPos.z * 100000)}
          up={[0, 0, 1]}

        />
      }
      {measureMode && <Measure/>}
      <Transforms />

    </React.Suspense>
  )
}

export default Cube