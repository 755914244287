import { Line, LineForSnap, Point, Number3, Vertices } from './../../../../types';

export const calculatePointPosition = (line: Line, point: Point): number => {
  const { x: x1, y: y1 } = line.p1;
  const { x: x2, y: y2 } = line.p2;
  const { x: x0, y: y0 } = point;

  // Calcul de l'équation de la droite
  const a = y2 - y1;
  const b = x1 - x2;
  const c = x2 * y1 - x1 * y2;
  return Math.abs(a * x0 + b * y0 + c) / Math.sqrt(a * a + b * b);
}

export const calculateRectangleVertices = (position: Number3, size: Number3, angle: number): Vertices => {
  const [width, depth, height] = size
  const halfWidth = width / 2;
  const halfDepth = depth / 2;
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  const [x, y, z] = position

  const center: Point = {
    x: x + halfWidth * cos + halfDepth * sin,
    y: y + halfWidth * sin - halfDepth * cos,
    z: z + height / 2
  };

  const surface =  {
    center,
    topLeft: { x, y, z: 0 },
    topRight: {
      x: center.x + halfWidth * cos - halfDepth * sin,
      y: center.y + halfWidth * sin + halfDepth * cos,
      z: z
    },
    bottomLeft: {
      x: center.x - halfWidth * cos + halfDepth * sin,
      y: center.y - halfWidth * sin - halfDepth * cos,
      z: z
    },
    bottomRight: {
      x: center.x + halfWidth * cos + halfDepth * sin,
      y: center.y + halfWidth * sin - halfDepth * cos,
      z: z
    },
  }
  const {topLeft, topRight, bottomLeft, bottomRight} = surface
  return {
    topLeft, topRight, bottomLeft, bottomRight,
    topLeftZ: {...topLeft, z: z + height},
    topRightZ: {...topRight, z: z + height},
    bottomLeftZ: {...bottomLeft, z: z + height},
    bottomRightZ: {...bottomRight, z: z + height},
  }
}

export const topLeftFromVertices = (point: Point, vertice: string, size: Number3, slope: number, side: boolean): Point => {
  const p = {x: 0, y:0}
  const {x, y} = point
  const [width, depth, height] = size
  const angle = side? slope - Math.PI / 2 : slope

  switch(vertice) {
    default: {
      p.x = x
      p.y = y
      break
    }
    case "topRight": {
      p.x = x - width * Math.cos(angle)
      p.y = y - width * Math.sin(angle)
      break
    }
    case "bottomRight": {
      const d = Math.sqrt(Math.pow(width, 2) + Math.pow(depth, 2))
      const teta = angle + Math.acos(width / d)
      p.x = x - d * Math.sin(teta)
      p.y = y - d * Math.cos(teta)
      break
    }
    case "bottomLeft": {
      p.x = x - depth * Math.sin(angle)
      p.y = y - depth * Math.cos(angle)
      break
    }
  }

  return p
}

export const lineEquation = (x: number, line: Line, side: boolean, parallel?: number): number => {
  // Calcul des coefficients de la droite
  const { p1, p2, angle } = line
  const a =  Math.tan(angle)
  const b = p1.y - a * p1.x + (parallel? parallel: 0);
  return side ? a * x + b : (x - b) / a;
}

export const straightLines = (center: Point, maxY: Point, maX: Point) => {

}

// export const equationsFromVertices = (vertices: Vertices, angle: number) => {

  
// }

export const generateLine = (p1: Point, p2: Point, angle: number): Line => ({ p1, p2, angle })

export const sideSize = 100, precision = 35, precisionFactor = 1.5
export const toGlued = (value: number) => {
  const neg = value < 0
  value = Math.abs(value)
  if (value % sideSize < precision) value = value - value % sideSize
  else if (value % sideSize > (sideSize - precision)) value = value - (value % sideSize) + sideSize
  return neg ? -value : value
}


export const extremVertices = (vertices: Vertices) => {
  const verticeLeft = Object.values(vertices).find(v => v.x === Math.min.apply(null, [vertices.topLeft.x, vertices.topRight.x, vertices.bottomRight.x, vertices.bottomLeft.x])) || { x: 0, y: 0 }
  const verticeRight = Object.values(vertices).find(v => v.x === Math.max.apply(null, [vertices.topLeft.x, vertices.topRight.x, vertices.bottomRight.x, vertices.bottomLeft.x])) || { x: 0, y: 0 }
  return { verticeLeft, verticeRight }
}

export const distance2 = (A: Point, B: Point): number => {
  const dx = B.x - A.x;
  const dy = B.y - A.y;
  return Math.sqrt(dx * dx + dy * dy);
}

export const generateLinesForSnap = (id: number, angle: number, vertices: Vertices): { [id: number]: LineForSnap } => {
  const { topLeft, topRight, bottomLeft, bottomRight } = vertices
  return {
    [id]: [generateLine(topLeft, topRight, angle),
    generateLine(topLeft, bottomLeft, angle + Math.PI / 2),
    generateLine(bottomLeft, bottomRight, angle),
    generateLine(topRight, bottomRight, angle + Math.PI / 2)]
  }
}