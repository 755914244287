import { ThreeEvent } from '@react-three/fiber'
import React, { useCallback, useRef } from 'react'
import { AppContextType, Component, ComponentPart, Space, SubPart, Zone } from '../../../types'
import Configuration from '../../../types/configuration'
import { PartType } from '../../../types/parts'
import { contextualToPrefs, fillFinishesContent } from '../../../utils/functions'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import selectionState from '../../../atoms/selectionState'
import { Popup, PopupType } from '../../components/Three/Collada/Popup'
import { useMainState } from '../../../recoil/hooks'
import request from '../../../utils/request'
import SelectObject from '../../../utils/json/SelectObject'
import { pieceState } from '../../../recoil/debitState'
import { contextState, daeWardRobeState } from '../../../recoil/contextState'
import { Intersection } from 'three/src/Three'
import { Transform } from '../../components/Three/Cube/Components/Transforms'
import { Pieces } from '../../components/Three/Cube/Components/Parts/partPiecesUtils'
import { parseConfiguration } from '../../../utils/parser'
import { Load } from '../../components/SideMenu'
import { Intersects } from '../../components/Three/Cube/Components/Measure'

type Props = {
  children: React.ReactNode
}

const findValuableItem = (intersections: Intersection[]) => {
  const toFind = [PartType.SUBPART, PartType.ZONE, PartType.SPACE, PartType.MACHINED, PartType.COMPONENT]
  let obj: THREE.Object3D | undefined
  for (let i = 0; i < intersections.length; i++) {
    obj = intersections[i].object
    while (obj && !(parseInt(obj.name) && toFind.includes(obj.type as PartType))) obj = obj.parent!
    if (obj) break
  }
  return obj
}

const findSelectableParent = (id: number, configuration: Configuration) => {
  let item = configuration.items[id]
  if (item.type === PartType.ZONE) return item as Zone
  while (item) {
    if (item.type === PartType.COMPONENT) {
      if ((item.parentItem as Zone).selectable) {
        return item.parentItem as Zone
      }
      else if (!item.component) return item as Component
    }
    item = item.parentItem!!
  }
}

export type HSelect = { x: number; y: number; part?: Zone | SubPart | Space | Component, selectable?: Zone | Component }

const transformEvent = (
  ev: ThreeEvent<PointerEvent | MouseEvent>,
  configuration: Configuration
): HSelect & ThreeEvent<PointerEvent | MouseEvent> => {
  const { intersections, clientX, clientY } = ev
  const found = findValuableItem(intersections)
  const part = configuration?.items[Number(found?.name)] as Zone | SubPart | Space | Component |  undefined
  return {
    ...ev,
    x: Math.floor(clientX),
    y: Math.floor(clientY),
    part,
    selectable: part ? findSelectableParent(part.id, configuration!!) : undefined
  }
}

export const samePos = (down: HSelect, up: HSelect) => {
  return down.x === up.x && down.y === up.y
}

const Handler: React.FC<Props> = ({ children }: Props) => {
  const { updateConfiguration, updateState, measure: measureMode } = useMainState()
  const hSelect = useRef<HSelect>({ x: 0, y: 0 })
  const last = useRef<number>()
  const setSelection = useSetRecoilState(selectionState)
  const pieces = useRecoilValue(pieceState)
  const daeWardrobe = useRecoilValue(daeWardRobeState)
  const [mainContext, setMainContext] = useRecoilState(contextState)
  const { move, rotate, configuration } = mainContext

  const select = useCallback((moveOrRotate?: boolean, selectedTarget: number = 0, selectable?: ComponentPart) => new Promise<AppContextType | void>(async (ok) => {
    const selectableId = selectable?.id
    const selectWillChange = last.current !== selectedTarget
    if (!selectable) updateState({ selectedTarget: undefined })
    if (moveOrRotate && selectWillChange) {
      const res = await Transform.validate()
      //.then(async res => {
        if (res) {
          // Price.update()
          const configuration = parseConfiguration(res)
          await Pieces.update(configuration)
          updateState({configuration})
        }
        updateState({ move: false, rotate: false, selectedTarget: undefined })
      //})
    }
    if (selectableId && selectWillChange) {
      last.current = selectedTarget
      // New selection request
      const cancel = Popup.popup({
        message: selectable?.type === PartType.SPACE ? `Sélection de "${selectable.name}"` : selectable?.type === PartType.ZONE ? "Sélection de la zone" : "Sélection du composant",
        type: PopupType.WAITING
      })
      const res: any = await request(SelectObject(selectedTarget))
      cancel()

      // Getting the after select things
      if (res && res.Selection) {
        const data = updateConfiguration(res)
        const config = data?.configuration
        const selection = config?.selection
        if (config && selection) {
          const part = config.items[selection?.id || 0]
          if (part?.type === PartType.COMPONENT) {
            // Preferences
            const contextual = res.Selection.properties.componentProperties.contextualProperties
            const preferences = contextualToPrefs(contextual)
            setSelection({ preferences })
          }
        }
      }
    }
    else if (!selectable && last.current) {
      last.current = undefined
      const cancel = Popup.popup({ message: "Desélection", type: PopupType.WAITING })
      const res = await request(SelectObject(0))
      if(res) {
        updateState({modules: undefined, finitions: undefined})
        const data = updateConfiguration(res)
        if(data?.configuration){
          Load.setFinishesLoad(true)
          fillFinishesContent(data.configuration.range).then(finitions => {
            updateState({ finitions })
            Load.setFinishesLoad(false)
          })
        }
      }
      cancel()
    }
    ok()
  }), [updateConfiguration])

  const mouseEvent = useCallback(
    (ev: ThreeEvent<MouseEvent>) => {
      ev.stopPropagation()
      const event = transformEvent(ev, configuration!) as HSelect
      if (samePos(hSelect.current, event) && !daeWardrobe && !measureMode) {
        const { selectable, part } = event
        if (selectable && part) {
          updateState({ selectedTarget: part.id })
          if (selectable.type === PartType.ZONE) {
            select(move || rotate, part.id, selectable)
          } else {
            let selectableId = selectable.id
            if ((selectable as Component).readOnly) {
              selectableId = configuration!.components.find(({ readOnly }) => readOnly)?.id || 0
            }
            if (selectableId) {
              const contextMenu = true // ev.button === 2
              select(move || rotate, part.id, selectable).then(() => {
                if (contextMenu) updateState({
                  contextMenu: { x: event.x, y: event.y, done: true }
                })
              })
              if (contextMenu) {
                setMainContext({
                  contextMenu: { x: event.x, y: event.y },
                  selectedTarget: part.id
                })
              }
            }
          }
        } else {
          select(move || rotate)
        }
      }
    },
    [configuration, mainContext, daeWardrobe, measureMode, pieces, move || rotate]
  )

  return (
    <group
      onPointerDown={(ev) => {
        ev.stopPropagation()
        const { x, y, part, selectable } = transformEvent(ev, configuration!)
        hSelect.current = { x, y, part, selectable }
      }}
      onPointerUp={(ev: ThreeEvent<MouseEvent>) => {
        mouseEvent(ev)
      }}
      onPointerMove={e => {
        const {intersections} = e 
        const partsItersects = intersections.filter(obj => 
          obj.object.type !== 'GridHelper' &&
          obj.object.name !== 'terrain' && 
          obj.object.type !== 'MEASURE' &&
          obj.object.type !== PartType.ZONE &&
          obj.object.name !== 'sky'
        )
        Intersects.intersections = partsItersects as Intersection[]
      }} 
    >
      {children}
    </group>
  )
}

export default Handler

