import React, { useEffect, useState } from 'react'
import Models from './Models'
import SidePane from './SidePane'
import Modules from './Modules'
import './SideMenu.scss'
import Finitions from './Finitions'
import { useMainState } from '../../../recoil/hooks'
import { Action } from '../../../types'
import { fillFinishesContent, fillModulesContent } from '../../../utils/functions'
import { PartType } from '../../../types/parts'
import Focusable from '../Focusable'
type Props = {}

export const Load = {
  setFinishesLoad: (b: boolean) => {},
  setModulesLoad: (b: boolean) => {}
}

const SideMenu: React.FC<Props> = ({ }) => {
  const { updateState, configuration, selected } = useMainState()
  const [finishesLoad, setFinishesLoad] = useState(false)
  const [modulesLoad, setModulesLoad] = useState(false)

  useEffect(() => {
    updateState({ modules: undefined })
  }, [selected?.id])
  useEffect(() => {
    Object.assign(Load, {setFinishesLoad, setModulesLoad})
  }, [])
  return (
    <div className='SideMenu'>
      <div className="menu-item">
        <div tabIndex={0} className="menu-button models">
          <span>modeles</span>
        </div>
        <SidePane title="modeles">
          <Models />
        </SidePane>
      </div>
      <Focusable
        className="menu-item"
        onFocus={() => {
          const range = configuration?.selection?.range
          if (range && selected?.type === PartType.ZONE) {
            setModulesLoad(true)
            fillModulesContent(range).then(modules => {
              updateState({ modules })
              setModulesLoad(false)
            })
          }
        }}
      >
        <div tabIndex={0} className="menu-button adds">
          <span>Ajouts</span>
        </div>
        <SidePane title="Ajouts de composant">
          <Modules modulesLoad={modulesLoad} />
        </SidePane>
      </Focusable>
      <Focusable
        className="menu-item"
        onFocus={() => {
          // if (configuration && configuration.selection) {
          const range = configuration?.selection?.range
          if (range && selected?.type === PartType.COMPONENT) {
            setFinishesLoad(true)
            fillFinishesContent(range).then(finitions => {
              updateState({ finitions })
              setFinishesLoad(false)
            })
          }
        }
        }
      >
        <div tabIndex={0} className="menu-button finitions">
          <span>finitions</span>
        </div>
        <SidePane title="finitions">
          <Finitions finishesLoad={finishesLoad} />
        </SidePane>
      </Focusable>
      <Focusable
        onClick={() => {
          updateState({ activePopup: Action.SPACES })
        }}
        className="menu-item"
      >
        <div className="menu-button spaces">
          <span>espaces</span>
        </div>
      </Focusable>
    </div>
  )
}

export default SideMenu