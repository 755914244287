import React, { useCallback, ChangeEvent, useState } from "react";
import SaveConfiguration from "../../../utils/json/SaveConfiguration";
import request from "../../../utils/request";
import "./SaveWork.scss"
import GetProductStructure from "../../../utils/json/GetProductStructure";
import { Popup, PopupType } from "../Three/Collada/Popup";
import OpenConfiguration from "../../../utils/json/OpenConfiguration";
import { useMainState } from "../../../recoil/hooks";
import { AppContextType } from "../../../types";


const SaveWork: React.FC = () => {
  const { updateConfiguration } = useMainState()
  const [value, setValue] = useState('Projet 01')
  const saveAs = useCallback(async (name: string) => {
    const cancel = Popup.popup({
      message: `Sauvegarde en cours`, type: PopupType.WAITING
    })
    const { configurationData: data } = (await request(SaveConfiguration('test'))) as { configurationData: string }
    cancel()
    const canvas = document.querySelector('canvas') as HTMLCanvasElement

    canvas.toBlob(async (blob) => {
      const link = document.createElement('a')
      if (blob) {
        link.setAttribute('download', `${name.replace(/\s/g, '')}.woodxml`)
        link.setAttribute('href', `data:@file/octet-stream;base64,${data}`)
        link.click()
        Popup.popup({
          message: `Sauvegarde local terminée`, type: PopupType.SUCCESS
        })
      }
      link.remove()
    })
  }, [])
  const load = useCallback((e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    if(file){
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async () => {
        const res = (reader.result as string).split(';base64,')[1]
        const cancel = Popup.popup({
          message: `Chargement de votre configuration`, type: PopupType.WAITING
        })
        const conf = await request(OpenConfiguration('test', res))
        cancel()
        updateConfiguration(conf) as AppContextType
        (document.activeElement as HTMLDivElement | undefined)?.blur()
      }
    }
  }, [])

  return <div className="SaveWork" tabIndex={0}>
    <label >
      <span>Nom du projet:</span>
      <input type="text" value={value} onChange={e => setValue(e.target.value)} />
    </label>
    <div className="buttons">
      <button className="button" id="save" onClick={e => {
        saveProject(value)
        const elt = e.target as HTMLButtonElement
        elt.blur()
      }}> Sauvegarder </button>
      <div className="hr"/>
      <label className="local button">
        <input
          onChange={load}
          type="file"
        />
        Ouvrir un projet local
      </label>
      <button className="local button" onClick={e => {
        saveAs(value)
      }}> Télécharger le projet </button>
    </div>
  </div>
}

const saveProject = async (name: string) => {
  const comStructure = (await request(GetProductStructure(false)) as any).comProductStructure
  const { configurationData: data } = (await request(SaveConfiguration(name))) as {configurationData: string}
  const canvas = document.querySelector('canvas') as HTMLCanvasElement

  const uniqid = (window as any).uniqId
  const win = window as any
  var fd = new FormData()
  fd.append('data', data)
  if(uniqid) fd.append('uniqId', uniqid)
  fd.append('name', name)
  fd.append('structure', JSON.stringify(comStructure))
  fd.append('date', new Date().toISOString())
  fd.append('image', canvas.toDataURL())
  const response = await fetch("/api/3d/save", {
    method: "POST",
    body: fd
  })
  const {uniqId} = await response.json()
  if(uniqId) win.uniqId = uniqId
}

const savedProjects = async (amount: String) => {
  const response = await fetch("/api/3d/saved", {
    method: "GET"
  });
}

export default SaveWork