import { Box, Edges } from "@react-three/drei";
import React, { useEffect, useMemo, useRef } from "react";
import * as THREE from "three";
import { SubPart, Number3 } from "../../../../../../types";
import { matrixForRotation, matrixForTranslation } from "../../../../../../utils/toMatrix";
import { pubUrl } from "../../../../../../utils/functions";
import { degToRad } from "three/src/math/MathUtils";
import { useMainState } from "../../../../../../recoil/hooks";

type Props = {
  part: SubPart,
  id: number,
  groupPosition: Number3
}

const textures: { [key: string]: THREE.Texture } = {}

export const getTextures = (path: string, sizes: Number3, position: Number3, costumed: boolean) => {
  const [w, d, h] = sizes
  const min = Math.min(...sizes)
  const vertical = w === min || (min === h && w > d) || (min === d && w > h)

  // Texture name
  const key = path + (costumed ? sizes.join(',') : "") + (vertical ? 'v' : 'h')

  delete textures[key]
  if (!textures[key]) {
    textures[key] = new THREE.TextureLoader().load(pubUrl(path));
    textures[key].wrapS = THREE.RepeatWrapping;
    textures[key].wrapT = THREE.RepeatWrapping;

    const sizeRef = 800
    const [sw, sd, sh] = sizes.map(size => Math.abs(size / sizeRef)) as Number3

    if(costumed){
      if(w === min){
        textures[key].repeat.set(sd, sh)
      } else if(min === h && w > d){
        textures[key].repeat.set(sd, sw)
      } else if(min === d && w > h){
        textures[key].repeat.set(sh, sw)
      } else if(h === min){
        textures[key].repeat.set(sw, sd)
      } else {
        textures[key].repeat.set(sw, sh)
      }
    }
    else textures[key].repeat.set(1, 1)

    if(vertical) textures[key].rotation = Math.PI / 2
  } 
  return textures[key];
}


type Mesh = THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>

const Part: React.FC<Props> = ({ part, id, groupPosition }) => {

  const { areParented, transparents, topestComponent, receiveShadow, selectedTarget, configuration, grids } = useMainState()
  const { size, position, assembly: { finish: { path } }, orientation } = part
  const [width, depth, height] = size
  const ref = useRef<Mesh>()
  const mpm = useRef<THREE.MeshPhongMaterial>()
  const costumedTexture = false
  
  const texture = useMemo(() => {
    return getTextures(
      pubUrl(path),
      part.size,
      part.position,
      costumedTexture
    )
  }, [costumedTexture, path, ...part.size])

  const transparent = useMemo(() => {
    return configuration ? areParented(part.id, transparents) : false
  }, [transparents])

  useEffect(() => {
    if (mpm.current) {
      mpm.current.transparent = !!transparent
      mpm.current.needsUpdate = true
    }
  }, [transparent])

  useEffect(() => {
    const m0 = matrixForTranslation([width / 2, - depth / 2, height / 2])
    const m1 = matrixForRotation(degToRad(orientation[2]))
    const m2 = matrixForTranslation([position[0] - groupPosition[0], position[1] - groupPosition[1], position[2] - groupPosition[2]])
    if (ref.current) {
      ref.current.position.set(0, 0, 0);
      ref.current.rotation.set(0, 0, 0);
      ref.current.scale.set(1, 1, 1);

      ref.current.applyMatrix4(m0);
      ref.current.applyMatrix4(m1);
      ref.current.applyMatrix4(m2);
    }
  }, [part])

  return (
    <mesh
      ref={ref as React.Ref<Mesh>}
    >
      <Box type={'SUBPART'} args={size} name={part.id + ''}
        castShadow={grids}
        receiveShadow={grids && receiveShadow}
      >
        <meshPhongMaterial
          ref={mpm as React.Ref<THREE.MeshPhongMaterial>}
          color={selectedTarget && areParented(part.id) ? '#ffa7df' : '#ddd'}
          opacity={transparent ? .4 : 1}
          map={texture}
        />
        <Edges color={'#999999'} />
      </Box>
    </mesh>
  )
}

export default Part