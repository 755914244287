import * as THREE from './three';
import './ColladaLoader';
import './OrbitControls';
const TWEEN = require('@tweenjs/tween.js');

import grey from '../images/textures/Grey.png';
import nuage from '../images/textures/Nuage.png';
import sky0 from '../images/textures/sky/0.jpg';
import sky1 from '../images/textures/sky/1.jpg';
import sky2 from '../images/textures/sky/2.jpg';
import sky3 from '../images/textures/sky/3.jpg';
import sky4 from '../images/textures/sky/4.jpg';
import sky5 from '../images/textures/sky/5.jpg';
import sky10 from '../images/textures/sky1/0.jpg';
import sky11 from '../images/textures/sky1/1.jpg';
import sky12 from '../images/textures/sky1/2.jpg';
import sky13 from '../images/textures/sky1/3.jpg';
import sky14 from '../images/textures/sky1/4.jpg';
import sky15 from '../images/textures/sky1/5.jpg';

class Wood3D {
    usedFinishes = [];
    objects = [];
    allObjects = [];
    hideMesh = [];
    transparentMesh = [];
    selectedMesh = [];
    gridHelper;
    isVisu;
    addDrawerAccess(daeFile, matrix, boxWidth, boxDepth, daeId, parentId, parentDrawer, drawerName, daeColor) {
        daeColor = typeof daeColor !== 'undefined' && daeColor !== undefined ? 
            daeColor.substring(1) :
            (this.isVisu ? "#5F6A6A" : 0xBDBDBD);
        let colorValue = parseInt(daeColor.replace("#","0x"), 16);
        let materialDae = new THREE.MeshPhysicalMaterial({ color: colorValue, skinning: true, flatShading: THREE.SmoothShading, metalness: 0.6 } );
        
        //add collada modele
        let line1_x = matrix[0]; let line1_y = -1 * matrix[1]; let line1_z = matrix[2];
        let line2_x = matrix[3]; let line2_y = -1 * matrix[4]; let line2_z = matrix[5];
        let line3_x = matrix[6]; let line3_y = -1 * matrix[7]; let line3_z = matrix[8];
        let pos_x = parseFloat(matrix[9]/2); let pos_y = -1 * parseFloat(matrix[10]/2); let pos_z = parseFloat(matrix[11]/2);
        
        let loader = new THREE.ColladaLoader();
        loader.load('/build/assets/'+daeFile, (collada) => {
            let dae = collada.scene;
            dae.scale.x = dae.scale.y = dae.scale.z = 0.5;
            //collada change material
            dae.traverse((child) => {
                if (child instanceof THREE.Mesh) {
                    child.material = materialDae;
                    if(this.isVisu) {
                        child.material.flatShading = true;
                    }
                }
            });
            
            let box = new THREE.Box3().setFromObject(dae);
            box.getCenter(dae.position);
            dae.position.multiplyScalar(-1);

            let translationMatrix = new THREE.Matrix4();
            translationMatrix.set(
                line1_x, line2_x, line3_x, pos_x,
                line1_z, line2_z, line3_z, pos_z,
                line1_y, line2_y, line3_y, pos_y,
                0, 0, 0, 1
            );
            let matrixTransformAxes = new THREE.Matrix4();
            matrixTransformAxes.set(
                1,0,0,0,
                0,0,-1,0,
                0,1,0,0,
                0,0,0,1
            );
            translationMatrix = matrixTransformAxes.premultiply(translationMatrix);
            dae.applyMatrix(translationMatrix);
            this.objectPositionCenter(dae, boxWidth, boxDepth);

            dae.parentID = parentId;
            dae.parentDrawer = parentDrawer;
            dae.idCube = daeId;
            dae.nameDae = "ACCESS";
            dae.drawName = drawerName;
            dae.contType = "accessory";
            this.scene.add(dae);
            if(!this.isVisu) {
                dae.position.y -= this.posYScene/2;
            }
        });
    }
    drawCube(piece, material, parentTypeDrawer) {
        let positionPiece = piece.position.split(",").map(s => parseFloat(s/2));
        let pieceSize = piece.size.split(",").map(s => parseFloat(s/2));
        let pieceOrientation = piece.orientation.split(",").map(s => parseFloat(s));

        let pieceWidth = pieceSize[0];
        let pieceHeight = pieceSize[2];
        let pieceDepth = pieceSize[1];
        let geometry = new THREE.BoxGeometry(pieceWidth, pieceHeight, pieceDepth);
        let cube = new THREE.Mesh(geometry, material);

        let geo = new THREE.EdgesGeometry(cube.geometry );
        let mat = new THREE.LineBasicMaterial({ color: 0x000000, linewidth: 2 });
        let wireframe = new THREE.LineSegments(geo, mat);
        wireframe.renderOrder = 1; // make sure wireframes are rendered 2nd
        //cube.add( wireframe );

        let posX = parseFloat(positionPiece[0] + pieceWidth/2);
        let posY = parseFloat(positionPiece[2] + pieceHeight/2);
        let posZ = -1 * parseFloat(positionPiece[1]) +  parseFloat(pieceDepth)/2;
        cube.position.set(posX, posY, posZ);
        cube.rotation.set(pieceOrientation[0], pieceOrientation[2], pieceOrientation[1]);

        //ajout machinings here
        cube.castShadow = true;
        cube.receiveShadow = true;
        cube.name = piece.code;
        cube.code = piece.code;
        cube.CubeType = piece.type;
        cube.idCube = piece.id;
        cube.parentID = piece.parent;
        cube.parentZone = piece.parent;
        if(this.isVisu == false) {
            cube.empty = piece.childrenIdList ? piece.childrenIdList.length == 0 : true;;
            cube.viewer = piece.visible === "True";
        }
        cube.contType = "component";
        cube.parentTypeDrawer = parentTypeDrawer;

        if((piece.type === "PIECE") || (this.isVisu == false && (piece.type === "ZONE" && piece.visible === "True"))) {
            cube.add(wireframe);
            this.scene.add(cube);
            if(!this.objects.includes(cube)) {
                this.objects.push(cube);
            }
        }
        if(!this.allObjects.includes(cube)) {
            this.allObjects.push(cube);
        }
    
        return cube;
    }
    parseRecursiveComponent(piece, boxWidth, boxDepth) {
        if (piece.type === "ASSEMBLY") {
            this.handleAssembly(piece, boxWidth, boxDepth);
        }
        else {
            piece.children.forEach(component => {
                let compPieceType = component.type;
                if (compPieceType == "ZONE") {
                    let material = new THREE.MeshBasicMaterial({color: 0xE4E4E4, transparent: true, opacity: 0.8});
                    let cube = this.drawCube(component, material, false);
                    this.objectPositionCenter(cube, boxWidth, boxDepth);

                    if(this.selectedMesh.includes(cube.idCube) || this.selectedMesh.includes(cube.parentID)) {
                        cube.material.color.setHex(0xE788C5);
                    }
                    if(this.hideMesh.includes(cube.idCube)) {
                        cube.visible = false;
                    }
                    if(this.transparentMesh.includes(cube.idCube)){
                        cube.material.transparent = true;
                        cube.material.opacity = 0.5;
                    }
                }
                if (compPieceType == "ASSEMBLY") {
                    this.handleAssembly(component, boxWidth, boxDepth);   
                }
                if(compPieceType === "FOREIGNCOMPONENT"){
                    this.addDrawerAccess(
                        component.colladaFileName, 
                        this.isVisu == true ? component.matrix.split(" ").map(x => parseFloat(x)) : component.id.split(" "),
                        boxWidth, 
                        boxDepth, 
                        component.id, 
                        component.parent, 
                        piece.parent, 
                        piece.name, 
                        component.color
                    );
                }
                if(typeof component.children !== 'undefined' &&
                    ["ACCESSORYCOMPONENT", "COMPONENT", "DRAWERCOMPONENT"].includes(compPieceType)) {
                    this.parseRecursiveComponent(component, boxWidth, boxDepth);
                }
            });
        }
        return { type : piece.type, piece : piece.children };
    }
    handleAssembly(component, boxWidth, boxDepth) {
        let texture = new THREE.TextureLoader().load(this.usedFinishes.find(finish => finish[0] == component.finish)[1]);
        component.children.forEach(child => {
            if (child.type == 'SUBPART') {
                child.type = 'PIECE';
                let material = component.name === "Removable glass shelf" ? 
                    new THREE.MeshBasicMaterial({map: texture, transparent: true, opacity: 0.8}):
                    new THREE.MeshBasicMaterial({map: texture});
                let cube = this.drawCube(child, material, true);
                this.objectPositionCenter(cube, boxWidth, boxDepth);

                if(this.selectedMesh.includes(cube.idCube) || this.selectedMesh.includes(cube.parentID)) {
                    cube.material.color.setHex(0xE788C5);
                }
                if(this.hideMesh.includes(cube.idCube)) {
                    cube.visible = false;
                }
                if(this.transparentMesh.includes(cube.idCube)) {
                    cube.material.transparent = true;
                    cube.material.opacity = 0.5;
                }
            }

            if(typeof component.connectors === "undefined") {
                return;
            }

            component.connectors.forEach(connector => {
                connector.représentations.forEach(r => {
                    this.addConnector(
                        connector.colladaFileName, 
                        r.matrix.split(" ").map(x => parseFloat(x)), 
                        boxWidth, 
                        boxDepth, 
                        connector.id, 
                        child.id
                    );
                });
            });
        });
    }
    objectPositionCenter(cube, width, depth) {
        cube.position.x -= parseFloat(width/2);
        cube.position.y -= parseInt(this.isVisu ? this.posYScene/2 : this.posYScene);
        cube.position.z -= parseFloat(depth/2);
    }
    addConnector(daeFile, matrix, boxWidth, boxDepth, daeId, parentId) {
        let color = 0xFF803F;
        let name = "CHARN";
        if (daeFile.indexOf('TOUR') > -1) {
            color = 0xFFE499;
            name = "TOUR";
        }
        else if (daeFile.indexOf('EXC') > -1) {
            color = 0x66A8D8;
            name = "EXC";
        }
        else if (daeFile.indexOf('TAQU') > -1) {
            color = 0x99C4E4;
            name = "TAQU";
        }
        else if (daeFile.indexOf('47654-61852') > -1) {
            color = 0xD1D1FF;
            name = "47654-61852";
        }
        let materialDae = this.isVisu == true ?
            new THREE.MeshPhongMaterial({ color: color, skinning: true, flatShading: THREE.SmoothShading } ) :
            new THREE.MeshPhysicalMaterial({ color: color, skinning: true, flatShading: THREE.SmoothShading, metalness: 0.6 } );
        //add collada modele
        let line1_x = matrix[0]; let line1_y = -1 * matrix[1]; let line1_z = matrix[2];
        let line2_x = matrix[3]; let line2_y = -1 * matrix[4]; let line2_z = matrix[5];
        let line3_x = matrix[6]; let line3_y = -1 * matrix[7]; let line3_z = matrix[8];
        let pos_x = parseFloat(matrix[9]/2); let pos_y = -1 * parseFloat(matrix[10]/2); let pos_z = parseFloat(matrix[11]/2);
        
        let loader = new THREE.ColladaLoader();
        loader.options.convertUpAxis = true;
        loader.load('/build/assets/'+daeFile, (collada) => {
            let dae = collada.scene;
            dae.scale.x = dae.scale.y = dae.scale.z = 0.5;
            dae.material = materialDae;
            dae.position.multiplyScalar(-1);
            let box = new THREE.Box3().setFromObject(dae);
            box.getCenter(dae.position);

            let translationMatrix = new THREE.Matrix4();
            translationMatrix.set(
                line1_x, line2_x, line3_x, pos_x,
                line1_z, line2_z, line3_z, pos_z,
                line1_y, line2_y, line3_y, pos_y,
                0, 0, 0, 1
            );
            let matrixTransformAxes = new THREE.Matrix4();
            matrixTransformAxes.set(
                1,0,0,0,
                0,0,-1,0,
                0,1,0,0,
                0,0,0,1
            );
            translationMatrix = matrixTransformAxes.premultiply(translationMatrix);
            dae.applyMatrix(translationMatrix);

            this.objectPositionCenter(dae, boxWidth, boxDepth);

            dae.parentID = parentId;
            dae.idCube = daeId;
            dae.nameDae = name;
            dae.contType = "connector";
        });
    }
    initFinishes(data) {
        this.usedFinishes = [];
        data.configuration.usedFinishes.forEach(finition => {
            this.usedFinishes.push([finition.finishCode, '/build/'+finition.relativeImageFilePath]);
        })
    }
}

export class Wood3DVisu extends Wood3D {
    isVisu = true;
    constructor(data) {
        super();
        this.camera = new THREE.PerspectiveCamera(65, window.innerWidth / window.innerHeight, 1, 1000000);
        this.camera.position.set(0, 0, 1000);
        this.scene = new THREE.Scene();

        let gridHelper = new THREE.GridHelper(4000, 20, 0x000000, 0x000000);
        gridHelper.position.y = -100;
        gridHelper.receiveShadow = true;

        this.scene.add(this.camera);
        //this.scene.add(gridHelper);

        let uniqId = data.uniqId;

        data = JSON.parse(data.threeD);
        
        this.initFinishes(data);
        /*cube and 3d object */
        let boxWidth = data.configuration.project_width/2;
        this.posYScene = data.configuration.project_height/2;
        let boxDepth = data.configuration.project_depth/2;
        // configuration
        let pieces = data.configuration.components;
        pieces.forEach(piece => {
            if(piece.type === "ZONE") {
                let material = new THREE.MeshBasicMaterial({color: 0xE4E4E4, transparent:true, opacity:0.8});
                let cube = this.drawCube(piece, material, false);
                this.objectPositionCenter(cube, boxWidth, boxDepth);
                return;
            }
            if(piece.type === "FOREIGNCOMPONENT"){
                this.addDrawerAccess(
                    piece.colladaFileName, 
                    piece.matrix.split(" "), 
                    boxWidth, 
                    boxDepth, 
                    piece.id, 
                    piece.parent,
                    piece.parent, 
                    piece.name, 
                    piece.color
                );
                return;
            }
            //all type of component
            this.parseRecursiveComponent(piece, boxWidth, boxDepth);
        });

        //lights
        let ambientLight = new THREE.AmbientLight(0xffffff, 0.2);
        this.scene.add(ambientLight);

        let pointLight = new THREE.PointLight(0xffffff, 0.8);
        this.scene.add(this.camera);
        this.camera.add(pointLight);

        this.renderer = new THREE.WebGLRenderer({ antialias: true, autoSize: true, preserveDrawingBuffer: true});
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth*0.8, window.innerHeight*0.8);

        this.container = $('#rendu');
        this.container.html(this.renderer.domElement);
        this.renderer.setClearColor(0xFFFFFF);

        let controls = new THREE.OrbitControls(this.camera, this.container[0]);
        controls.maxPolarAngle = Math.PI/2;
        controls.enableKeys = true;

        this.animate();
        this.initSnapshot(uniqId);

        $(window).resize(() => {
            this.camera.aspect = this.container.width() / this.container.height();
            this.camera.updateProjectionMatrix();

            this.renderer.setSize(this.container.width(), this.container.height());
        });
    }
    animate() {
        requestAnimationFrame(() => this.animate());
        this.renderer.render(this.scene, this.camera);
    }
    initSnapshot(uniqId) {
        $('.picture')
            .attr('uniqId', uniqId)
            .removeClass('d-none');

        $(document)
            .off('click', '.picture')
            .on('click', '.picture', (e) => {
                let uniqId = $(e.currentTarget).attr('uniqId');
                
                let strMime = "image/png";
                let imgData = this.renderer.domElement.toDataURL(strMime);
                $.ajax({
                    url: urlSaveImage.replace('-1', uniqId),
                    method: 'POST',
                    data: {
                        image: imgData
                    },
                    success: (response) => {
                        $('.item-'+uniqId+' .img-product').attr('src', imgData);
                        let link = document.createElement('a');
                        if (typeof link.download === 'string') {
                            document.body.appendChild(link); //Firefox requires the link to be in the body
                            link.download = "kazedview.jpg";
                            link.href = imgData.replace(strMime, "image/octet-stream");
                            link.click();
                            document.body.removeChild(link); //remove the link when done
                        } else {
                            location.replace(uri);
                        }
                    }
                })
            })
            .off('hidden.bs.modal', '#viewOrderItemModal')
            .on('hidden.bs.modal', '#viewOrderItemModal', (e) => {
                $('#rendu').empty();
                $('.picture').addClass('d-none');
            });
    }
}