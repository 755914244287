import * as THREE from 'three'
import { Number3 } from '../types'
import { SMatrix } from '../types'

export type MProperties = {
  position?: Number3,
  scale?: Number3,
  rotation?: number
}

export const toRad = (angleDegree: number): number => {
  return angleDegree / (180 / Math.PI)
}

export const toMatrix4 = (m: SMatrix): THREE.Matrix4 => {
  const mArray = [
    m[0], m[1], m[2], 0,
    m[3], m[4], m[5], 0,
    m[6], m[7], m[8], 0,
    m[9], m[10], m[11], 1
  ]
    
  const m4 = new THREE.Matrix4().fromArray(mArray)
  return m4
}

export const toDegree = (angleRad: number): number => {
  return angleRad * (180 / Math.PI)
}

export const matrixForTranslation = (position: Number3 | undefined): THREE.Matrix4 => {
  let m = new THREE.Matrix4()
  if(position) {
    const [x, y, z] = position
    m.makeTranslation(x, y, z)
  }
  return m
}

export const matrixForRotation = (angle: number | undefined): THREE.Matrix4 => {
  let m = new THREE.Matrix4()
  if(angle) {
    m.makeRotationZ(angle)
  }
  return m
}

export const matrixForScale = (scale: Number3 | undefined): THREE.Matrix4 => {
  let m = new THREE.Matrix4()
  if(scale) {
    const [x, y, z] = scale
    m.makeScale(x, y, z)
  }
  return m
}
 
const toMatrix = (props: MProperties | undefined): THREE.Matrix4 => {
  let m = new THREE.Matrix4()
  if (props) {
    const { position, scale, rotation } = props
    const mTranslation = matrixForTranslation(position)
    const mRotation = matrixForRotation(rotation)
    const mScale = matrixForScale(scale)
    m.multiply(mTranslation).multiply(mScale).multiply(mRotation)
  }
  return m
}

export default toMatrix