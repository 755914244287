import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Component } from '../../../types'
import './DimensionInfo.scss'
import { useMainState } from '../../../recoil/hooks'

const DimensionInfo: React.FC = (props) => {
  const {configuration: {selection} = {}, selected, configuration} = useMainState()
  const [dimension, setDimension] = useState([0, 0, 0])
  useEffect(() => {
    if(selected) {
      const component = selected as Component
      const m = component.multiplier || [1, 1, 1]
      setDimension([
        (selection?.size.x.value || 0) * m[0],
        (selection?.size.y.value || 0) * m[1],
        (selection?.size.z.value || 0) * m[2],
      ])
    }
  }, [selection, configuration])
  return (
    <div className={clsx("DimensionInfo", !selected && 'inactive')}>
      <div className='di-item'>
        <span className='di-title'>Hauteur : </span>
        <span className='di-value'>{dimension[2]}</span>
      </div>
      <div className='di-item'>
        <span className='di-title'>Largeur : </span>
        <span className='di-value'>{dimension[0]}</span>
      </div>
      <div className='di-item'>
        <span className='di-title'>Profondeur : </span>
        <span className='di-value'>{dimension[1]}</span>
      </div>
    </div>
  ) 
}

export default DimensionInfo