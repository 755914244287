import session from "./session"

export default (name: string, configurationData: string) => {
  return {
    command: {
      name: "OpenConfiguration",
      sessionId: session,
      id: "40",
      params: {
        name: name,
        configurationData: configurationData
      }
    }
  }
}