import { topestComponent } from './../../utils/parser';
import { Space } from './../../types/index';
import { useRecoilState } from 'recoil';
import { __contextState } from '../contextState';
import { parseConfiguration, parseSelection } from '../../utils/parser';
import { areParented } from '../../utils/functions';
import { AppContextType, Component, Number3 } from '../../types';

import Configuration from '../../types/configuration';
import React, { useMemo, useRef } from 'react';
import { PartType } from '../../types/parts';

type Type = AppContextType & {
  updateConfiguration: (res: any) => AppContextType | undefined,
  updateMultiplier: (item: Component | Space, multiplier: Number3) => AppContextType | undefined,
  updateComponent: <T extends Component | Space>(item: T, toAdd: Partial<T>) => AppContextType | undefined,
  updateState: (newState: Partial<AppContextType>) => void,
  areParented: (id: number, parent?: number | number[]) => boolean,
  topestComponent<ComponentPart>(id: number, cType?: PartType[]): ComponentPart | undefined
}

export const useMainState = () => {
  const [state, setState] = useRecoilState(__contextState)
  const ref = useRef({} as Type)

  const hooks = useMemo(() => {
    Object.assign(ref.current, {
      configuration: state.configuration,
      updateConfiguration: (res: any) => {
        const { configuration } = state;
        // let newstate: AppContextType | undefined;
        let newstate: AppContextType | undefined
        setState((state) => {
          if (res.configuration) {
            // if there is a new configuration
            const config = parseConfiguration(res)
            const selId = config?.selection?.id || 0
            newstate = {
              ...state,
              commands: res.availableCommands.map(Number),
              configuration: config,
              selected: config?.items[selId]
            };
          } else if (res.Selection && configuration) {
            // if there is only a new selection
            const id = Number(res.Selection.id || res.Selection.properties?.id)
            const selection = id ? parseSelection(res.Selection, configuration) : undefined;
            newstate = {
              ...state,
              configuration: {
                ...configuration,
                selection,
              },
              commands: res.availableCommands.map(Number),
              selected: configuration?.items[id]
            }
          }
          // setting the new sate
          return newstate || state;
        })

        // returning the new state before updates
        return newstate;
      },
      updateComponent: <T extends Space | Component>(item: T, toAdd: Partial<T>) => {
        let newState: AppContextType | undefined
        setState(({ configuration: config, ...state }) => {
          newState = {
            ...state,
            configuration: config ? replaceInConfiguration(config, item, toAdd) : config,

          } as AppContextType

          if (item.id === state.selected?.id) newState.selected = newState.configuration?.items[item.id]
          return newState
        })
        return newState
      },
      updateState: (newState: Partial<AppContextType>) => {
        setState(accState => { return { ...accState, ...newState } });
      },
      topestComponent : (id: number, cType?: PartType[]) => {
        const { configuration } = ref.current
        return configuration && topestComponent(id, configuration, cType)
      },
      areParented: (id: number, parent?: number | number[]) => {
        const { configuration } = state
        if (!parent) parent = configuration?.selection?.id
        if (configuration && parent) {
          return areParented(id, parent, configuration)
        }
        return false
      }
    })
    return {
      ...state,
      updateConfiguration: (res: any) => ref.current.updateConfiguration(res) as AppContextType | undefined,
      updateMultiplier: (item: Component | Space, multiplier: Number3) => ref.current.updateComponent(item, { multiplier }),
      updateComponent: <T extends Component | Space>(item: T, toAdd: Partial<T>) => ref.current.updateComponent(item, toAdd),
      updateState: (newState: Partial<AppContextType>) => ref.current.updateState(newState),
      areParented: (id: number, parent?: number | number[]) => ref.current.areParented(id, parent),
      getState: () => ref.current as AppContextType,
      topestComponent: (id: number, cType?: PartType[]) => ref.current.topestComponent(id, cType)
    }
  }, [state])

  return hooks;
}


const replaceInConfiguration = <T extends Space | Component>(config: Configuration, item: T, toAdd: Partial<T>) => {
  config = { ...config }
  item = { ...config.items[item.id], ...toAdd } as T
  if (item.type === PartType.SPACE) {
    config.spaces = config.spaces.map(space => space.id === item.id ? item : space) as Space[]
    config.items = { ...config.items, [item.id]: item }
  } else {
    config.components = config.components.map(component => component.id === item.id ? item : component) as Component[]
    config.items = {
      ...config.items,
      [item.id]: item,
      ...Object.values(config.items).filter(({ parent }) => parent === item.id).reduce((items, cur) => {
        items[cur.id] = { ...cur, parentItem: cur }
        return items
      }, {} as typeof config.items)
    }
  }
  return config
}