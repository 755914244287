import { Validator } from '../../views/components/Three/Cube/index';
import session from "./session"


export default (validator: Validator) => {
  return {
    command: {
      sessionId: session,
      name: "AddProduct",
      id: "550",
      params: {
        catalogName: validator.name,
        componentMetaCode: validator.code,
        x: validator.x,
        y: validator.y,
        z: validator.z,
        zAngle: validator.zAngle
      }
    }
  }
}