import { Popup } from '../views/components/Three/Collada/Popup';
import {v4} from 'uuid'
import { secondsToMinutes } from './functions'
import { Disconnected } from '../views/components/Disconnected';
// import { Disconnected } from '../components/Home/Disconnected'
export const Request = {
  socket: {} as WebSocket,
  delayBeforeDisconnectMinute: 20,
  init: () => {
    const socket = new WebSocket(localStorage.service || (window as any).service || `wss://ws.wooddevelop.fr:1240`)
    socket.onopen = () => {
      opened = true
      queue.forEach(send)
      queue.splice(0) // clear queue
    }

    // receiving
    socket.onmessage = function (event: any) {
      const data = JSON.parse(event.data)
      const id = data.requestId
      if(handlers[id]){
        log(data)
        handlers[id].handler(data)
        delete handlers[id]
      }
      else {
        if(id === 'AutoSave') {
          Disconnected.unConnect(data.configurationData)
          opened  = false
        }
      }
    }

    // error
    socket.onerror = (err: any) => {
      console.error(err)
    }
    Request.socket = socket
  }
}

const queue: any[] = []
const toTicks = (ticks: number[]) => {
  for (let i = ticks.slice(-1)[0] - 1; i >= 0; i--) ticks.push(i)
  return ticks
}

const waiter = {
  timeoutId: undefined as ReturnType<typeof setTimeout> | undefined,
  ticks: toTicks([60 * Request.delayBeforeDisconnectMinute, 60 * 3, 60 * 2, 60]), // ticks in left seconds first is the max
  cancelLastPopup: undefined as ReturnType<typeof Popup.popup> | undefined
}

waiter.ticks = [...waiter.ticks]
const handlers: {[key: string]: {
  handler: (ret: any) => void,
  message: any
}} = {}
let opened = false

const logs: any = {}
const log = (data: any) => {
  let id = data.requestId, reception
  if(!id){
    if(Object.keys(logs).length === 0) document.dispatchEvent(new Event('request'))
    id = data.command?.requestId
    logs[id] = {message: data}
  }
  else {
    if(Object.keys(logs).length === 1) document.dispatchEvent(new Event('requestEnd'))
    reception = true
    logs[id].response = data
  }
  if(reception) {
    console.log(logs[id]) 
    console.log(`>> Receiving ${logs[id].message.command.name} <<`)
    delete logs[id]
  }
  else {
    console.log(`>> Sending ${logs[id].message.command.name} <<`)
    console.log(logs[id])
  }
}

export const request = (message: any) => new Promise<any>(async (ok, ko) => {
  const id = v4()
  const { sync } = message.command
  message.command.requestId = id
  if(sync) {
    delete message.command.sync
  }
  handlers[id] = { handler: (data: any) => {
    ok(data)
  }, message }
  if(!opened) queue.push(message)
  else send(message)
})

const tickWaiter = (tickIndex: number = 1) => {
  if(tickIndex === 1){
    waiter.cancelLastPopup && waiter.cancelLastPopup()
  }
  const prevTick = waiter.ticks[tickIndex - 1]
  const tick = waiter.ticks[tickIndex]
  if(tick !== undefined){
    clearTimeout(waiter.timeoutId)
    waiter.timeoutId = setTimeout(() => {
      waiter.cancelLastPopup && waiter.cancelLastPopup()
      waiter.cancelLastPopup = Popup.error({message: 'Vous serez deconnecté dans ' + secondsToMinutes(tick), duration: 0})
      tickWaiter(tickIndex + 1)
    }, (prevTick - tick) * 1000)
  } else {
    waiter.cancelLastPopup && waiter.cancelLastPopup()
    waiter.cancelLastPopup = Popup.error({message: 'Vous avez été déconnecté. Relancez l\'utilitaire', duration: 0})
  }
}

const send = (message: any) => new Promise(async (ok, ko) => {
  log(message)
  tickWaiter()
  Request.socket.send(JSON.stringify(message))
})

Request.init()

export default request