import session from "./session"

export default () => {
  return {
    command: {
      sessionId: session,
      name: "Undo",
      id: "60"
    }
  }
}
