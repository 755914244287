import { Box } from "@react-three/drei";
import React, { useEffect, useMemo, useRef } from "react";
import * as THREE from "three";
import { Zone, Number3 } from "../../../../../../types";
import { matrixForRotation, matrixForTranslation } from "../../../../../../utils/toMatrix";
import { degToRad } from "three/src/math/MathUtils";
import { useMainState } from "../../../../../../recoil/hooks";

type Props = {
  zone: Zone,
  groupPosition: Number3
}
type Mesh = THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>

const ZoneComponent: React.FC<Props> = ({ zone, groupPosition }) => {
  const { selectedTarget, selected, grids } = useMainState()
  const { position, size, orientation } = zone
  const [width, depth, height] = size
  const sizeSetted = size.map(item => item - 10)
  const ref = useRef<Mesh>()

  useEffect(() => {

    const m0 = matrixForTranslation([width / 2, - depth / 2, height / 2])
    const m1 = matrixForRotation(degToRad(orientation[2]))
    const m2 = matrixForTranslation([position[0] - groupPosition[0], position[1] - groupPosition[1], position[2] - groupPosition[2]])

    ref.current!!.position.set(0, 0, 0)
    ref.current!!.rotation.set(0, 0, 0)
    ref.current!!.scale.set(1, 1, 1)

    ref.current!!.applyMatrix4(m0)
    ref.current!!.applyMatrix4(m1)
    ref.current!!.applyMatrix4(m2)
  }, [zone]);

  const isRed = useMemo(() => selectedTarget && zone.id === selected?.id, [selected])

  return (
    zone &&
    <Box
      ref={ref as React.Ref<Mesh>}
      castShadow={grids}
      receiveShadow={grids}
      type={zone.type}
      name={zone.id + ""}
      args={sizeSetted as Number3}
    >
      <meshBasicMaterial
        opacity={isRed ? 0.8 : .3}
        color={isRed ? "#ffa7df" : "#87CEFF"}
        transparent
      />
    </Box>
  )
}

export default ZoneComponent