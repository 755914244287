import { useFrame, useThree } from "@react-three/fiber"
import React, { Ref, useEffect, useMemo, useRef, useState } from "react"
import { Object3D, Camera, Vector3 } from "three"
import { TransformControls } from "@react-three/drei"
import { TransformControls as TransformControlsType } from "three-stdlib"
import { degToRad, radToDeg } from "three/src/math/MathUtils"
import request from "../../../../../../utils/request"
import MoveSelection from "../../../../../..//utils/json/MoveSelection"
import { Number3, Zone, Component, Point, Space } from "../../../../../../types"
import { useSetRecoilState } from "recoil"
import { PartType } from "../../../../../../types/parts"
import { useMainState } from "../../../../../../recoil/hooks"
import { contextState } from "../../../../../../recoil/contextState"
import EditSpace from "../../../../../../utils/json/EditSpace"
import { Zinfo, componentToEquations, snapForHeight, snapForPoint, tryToSnap } from "../../../Collada/snap2"
import Text from "../Measure/Text"
import { calculateRectangleVertices } from "../../../Collada/utilsForSnap"

type Property = {
  position: Number3,
  rotation: Number3
}

const compareMoves = (object: Object3D, { position, orientation }: Zone | Space) => {
  const forX = object.position.x !== position[0]
  const forY = object.position.y !== position[1]
  const forZ = object.position.z !== position[2]
  const forRotation = object.rotation.z !== orientation[2]
  return forX || forY || forZ || forRotation
}

type MoveAxis = 'X' | 'Y' | 'Z' | 'XY'

export const Transform = {
  validate: (async () => {}) as () => Promise<any>
}

const Transforms = () => {
  const lastMoved = useRef<Object3D>()
  const ref = useRef<TransformControlsType<Camera>>()
  const axisRef = useRef<MoveAxis>()
  const shifted = useRef<boolean>(false)
  const { scene } = useThree()
  const setMainState = useSetRecoilState(contextState)
  const { rotate, move, configuration, updateState, selected, selectedTarget } = useMainState()

  const mode = useMemo(() => rotate ? 'rotate' : 'translate', [move, rotate])
  const [angleValue, setAngle] = useState<number>(0)
  const [translationValue, setTranslation] = useState<Number3>([0, 0, 0])

  const spaceType = useMemo(() => (selected as Space)?.spaceType, [selected])

  const object = useMemo<Object3D | undefined>(() => {
    if ((move || rotate) && selected) {
      lastMoved.current = spaceType ? scene.getObjectByName(selected.id + 'sp') : scene.getObjectByName(selected.id + '')
      return lastMoved.current
    }
  }, [spaceType, move, rotate, selectedTarget])

  const sizeOfMovable = useMemo<Number3 | undefined>(() => {
    if (selected?.type === PartType.COMPONENT) return (selected as Component).size
  }, [selected])

  const lastPos = useMemo<Property | undefined>(() => {
    if (object) {
      const { x, y, z } = object.position
      const { x: xR, y: yR, z: zR } = object.rotation
      return {
        position: [x, y, z], rotation: [xR, yR, zR],
      }
    }
  }, [object])

  useEffect(() => {
    Transform.validate = async () => {
      if (configuration && lastMoved.current && configuration.selection?.type) {
        const { name: idMoved } = lastMoved.current
        const type = configuration.selection?.type
        const group = type === PartType.SPACE ? configuration.spaces.find(one => one.id === Number(idMoved)) as Space : configuration.items[Number(idMoved)] as Zone
        if (group && compareMoves(lastMoved.current, group)) {
          const { x, y, z } = lastMoved.current.position
          if (type && type === PartType.SPACE) {
            const space = configuration.spaces.find(one => one.id === selected?.id)
            if (space) {
              // const {x: xP, y: yP, z: zP} = parentLastMoved.current.position
              return await request(EditSpace(
                space.name, space.spaceType,
                space.color, space.size,
                [x, y, z],
                [space.orientation[1], space.orientation[2], radToDeg(lastMoved.current.rotation.z)])
              )
            }
          } else {
            return await request(MoveSelection({
              x, y, z,
              zAngle: Math.round(radToDeg(lastMoved.current.rotation.z))
            }))
          }
          lastMoved.current = undefined
        }
      }
    }
  }, [object])

  const component = useMemo(() => {
    if (configuration && object) return configuration?.components.find(one => one.id === Number(object.name))
    return undefined
  }, [configuration, object])

  useEffect(() => {
    const s = ['XYZ', 'YZ', 'XZ'].forEach(key => {
      ref.current?.getObjectsByProperty('name', key).forEach(obj => {
        obj.visible = false
        obj.layers.disable(0);
      })
    })
  }, [ref.current, move])

  const three = useThree()

  const allPoints = useMemo<Vector3[]>(() => {
    if (configuration && object) {
      const spaces = configuration.spaces.map(({ position, size, orientation }) => calculateRectangleVertices(position, size, degToRad(orientation[2])))
      const component = configuration.components.filter((c: Component) => c.id !== Number(object.name)).map(({ position, size, orientation }) => calculateRectangleVertices(position, size, degToRad(orientation[2])))
      return [...spaces, ...component].reduce((acc: Vector3[], item) => {
        Object.values(item).forEach(({ x, y, z }: Point) => acc.push(new Vector3(x, y, z)))
        return acc
      }, [])
    }
    return []
  }, [configuration, object])

  const allObjects = useMemo<(Component | Space)[]>(() => {
    if (configuration && object) {
      return [...configuration.components.filter((c: Component) => c.id !== Number(object.name)),
      ...configuration.spaces]
    }
    return []
  }, [configuration, object])

  useFrame(() => {
    if (object && component && move && spaceType === undefined) {
      const { x, y, z } = object.position
      if (object.position.z < 0) object.position.set(x, y, 0)

      if (shifted.current) {
        ref.current!.setTranslationSnap(0)

        const { x, y, z } = object.position
        const rotation = component.orientation[2]
        const movableVertices = calculateRectangleVertices([x, y, z], component.size, degToRad(rotation))
        const values = tryToSnap(
          movableVertices,
          allObjects.map(componentToEquations),
          axisRef.current == 'X' ? 'front' : (axisRef.current == 'Y' ? 'side' : undefined)
        )
        if (axisRef.current === 'Z') {
          const allComponents: Zinfo[] = [...configuration!.components.filter(c => c.id !== Number(object.name))]
            .map(({ position, size }) => { return { z: position[2], height: size[2] } as Zinfo })

          const sizeOfMovable = configuration!.components.find(one => one.id === Number(object.name))
          const zSnap = snapForHeight(allComponents, { z, height: sizeOfMovable!.size[2] })
          object.position.set(x, y, zSnap ? zSnap : z)
        }

        if (values && axisRef.current === 'XY') {
          const coord = {} as Point
          let snapPointFound = false
          for (let i = 0, vertices = Object.values(movableVertices); i < vertices.length && !snapPointFound; i++) {
            const { x: vX, y: vY, z: vZ } = vertices[i]
            const verticeTypes = Object.keys(movableVertices)
            const pointVertice = new Vector3(vX, vY, vZ)
            const pointSnap = allPoints.find(one => one.distanceTo(pointVertice) < 150)
            if (pointSnap) {
              snapPointFound = true
              const { x: sX, y: sY, z: sZ } = snapForPoint(pointSnap, movableVertices, verticeTypes[i], object?.rotation?.y || 0)
              coord.x = sX
              coord.y = sY
              coord.z = sZ
            }
          }
          if (coord.x !== undefined && coord.y !== undefined) object.position.set(coord.x, coord.y, coord.z ? coord.z : 0)
          else {
            object.position.set(values.x, values.y, object.position.z)
          }
        }
        if (values && (axisRef.current === 'X' || axisRef.current === 'Y')) {
          object.position.set(values.x, values.y, object.position.z)
        }
      } else {
        ref.current?.setTranslationSnap(100)
      }
      const nVal = [object.position.x, object.position.y, object.position.z] as Number3
      if(translationValue.some((val, i) => val !== nVal[i])) setTranslation(nVal)
    }
    if (object && rotate) {
      // if(shifted.current) ref.current?.setRotationSnap(1)
      // else ref.current?.setRotationSnap(5)

      setAngle(lastMoved.current!.rotation.z)
    }
  })

  useEffect(() => {
    const on = (e: KeyboardEvent) => {
      const sh = e.code.toLowerCase().indexOf('shift') >= 0
      if (sh) shifted.current = true
    }
    const off = (e: KeyboardEvent) => {
      const ok = e.code.toLowerCase().indexOf('shift') >= 0
      const cancel = e.code.toLowerCase().indexOf('escape') >= 0
      if (ok) shifted.current = false
      if (object && lastPos && cancel) {
        lastMoved.current = undefined
        object.position.set(...lastPos.position)
        object.rotation.set(...lastPos.rotation)
        setMainState(state => ({ ...state, move: false, rotate: false }))
      }
    }
    document.addEventListener('keydown', on)
    document.addEventListener('keyup', off)
    return () => {
      document.removeEventListener('keydown', on)
      document.removeEventListener('keyup', off)
    }
  }, [object, lastPos])

  return selectedTarget && selected && (move || rotate) ? <React.Suspense>
    <TransformControls
      ref={ref as Ref<TransformControlsType<Camera>> | undefined}
      object={object} mode={mode}
      showZ={rotate || move || !spaceType}
      showX={!rotate}
      showY={!rotate}
      translationSnap={100}
      rotationSnap={degToRad(5)}
      onMouseDown={() => {
        const found = (['X', 'Y', 'Z', 'XY'] as MoveAxis[]).find((name) => {
          updateState({ orbitControl: false })
          const objects = ref.current!.getObjectsByProperty('name', name)
          return three.raycaster.intersectObjects(objects).length
        })
        axisRef.current = found
      }}
      onMouseUp={() => {
        updateState({ orbitControl: true })
        axisRef.current = undefined
      }}
      space="local"
    />
    {
      move && sizeOfMovable && axisRef.current && object && <Text
        position={[object?.position.x + sizeOfMovable[0], object?.position.y - sizeOfMovable[1], object?.position.z] as Number3}
        content={
          <div>
            {axisRef.current.includes('X') && <span>x: {Math.round(translationValue[0])}</span>}
            {axisRef.current.includes('Y') && <span>y: {Math.round(translationValue[1])}</span>}
            {axisRef.current.includes('Z') && <span>z: {Math.round(translationValue[2])}</span>}
          </div>
        }
      >
      </Text>
    }
    {rotate && object && <Text content={`${Math.round(radToDeg(angleValue))} °`} position={[object?.position.x, object?.position.y, object?.position.z] as Number3} />}
  </React.Suspense> : null
}
export default Transforms 