import React from "react";
import './Instruction.scss'

type Props = {
  children: React.ReactNode

}

const Instruction: React.FC<Props> = ({children}) => {

  return (
      <div className="Instruction">
        {children}
      </div>
  )
}

export default Instruction