import session from "./session"

export default (name: string) => {
  return {
    command: {
      name: "SaveConfiguration",
      sessionId: session,
      id: "30",
      params: {
        name: name
      }
    }
  }
}