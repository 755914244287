import { useThree } from "@react-three/fiber";
import { useEffect} from "react";
import { Vector3 } from "three";

const CamPosSaving = () => {
  const { camera: {position}} = useThree()
  const camPosCapture: Vector3 = new Vector3(-4000, -4000, 2000)

  useEffect(() => {
    const btn = document.querySelector("#save")
    const orgCamPosition = () => {
      sessionStorage.setItem("camPos", JSON.stringify(position))
      position.set(camPosCapture.x, camPosCapture.y, camPosCapture.z)
    }
    const curCamPosition = () => {
      const lastCamPos = JSON.parse(sessionStorage.getItem("camPos")!)
      if (lastCamPos) {
        position.set(lastCamPos.x, lastCamPos.y, lastCamPos.z)
        sessionStorage.removeItem('camPos')
      }
      btn?.removeEventListener("mouseup", orgCamPosition)
    }
    btn?.addEventListener("mousedown", orgCamPosition)
    btn?.addEventListener("mouseup", curCamPosition)
  }, [])

  return null
}

export default CamPosSaving