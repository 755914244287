import React, { useEffect, useMemo, useRef } from "react";
import { Number3, Connector as ConnectorType } from "../../../../../../types";
import * as THREE from 'three'
import { useLoader } from "@react-three/fiber";
import { ColladaLoader } from "three/examples/jsm/loaders/ColladaLoader";
import { matrixForTranslation, toMatrix4 } from "../../../../../../utils/toMatrix";
import { useMainState } from "../../../../../../recoil/hooks";
import { getCloseColor, urlModifier } from "../../../../../../utils/functions";

type Props = {
  connector: ConnectorType,
  groupPosition: Number3
}

const Connector: React.FC<Props> = ({ connector, groupPosition }) => {
  const { selected, areParented, selectedTarget, transparents, grids } = useMainState()
  const { matrix, collada } = connector
  const ref = useRef<THREE.Mesh>()
  // const group = useRef<Group>()
  const { scene } = useLoader(ColladaLoader, collada, loader => {
    loader.manager.setURLModifier(urlModifier)
  })
  const sceneClone = useMemo(() => scene.clone(), [scene])

  useEffect(() => {
    // RESET
    ref.current!.position.set(0, 0, 0)
    ref.current!.rotation.set(0, 0, 0)
    ref.current!.scale.set(1, 1, 1)
    //
    const m = toMatrix4(matrix)
    const m0 = matrixForTranslation([0, 0, 0])
    ref.current!.applyMatrix4(m0)
    ref.current!.applyMatrix4(m)

  }, [groupPosition])

  useEffect(() => {
    const isRed = selectedTarget && areParented(connector.id)
    const isTransparent = areParented(connector.id, transparents)
    ref.current?.traverse(child => {
      if (child instanceof THREE.Mesh && child?.material) {
        const { material } = child
        const materials = Array.isArray(material) ? material : [material]
        for (let i = 0; i < materials.length; i++) {
          materials[i].needsUpdate = false
          if (!materials[i].name.startsWith('#')) {
            const defColor = '#' + materials[i].color.getHexString()
            const rededColor = getCloseColor(defColor, "#ffa7df", .65)
            materials[i].name = defColor + '|' + rededColor
          }
          // color
          materials[i].color.setStyle(materials[i].name.split('|')[isRed ? 1 : 0])
          // transparent
          materials[i].transparent = isTransparent
          materials[i].opacity = isTransparent ? .4 : 1

          materials[i].needsUpdate = true
        }
      }
    })
  }, [selected, selectedTarget, transparents])

  return (
    <group
      castShadow={grids}
      receiveShadow={grids}
      position={[- groupPosition[0], - groupPosition[1], - groupPosition[2]]}
      onClick={() => {
        const position = new THREE.Vector3()
        const m = toMatrix4(matrix)
        m.decompose(position, new THREE.Quaternion(), new THREE.Vector3())
      }}
    >
      <primitive
        ref={ref}
        object={sceneClone}
      >
        <bufferGeometry/>
        <bufferAttribute/>
      </primitive>
    </group>
  )
}

export default Connector