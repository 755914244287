import { Assembly, ComponentPart, Catalog, CatalogItem, Space, Connector } from '../types';
import { pubUrl } from './functions';
import Configuration from '../types/configuration';
import Selection, { SizeItem } from '../types/selection';
import {
  Component, Zone, Finish, SubPart, Number3,
  ForeignComponent, SMatrix
} from '../types'
import { PartType } from '../types/parts'

const itemToFinish = (item: any, container: { [key: string]: Finish }): { [key: string]: Finish } => {
  const code = item.finishCode
  container[code] = {
    code,
    path: item.relativeImageFilePath,
    label: item.label
  }
  return container
}

export const toNbArray = (nbs: string): Number3 | SMatrix => {
  return nbs.split(',').filter(_ => _).map(Number) as (Number3 | SMatrix)
}

export const toNbArray2 = (nbs: string): SMatrix => {
  return nbs.split(' ').filter(_ => _).map(Number) as SMatrix
}

const itemToComponentPart = (item: any, config: Configuration): ComponentPart => {
  const parent = Number(item.parent)
  return {
    id: Number(item.id),
    parent,
    parentItem: config.items[parent],
    type: item.type,
    name: item.name
  } as ComponentPart
}

const itemToZone = (item: any, config: Configuration) => {
  return {
    ...itemToComponentPart(item, config),
    childrenList: item.childrenIdList.map(Number),
    orientation: toNbArray(item.orientation),
    selectable: item.selectable !== "False",
    position: toNbArray(item.position),
    size: toNbArray(item.size),
    visible: item.visible !== "False",
    readOnly: item.readOnly === "False"
  } as Zone
}

const itemToAssembly = (item: any, config: Configuration) => {
  return {
    ...itemToComponentPart(item, config),
    finish: config.finishes[item.finish],
    finishType: item.finishType
  } as Assembly
}

const itemToForeignComponent = (item: any, config: Configuration) => {
  const cPart = itemToComponentPart(item, config)
  return {
    ...cPart,
    collada: pubUrl(item.colladaFileName),
    color: item.color,
    matrix: toNbArray2(item.matrix),
    accessory: closestComponent(cPart.id, config, [PartType.ACCESSORYCOMPONENT])
  } as ForeignComponent
}

const itemToConnector = (item: any, config: Configuration) => {
  const cPart = itemToComponentPart(item, config)
  return {
    ...cPart,
    collada: pubUrl(item.colladaFileName),
    matrix: toNbArray2(item.matrix),
    scale: parseFloat(item.scale)
  } as Connector
}

const itemToComponent = (item: any, config: Configuration): Component => {
  const cpart = itemToComponentPart(item, config)
  const comp = {
    ...cpart,
    zones: [],
    parts: [],
    accessories: [],
    connectors: [],
    configuration: config,
    components: [],
    size: [...(config.items[cpart.parent] as Zone).size],
    orientation: [...(config.items[cpart.parent] as Zone).orientation],
    position: [...(config.items[cpart.parent] as Zone).position],
    readOnly: item.readOnly === 'True'
  } as Component
  return comp
}

const itemToSubpart = (item: any, config: Configuration) => {
  const size = toNbArray(item.size)
  const id = Number(item.id)
  const assembly = config.items[Number(item.parent)] as Assembly
  // const
  const subpart = {
    code: item.code, id, name: '',
    orientation: toNbArray(item.orientation),
    position: toNbArray(item.position),
    size,
    type: item.type,
    assembly,
    parent: assembly.id,
    parentItem: assembly
  } as SubPart
  return subpart
}

export const closestComponent = <T extends ComponentPart>(
  id: number,
  configuration: Configuration,
  cType: PartType[] = [PartType.COMPONENT, PartType.DRAWERCOMPONENT]
) => {
  let item = configuration.items[id] as ComponentPart
  if(cType.includes(item?.type)) return item as T
  while (item && item.parentItem) {
    if (cType.includes(item.parentItem.type)) {
      return item.parentItem as T
    }
    else item = item.parentItem
  }
}

export const topestComponent = <T extends ComponentPart>(
  id: number,
  configuration: Configuration,
  cType: PartType[] = [PartType.COMPONENT]
) => {
  let last, item = configuration.items[id] as ComponentPart
  while (item && item.parentItem) {
    if (cType.includes(item.type)) last = item as T
    item = item.parentItem
  }
  return last
}

const pushToItems = (part: ComponentPart, config: Configuration) => {
  config.items[part.id] = part
  part.parentItem = config.items[part.parent]
  part.component = closestComponent(part.parent, config)
  if (part.type === PartType.SUBPART) {
    part.component?.parts.push(part as SubPart)
  }
  else if (part.type === PartType.ZONE) {
    part.component?.zones.push(part as Zone)
  }
  else if ([PartType.DRAWERCOMPONENT, PartType.COMPONENT].includes(part.type)) {
    const parentComp = closestComponent(part.parent, config) as Component
    if (parentComp) parentComp.components.push(part as Component)
    else config.components.push(part as Component)
  }
  else if (PartType.FOREIGNCOMPONENT === part.type) {
    const parentComp = closestComponent(part.parent, config) as Component
    parentComp.accessories.push(part as ForeignComponent)
  }
  else if (PartType.CONNECTOR === part.type) {
    const parentComp = closestComponent(part.parent, config) as Component
    parentComp.connectors.push(part as Connector)
  }
}

const parse = (item: any, config: Configuration) => {
  item.children.forEach((child: any) => {
    if (!child.parent) child.parent = item.id
    switch (child.type) {
      case PartType.ZONE:
        pushToItems(itemToZone(child, config), config)
        break
      case PartType.SUBPART:
        pushToItems(itemToSubpart(child, config), config)
        child.connectors.forEach((data: any) => {
          const {colladaFileName, représentations: reps, scale} = data
          reps.forEach((rep: any) => {
            pushToItems(itemToConnector({
              ...rep, scale, colladaFileName ,
              parent: child.parent, type: PartType.CONNECTOR
            }, config), config)
          })
        })
        break
      case PartType.ASSEMBLY:
        const assembly = itemToAssembly(child, config)
        pushToItems(assembly, config)
        break
      case PartType.ACCESSORYCOMPONENT:
        pushToItems(itemToComponentPart(child, config), config)
        break
      case PartType.DRAWERCOMPONENT:
      case PartType.COMPONENT:
      case PartType.FRONTCOMPONENT:
        pushToItems(itemToComponent(child, config), config)
        break
      case PartType.FOREIGNCOMPONENT:
        pushToItems(itemToForeignComponent(child, config), config)
        break
    }


    if (child.children) parse(child, config)
  })

}



export const parseConfiguration = ({ configuration: config, Selection: selection }: any): Configuration => {
  const configuration: Configuration = {
    zones: [],
    components: [],
    depth: Number(config.project_depth),
    height: Number(config.project_height),
    width: Number(config.project_width),
    name: config.name,
    range: config.range,
    finishes: config.usedFinishes.reduce((finishes: { [key: string]: Finish }, item: any) => itemToFinish(item, finishes), {}),
    spaces: [],
    items: {},
    parsingId: new Date().getTime()
  }

  configuration.spaces = config.spaceLayout.spaces.map((one: any) => itemToSpace(one, configuration)) as Space[]

  // parsing all parts
  parse({ children: config.components }, configuration)

  // Parsing selection
  if (selection.id || selection.properties?.id) {
    configuration.selection = parseSelection(selection, configuration)
  }
  
  return configuration
}

const itemToSize = (item: any): SizeItem => {
  return typeof item === 'string' ? {
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
    value: Number(item)
  } : {
    min: Number(item.min),
    max: Number(item.max),
    value: Number(item.value)
  }
}

export const parseSelection = (item: any, configuration: Configuration) => {
  const id = Number(item.id || item.properties.id)

  const { code, defaultFinish, finish, properties: { type, range, componentMetaCode } } = item

  const simpleSize = typeof item.properties.size === 'string' && item.properties.size.split(',')
  const pItems = item.properties.componentProperties?.contextualProperties
  const sel = {
    id, code, componentMetaCode, defaultFinish, finish, range, type,
    item: configuration.items[id] as (Zone | Component),
    size: simpleSize ? {
      x: itemToSize(simpleSize[0]),
      y: itemToSize(simpleSize[1]),
      z: itemToSize(simpleSize[2])
    } : {
      x: itemToSize(item.properties.size.x),
      y: itemToSize(item.properties.size.y),
      z: itemToSize(item.properties.size.z)
    },
    properties: pItems ? pItems.map((item: any) => {
      const { comment, isForced, label, name, type, value, visible } = item
      return {
        comment, isForced: isForced === 'True',
        visible: visible === 'True', label, name, type,
        value: type === 'double' ? parseFloat(value) : value
      }
    }) : [],
  } as Selection

  if (item.properties.zoneDivision) {
    sel.zoneDivision = {
      direction: {
        enum: item.properties.zoneDivision.direction.enum.map(Number),
        type: item.properties.zoneDivision.direction.type,
        value: Number(item.properties.zoneDivision.direction.type)
      },
      type: {
        enum: item.properties.zoneDivision.type.enum.map(Number),
        type: item.properties.zoneDivision.type.type,
        value: Number(item.properties.zoneDivision.type.type)
      },
      division: {
        inputString: {
          type: item.properties.zoneDivision.division.type,
          value: item.properties.zoneDivision.division.value
        }
      }
    }
  }
  return sel
}

export const itemToCatalogItems = (item: any) => {
  const { name, components } = item
  return components.filter(({ code }: { code: string }) => !!code).map((item: any) => {
    return {
      image: pubUrl(item.imageRelativeFilePath),
      path: pubUrl(item.imageRelativeFilePath, true),
      size: toNbArray(item.size || '') as Number3,
      code: item.code, name
    }
  }) as CatalogItem[]
}

export const itemToCatalog = (item: any) => {
  const items: CatalogItem[] = []
  const catalogs: Catalog[] = []
  const catalog: Catalog = {
    image: pubUrl(item.imageRelativeFilePath),
    label: item.label, catalogs, items,
    uuid: item.uuid.replace(/[{}]/g, '')
  }
  if (item.children && item.children.length) item.children.forEach((child: any) => {
    if (child.children && child.children.length === 1 && child.label === child.children[0].label) {
      child = child.children[0]
    }
    if (!child.label.endsWith('_Interne')) catalogs.push(itemToCatalog(child))
  })
  return catalog
}

const itemToSpace = (item: any, configuration: Configuration): Space => {
  const space = {
    color: item.color,
    id: Number(item.id),
    name: item.name,
    type: "SPACE",
    orientation: toNbArray(item.orientation),
    position: toNbArray(item.position),
    size: toNbArray(item.size),
    spaceType: item.spaceType
  } as Space
  configuration.items[space.id] = space
  return space
}



export const itemToFinishesTree = (finishTree: any) => {
  const catalog = {
    catalogs: [],
    image: '',
    items: [],
    label: finishTree.label,
    uuid: ''
  } as Catalog

  itemToFinishes(catalog, finishTree)

  return catalog
}

const itemToFinishes = (parent: Catalog, { finishesNode, finishes }: any) => {
  finishesNode && finishesNode.forEach((nodeItem: any) => {
    const catalog = {
      catalogs: [],
      image: '',
      items: [],
      label: nodeItem.label,
      uuid: ''
    }
    parent.catalogs.push(catalog)
    itemToFinishes(catalog, nodeItem)
  })
  finishes && finishes.forEach(({ finishCode: code, label, relativeImageFilePath: image }: any) => {
    const item = {
      label: code,
      image: pubUrl(image),
      path: pubUrl(image),
      code, name: label,
      size: toNbArray('') as Number3
    } as CatalogItem
    parent.items.push(item)
  })
}