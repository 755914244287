import React, { useCallback, FocusEvent } from "react";

type Props = JSX.IntrinsicElements['div'] &  {
  children: React.ReactNode,
  onBlur?: () => void,
}

const SpaceWrapper: React.FC<Props> = ({ children, onBlur,  ...props }) => {

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLDivElement>) => {
      const currentTarget = e.currentTarget;
      requestAnimationFrame(() => {
        if (!currentTarget.contains(document.activeElement)) {
          onBlur && onBlur();
        }
      });
  }, [onBlur])

  return (
    <div {...props} onBlur={handleBlur}>
      {children}
    </div>
  )
}

export default SpaceWrapper;