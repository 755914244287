import { Number3 } from '../../types/index';
import session from "./session"

export default (finish: string) => {
  return {
    command: {
      sessionId: session,
      name: "SetGlobalFinish",
      id: "750",
      params: {
        finish
      }
    }
  }
}