import { useFrame } from "@react-three/fiber";
import React, { FC, Ref, useEffect, useRef } from "react";
import { BufferGeometry, Material, Mesh, Object3D, Vector3 } from "three";

type Props = {
	position: Vector3
}

const Anchor: FC<Props> = ({position:{x, y, z}}) => {
	const ref = useRef<Mesh<BufferGeometry, Material | Material[]>>()
  return <mesh
		ref={ref as Ref<Mesh<BufferGeometry, Material | Material[]>>}
		type="MEASURE"
		position={[x, y, z]}
	>
		<sphereGeometry args={[50, 100, 20]} />
		<meshBasicMaterial color={'#FF5385'} />
	</mesh>
}

export default Anchor