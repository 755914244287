import React, { useEffect, useId, useMemo, useRef } from 'react'
import { Html } from '@react-three/drei'
import { Number3 } from '../../../../../../../types'
import './Text.scss'


type Props = JSX.IntrinsicElements['mesh'] & {
  content: React.ReactNode | string,
  position?: Number3,
  scale?: Number3,
}


const Text: React.FC<Props> = ({ content, position, scale }) => {


  return (
    <mesh position={position? position : [0, 0, 0]} scale={scale} >
      <Html>
        <div
          className="text-content active"
          style={{
            backgroundColor: 'rvba(0, 0, 0, .7)',
            fontSize: '.9em'
          }}
        >
          {content}
        </div>
      </Html>
    </mesh >
  )
}


export default Text