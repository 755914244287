import { Catalog, Preference, FinishesType, Number3 } from '../types/index';
import { itemToCatalog, itemToCatalogItems, itemToFinishesTree, parseConfiguration } from './parser';
import CreateNewConfiguration from "./json/CreateNewConfiguration"
import OpenConfiguration from "./json/OpenConfiguration"
import GetComponentCatalogTree from "./json/GetComponentCatalogTree"
import GetComponentListFromNode from "./json/GetComponentListFromNode"
import request from "./request"
import MoveSelection, { MoveParams } from './json/MoveSelection';
import SelectObject from './json/SelectObject';
import LocalData, { DATA } from './LocalData';
import GetAvailableFinishesTree from './json/GetAvailableFinishesTree';
import { ComponentPart } from '../types';
import Configuration from '../types/configuration';
import { PartType } from '../types/parts';
import SetProductStructureAutoUpdate from './json/SetProductStructureAutoUpdate';


export const areParented = (
  id: number,
  parent: number | number[],
  configuration: Configuration
) => {
  const parents = typeof parent === 'number' ? [parent] : parent;
  let item = configuration.items[id] as ComponentPart | undefined;
  while (item) {
    if (parents.includes(item.id)) return true;
    item = item.parentItem;
  }
  return false;
}

export const closestComponent = <T extends ComponentPart>(
  id: number,
  configuration: Configuration,
  cType: PartType[] = [PartType.COMPONENT, PartType.DRAWERCOMPONENT]
) => {
  let item = configuration.items[id] as ComponentPart;
  if (cType.includes(item?.type)) return item as T;
  while (item && item.parentItem) {
    if (cType.includes(item.parentItem.type)) {
      return item.parentItem as T;
    }
    else item = item.parentItem;
  }
};

export const topestComponent = <T extends ComponentPart>(
  id: number,
  configuration: Configuration,
  cType: PartType[] = [PartType.COMPONENT]
) => {
  let last, item = configuration.items[id] as ComponentPart;
  while (item && item.parentItem) {
    if (cType.includes(item.type)) last = item as T;
    item = item.parentItem;
  }
  return last;
}

export const toUrl = (path: string) => {
  const url = (path.startsWith('/BaseWCO/') ? '' : '/BaseWCO/') + path
  return url.split('/').map(str => encodeURIComponent(str.normalize('NFKD'))).join('/') // str.normalize('NFKD')
    .replace(/%2520/g, '%20').replace(/%2C/g, ',')
}

export const pubUrl = (relative: string, forceDae = false) => {
  if(relative.indexOf('.dae') > 0 || forceDae) {
    const paths = relative.split('/')
    const last = paths[paths.length - 1]
    const folder = last.split('.').slice(0, -1).join('.')
    paths.splice(-1, 0, folder)
    if(forceDae) {
      paths[paths.length - 1] = folder + '.dae'
    }
    return toUrl(paths.join('/'))
  }
  else return toUrl(relative)
}

export const fillItems = async (catalog: Catalog) => {
  const { catalog: item } = await request(GetComponentListFromNode(catalog.uuid)) as any
  item && catalog.items.splice(0, catalog.items.length, ...itemToCatalogItems(item))
}



const fillNodes = (catalog: Catalog, requests: Promise<unknown>[] = []) => {
  if (catalog.catalogs.length) catalog.catalogs.forEach(ct => fillNodes(ct, requests))
  else {
    const promise = request(GetComponentListFromNode(catalog.uuid)).then((data: any) => {
      const { catalog: item } = data
      item && catalog.items.splice(0, catalog.items.length, ...itemToCatalogItems(item))
    })
    requests.push(promise)
  }
  return requests
}

export const getCurWork = () => {
  const data = (window as any).data3d as string,
  id = (window as any).uniqId as string
  return id ? {id, data} : undefined
}

export const initConfiguration = async () => {
  const work = getCurWork()
  const configuration = parseConfiguration(await request(
    work ? OpenConfiguration(work.id, work.data) : CreateNewConfiguration()
  ))
  return configuration
}

export const fillModelsContent = async (range: string) => {
  const models = itemToCatalog(await request(GetComponentCatalogTree(range)))
  await Promise.all(fillNodes(models))
  LocalData[DATA.MODELS] = models
  addParents(models)
  return models
}

export const fillModulesContent = async (range: string) => {
  const modules = itemToCatalog(await request(GetComponentCatalogTree(range, false))).catalogs[0]
  await Promise.all(fillNodes(modules))
  // LocalData[DATA.MODULES] = modules
  addParents(modules)
  return modules
}

export const moveSelection = async (moveParams: MoveParams) => {
  const newConfig = await request(MoveSelection(moveParams))
  return newConfig
}

export const selectObject = async (id: number) => {
  const selection = await request(SelectObject(id))
  return selection
}


export const addParents = (parent: Catalog) => {
  parent.catalogs.forEach(catalog => {
    catalog.parent = parent
    addParents(catalog)
  })
  parent.items.forEach(item => {
    item.parent = parent
  })
}

const toPrefs = (property: any, group: string): Preference[] => {
  return property.filter((item: any) => !item.children).map((item: any) => {
    const { type, value, visible } = item
    const oldValue = type === 'double' ? parseFloat(value as string) : value
    return {
      ...item, visible: visible === 'True', group,
      value: oldValue, oldValue
    }
  })
}

export const contextualToPrefs = (contextual: any): Preference[] => {
  const all: Preference[] = []
  all.push(...toPrefs(contextual, ""))
  contextual.filter((item: any) => item.children).forEach((item: any) => {
    all.push(...toPrefs(item.children, item.label))
  })
  return all
}


export const fillFinishesContent = async (range: string, type: FinishesType = FinishesType.GLOBAL) => {
  const sets = [''] as string[]
  let finitions = {
    catalogs: [], items: [],
    label: 'Root', uuid: type === FinishesType.DEBIT ? '' : "0"
  } as Catalog

  // if(type === FinishesType.GLOBAL){
  //   const { availableFinishesSet: allSets } = await request(GeAvailabletFinishesSet())
  //   sets.push(...allSets.map((set: any) => set.finishSet))
  // }
  // else if(type === FinishesType.SINGLE) {
  //   sets.push(...LocalData[DATA.FINITIONS].catalogs.map(({label}) => label)) 
  // }
  
  const tasks = (type === FinishesType.DEBIT ? [''] : sets).sort().map(async (set: string) => {
    const tree = await request(GetAvailableFinishesTree(range, set)) as any
    const catalog = itemToFinishesTree(tree.finishTree)
    catalog.label = set
    finitions.catalogs.push(catalog)
  })

  await Promise.all(tasks)
  
  if(type === FinishesType.GLOBAL) {
    finitions = finitions.catalogs[0]
  }
  if (type === FinishesType.GLOBAL) LocalData[DATA.FINITIONS] = finitions
  switch (type) {
    case FinishesType.GLOBAL:
      // LocalData[DATA.FINITIONS] = finitions
      break;
    case FinishesType.DEBIT:
      // LocalData[DATA.DEBIT] = finitions
      break;
  }
  addParents(finitions)
  return finitions
}

export const secondsToMinutes = (nb: number) => {
  const m = Math.floor(nb / 60), s = nb % 60
  return (m ? `${m} minute${m > 1 ? 's' : ''}` : '') + (`${m && s ? ' et ' : ''}`) + (s ? `${s}` : '') // seconde${s > 1 ? 's' : ''}
}

export const multipliedSize = ({size, multiplier = [1, 1, 1]}: {size: Number3, multiplier?: Number3}) => {
  return size.map((v, i) => v * multiplier[i]) as Number3
}

export const dividedSize = ({size, multiplier = [1, 1, 1]}: {size: Number3, multiplier?: Number3}) => {
  return size.map((v, i) => v / multiplier[i]) as Number3
}

export const areSameArrays = (arr: any[] = [], arr2: any[] = []) => arr.every((val, i) => val === arr2[i])

export const getCloseColor = (chosen: string, target: string, percentage: number) => {
  const toNumber = (color: string) => [parseInt(color.slice(0, 2), 16), parseInt(color.slice(2, 4), 16), parseInt(color.slice(4, 6), 16)]
  const chosenArr = toNumber(chosen.replace('#', ''))
  const targetArr = toNumber(target.replace('#', ''))
  return '#' + chosenArr.map((item, i) => Number(Math.round(item - (item - targetArr[i]) * percentage)).toString(16)).join('')
}

const folders = ['3D Data', 'CatalogTreeImages', 'Components', 'Connectors', 'Drawers']
export const urlModifier = (url: string) => {
  if (url.includes('BaseWCO')) {
    if(url.includes('blob:')) url = url.replace('blob:', '')
    const [, second] = url.split('BaseWCO')
    url = toUrl('/BaseWCO' + second)
  }
  return url
}