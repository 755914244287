import { Catalog, Preference, PreferenceGroup } from './../types';
import { atom, selector } from "recoil";

type SelectionState = {
  finitions?: Catalog,
  preferences: Preference[]
}

const selectionState = atom<SelectionState>({
  key: 'selectionState',
  default: { preferences: [] }
});

export const selectionPreferences = selector<Preference[]>({
  key: 'selectionPreferences',
  get: ({get}) => {
    return [...get(selectionState).preferences].sort((a, b) => a.name < b.name ? -1 : 1)
  },
  set: ({set, get}, nPrefs) => {
    const newPrefs = nPrefs as Preference[]
    const selections = get(selectionState)
    const prefs = selections.preferences
    set(selectionState, {
      ...selections,
      preferences: prefs.length ? prefs.map((one) => {
        const found = newPrefs.find(({name}) => name === one.name)
        return found ? { ...one, value: found.value } : one
      }) : newPrefs
    })
  }
})

export const selectionFinishState = selector<Preference[]>({
  key: 'selectionFinishState',
  get: ({get}) => {
    return get(selectionState).preferences.filter(({group}) => group === "Finish").map(item => ({
      ...item,
      label: item.label
    }))
  },
  set: ({set, get}, fins) => {
    const finishes = fins as Preference[]
    const state = get(selectionState)
    return set(selectionState, {
      ...state,
      preferences: state.preferences.map((one) => {
        const found = finishes.find(({name}) => name === one.name)
        return found ? { ...one, value: found.value } : one
      })
    })
  }
})

export const selectionPrefState = selector<PreferenceGroup[]>({
  key: 'selectionPrefState',
  get: ({get}) => {
    const all = get(selectionState).preferences
    return all.reduce((group, pref) => {
      const {group: grp} = pref
      let found = group.find(({label: g}) => g === grp)
      if(!found) {
        found = {label: grp, children: []} as PreferenceGroup
        group.push(found)
      }
      found.children.push(pref)
      return group
    }, [] as PreferenceGroup[])
  }
})



export default selectionState