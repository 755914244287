import React, { useEffect, useRef, useState } from 'react'
import './Popup.scss'
import clsx from 'clsx'

export enum PopupType {
  INFO = 'info', WAITING = 'waiting',
  ERROR = 'error', SUCCESS = 'success',
}

type PopupProp = {
  message: string,
  type?: PopupType,
  duration?: number,
}

const popuper = (type: PopupType) => (prop: Omit<PopupProp, 'type'> | string) => {
  if(typeof prop === 'string') return Popup.popup({type, message: prop})
  else return Popup.popup({type, ...prop})
}

export const Popup = {
  popup: (prop: PopupProp) => {
    console.log('Popup is not ready yet: ' + prop.message)
    return () => {}
  },
  error: popuper(PopupType.ERROR),
  waiting: popuper(PopupType.WAITING),
  info: popuper(PopupType.INFO),
  success: popuper(PopupType.SUCCESS),
  globalize: (act = true) => {}
}

type Pop = {message: string, type: PopupType, active: boolean}

const PopupComponent = () => {
  const [messages, setMessages] = useState<Pop[]>([])

  useEffect(() => {
    Popup.popup = ({message, duration = 0, type = PopupType.INFO}: PopupProp) => {
      if(!duration && type != PopupType.WAITING) duration = 3000
      const pop = {message, type, active: true}
      const cancel = () => {
        pop.active = false
        setMessages(messages => [...messages])
        setTimeout(() => setMessages(messages => messages.filter(m => m !== pop)), 500)
      }
      if(duration) setTimeout(cancel, duration)
      setMessages([...messages, pop])
      return cancel
    }
  }, [])

  return (
    <div className='Popup'>
      {
        messages.map(({type, message, active}, i) => {
          return <div key={i} className={clsx("message", type, {active})}>{message}</div>
        })
      }
    </div>
  )
}

export default PopupComponent