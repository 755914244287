import clsx from "clsx"
import React, { CSSProperties, useRef, useState } from "react"
import { useEffect } from "react"
import HeadOption from "../HeadOption"
import './Dimensions.scss'
import { Component, Number3 } from "../../../../types"
import { PartType } from "../../../../types/parts"
import request from "../../../../utils/request"
import SetElementSize from "../../../../utils/json/SetElementSize"
import { Popup, PopupType } from "../../../components/Three/Collada/Popup"
import { useMainState } from "../../../../recoil/hooks"
import { areSameArrays } from "../../../../utils/functions"
import { Pieces } from "../../../../views/components/Three/Cube/Components/Parts/partPiecesUtils"
import { parseConfiguration } from "../../../../utils/parser"



type Props = {
  active: boolean
}

const letters = 'xyz'
const labels = ['Largeur', 'Profondeur', 'Hauteur'] 

const Dimensions: React.FC<Props> = props => {
  const {configuration, selected, updateConfiguration, updateMultiplier, updateState} = useMainState()
  const lastComponent = useRef<Component & {multiplier: Number3}>()
  const [size, setSize] = useState<Number3>([0, 0, 0])
  const [errors, setErrors] = useState<Number3>([0, 0, 0])


  useEffect(() => {
    if(selected) {
      const type = selected.type
      if(type === PartType.COMPONENT){
        const component = selected as Component
        const size = [
          configuration?.selection?.size.x.value,
          configuration?.selection?.size.y.value,
          configuration?.selection?.size.z.value,
        ] as Number3
        setErrors([0, 0, 0])
        setSize(size)
        if(!component.multiplier) updateMultiplier(component, [1, 1, 1])
      }
    }
  }, [configuration?.selection])

  const change = (index: number, value: number) => {
    const ssize = configuration?.selection?.size
    if(ssize){
      const component = selected as Component
      const psize = [
        configuration?.selection?.size.x.value,
        configuration?.selection?.size.y.value,
        configuration?.selection?.size.z.value,
      ] as Number3
      const {min, max} = [ssize.x, ssize.y, ssize.z][index]
      let rval = value
      if(value < min) {
        rval = min
        errors[index] = 1
      }
      else if(value > max) {
        rval = max
        errors[index] = 1
      }
      else errors[index] = 0
      size[index] = value
      setSize([...size])
      lastComponent.current = {...lastComponent.current!, multiplier: psize.map((psz, i) => (i === index ? rval : size[i]) / psz) as Number3}
      updateMultiplier(component, lastComponent.current!.multiplier as Number3)
    }
  }

 useEffect(() => {
  if(props.active && selected) {
    const component = selected as Component
    if(component) lastComponent.current = {
      ...selected as Component,
      multiplier: component.multiplier || [1, 1, 1]
    }
  }
  else if(lastComponent.current){
    const { multiplier } = lastComponent.current
    if (!areSameArrays([1, 1, 1], multiplier)) {
      const {x: {value: x}, y: {value: y}, z:{value: z}} = configuration!.selection!.size
      const newSize = multiplier.map((m, i) => Math.round(m * [x, y, z][i])) as Number3
      const cancel = Popup.popup({message: "Redimension", type: PopupType.WAITING})
      request(SetElementSize(newSize)).then(async data => {
        cancel()
        // Price.update()
        const configuration = parseConfiguration(data)
        await Pieces.update(configuration)
        // updateState({configuration})
        updateConfiguration(data)
        // if(state && state.configuration) Pieces.update(state.configuration)
      })
    }
    lastComponent.current = undefined
  }
 }, [props.active])

  return (
    <HeadOption
      active={props.active}
      className={clsx('Dimensions', selected && "d-active")}
      title="Dimensions"
    >
      <div className="d-inputs">
        {
          size.map((value, index) => {
            const ssize = configuration?.selection?.size
            const {min, max} = (index === 0 ? ssize?.x : index === 1 ? ssize?.y : ssize?.z) || {}
            return (
              <label key={index}>
                <span
                  style={{'--par': `"${min !== max ? `(${min} - ${max})` : selected ? '(fixe)' : ""}"`} as CSSProperties}
                >
                  {labels[index]}
                </span>
                <input
                  value={value}
                  className={clsx({error: errors[index]})}
                  onChange={e => {
                    const val = Number((e.target as HTMLInputElement).value)
                    change(index, val)
                  }}
                  readOnly={min === max}
                  type="number"
                />
              </label>
            )
          })
        }
      </div>
    </HeadOption>
  )
}

export default Dimensions