import { atom, selector, selectorFamily } from "recoil"
import { CatalogItem, AppContextType, Catalog, CMenu, Vertices } from '../types'
import Configuration from "../types/configuration"
import defState from './contextStateValues'

export const __contextState = atom<AppContextType>({
  key: '__contextState',
  default: defState
})

export const contextState = selector<Partial<AppContextType>>({
  key: 'ContextState',
  get: ({ get }) => get(__contextState),
  set: ({ set, get }, values) => {
    set(__contextState, {
      ...get(__contextState), ...values
    })
  }
})

export const configurationState = selector<Configuration | undefined>({
  key: 'ConfigurationState',
  get: ({ get }) => get(__contextState).configuration,
  set: ({ set, get }, configuration) => {
    set(__contextState, {
      ...get(__contextState),
      configuration: configuration as Configuration
    })
  }
})

export const daeWardRobeState = selector<CatalogItem | undefined>({
  key: 'DaeWardRobeState',
  get: ({ get }) => get(__contextState).daeWardrobe,
  set: ({ set, get }, dae) => {
    set(__contextState, {
      ...get(__contextState),
      daeWardrobe: dae as CatalogItem
    })
  }
})


export const menuState = selector<CMenu | undefined>({
  key: 'MenuState',
  get: ({ get }) => get(__contextState).contextMenu,
  set: ({ set, get }, contextMenu) => {
    set(__contextState, {
      ...get(__contextState),
      contextMenu: contextMenu as CMenu
    })
  }
})

// export const activeTabState = selector<Options | string>({
//   key: 'ActiveTabState',
//   get: ({ get }) => get(__contextState).activeTab,
//   set: ({ set, get }, activeTab) => {
//     set(__contextState, {
//       ...get(__contextState),
//       activeTab: activeTab as Options
//     })
//   }
// })

export const orbitControlState = selector<boolean>({
  key: 'OrbitControlState',
  get: ({ get }) => get(__contextState).orbitControl,
  set: ({ set, get }, orbitControl) => {
    set(__contextState, {
      ...get(__contextState),
      orbitControl: orbitControl as boolean
    })
  }
})

export const rotateState = selector<boolean | undefined>({
  key: 'RotateState',
  get: ({ get }) => get(__contextState).rotate,
  set: ({ set, get }, rotate) => {
    set(__contextState, {
      ...get(__contextState),
      rotate: rotate as boolean
    })
  }
})

export const moveState = selector<boolean | undefined>({
  key: 'MoveState',
  get: ({ get }) => get(__contextState).move,
  set: ({ set, get }, move) => {
    set(__contextState, {
      ...get(__contextState),
      move: move as boolean
    })
  }
})


export const modulesState = selector<Catalog | undefined>({
  key: 'ModulesState',
  get: ({ get }) => get(__contextState).modules,
  set: ({ set, get }, modules) => {
    set(__contextState, {
      ...get(__contextState),
      modules: modules as Catalog
    })
  }
})

export const modelsState = selector<Catalog | undefined>({
  key: 'ModelsState',
  get: ({ get }) => get(__contextState).models,
  set: ({ set, get }, models) => {
    set(__contextState, {
      ...get(__contextState),
      models: models as Catalog
    })
  }
})

export const finitionsState = selector<Catalog | undefined>({
  key: 'FinitionsState',
  get: ({ get }) => get(__contextState).finitions,
  set: ({ set, get }, finitions) => {
    set(__contextState, {
      ...get(__contextState),
      finitions: finitions as Catalog
    })
  }
})

export const transparentsState = selector<number[]>({
  key: 'TransparentsState',
  get: ({ get }) => get(__contextState).transparents,
  set: ({ set, get }, trans) => {
    set(__contextState, {
      ...get(__contextState),
      transparents: trans as number[]
    })
  }

})


export const hiddenState = selector<number[]>({
  key: 'HiddenState',
  get: ({ get }) => get(__contextState).hidden,
  set: ({ set, get }, hidden) => {
    set(__contextState, {
      ...get(__contextState),
      hidden: hidden as number[]
    })
  }
})

export const zonesState = selector<boolean>({
  key: 'ZonesState',
  get: ({ get }) => get(__contextState).zones,
  set: ({ set, get }, zones) => {
    set(__contextState, {
      ...get(__contextState),
      zones: zones as boolean
    })
  }
})

export const openConfigState = selector<boolean>({
  key: 'OpenConfigState',
  get: ({ get }) => get(__contextState).openConfig,
  set: ({ set, get }, openConfig) => {
    set(__contextState, {
      ...get(__contextState),
      openConfig: openConfig as boolean
    })
  }
})

export const positionAssignedState = selector<{ [id: number]: Vertices }>({
  key: 'PositionAssignedState',
  get: ({ get }) => get(__contextState).positionAssigned,
  set: ({ set, get }, pos) => {
    set(__contextState, {
      ...get(__contextState),
      positionAssigned: pos as { [id: number]: Vertices }
    })
  }

})

// export const spacesState = selector<{ [id: number]: Space } | undefined>({
//   key: 'SpacesState',
//   get: ({ get }) => get(__contextState).spaces,
//   set: ({ set, get }, spaces) => {
//     set(__contextState, {
//       ...get(__contextState),
//       spaces: spaces as { [id: number]: Space }
//     })
//   }
// })



