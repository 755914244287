import './Space.scss'
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import 'overlayscrollbars/overlayscrollbars.css'
import { request } from "../../../utils/request"
import AddSpace from '../../../utils/json/AddSpace'
import { Number3 } from '../../../types'
import SpaceGround from './SpaceGround'
import { useMainState } from '../../../recoil/hooks'
import groundIcon from '../../../wcassets/icons/sol.png'
import TitleHeader from '../../../views/containers/Properties/TitleHeader'
import { Popup } from '../Three/Collada/Popup'


export type Floor = {
  id: number,
  title: string,
  values: Number3,
  pos: Number3,
  orientation: Number3,
  color: string
}

export default function SpaceEditorComponent() {
  const { updateConfiguration, configuration } = useMainState()

  const handleFloorAdd = async () => {
    const newSize = [1000, 1000, 10] // TODO: à parametrer
    const title = "Nouveau Sol" // TODO: à parametrer
    const color = getComputedStyle(document.body as HTMLDivElement).getPropertyValue('--main-color')
    const cancel = Popup.waiting(`Ajout d'un Nouveau Sol`)
    const newConfig = await request(AddSpace(title, '0', color, newSize as Number3, [0, 0, 0], [0, 0, 0]))
    cancel()
    updateConfiguration(newConfig)
  }

  return (
    <div className='Spaces'>
      <TitleHeader background={groundIcon} text='ESPACES' />
      <div className="sub-wrapper">
        <OverlayScrollbarsComponent
          className='space-scrollbar' defer>
          {
            configuration && configuration.spaces.map((floorItem, i) => {
              return (
                <SpaceGround
                  key={floorItem.id}
                  floorItem={floorItem}
                />
              )
            })
          }

          <div
            className="add-volume"
            onClick={handleFloorAdd}>
            <div className="add-title">
              <span className='add-icon'></span>
              <span className='add-text'>Ajouter un volume</span>
            </div>
          </div>
        </OverlayScrollbarsComponent>
      </div>
    </div >
  )
}