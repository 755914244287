import session from "./session"

export default () => {
  return {
    command: {
      sessionId: session,
      name: "Redo",
      id: "70"
    }
  }
}