import React from 'react'
import './HeadOption.scss'
import {clsx} from 'clsx'

type Props = JSX.IntrinsicElements["div"] & {
  title: string,
  children: React.ReactNode,
  active: boolean,
  className: string
}

const HeadOption: React.FC<Props> = ({title, children, active, className, ...props}) => {
  return (
    <div {...props} className={clsx('HeadOption', active && 'shown', className)}>
      <div className="ho-title">{title}</div>
      {children}
    </div>
  )
}

export default HeadOption