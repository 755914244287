import { Plane } from "@react-three/drei";
import React from "react";
import { useMainState } from "../../../../../recoil/hooks";

const Ground = () => {
  const { rayon } = useMainState()
  const sideSize = rayon? rayon * 100 : 200  * 100;

  return (
    <Plane
      args={[sideSize, sideSize]}
      rotation={[0, 0, 0]}
      position={[0, 0, 0]}
      name="terrain"
      receiveShadow
    >
      <meshPhongMaterial
        color="white"
      />
    </Plane>
  );
};

export default Ground;
