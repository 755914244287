import React, { useEffect, useMemo } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { measureState, measureValue } from "../../../../../../atoms/MeasureState"
import Anchor from "./Anchor"
import LineMeasure from "./LineMeasure"
import Pointer from "./Pointer"
import { Intersection, Vector3 } from "three"
import Text from "./Text"
import { Number3 } from "../../../../../../types"

const findMidPoint = (p1: Vector3, p2: Vector3): Number3 => {
	const x_mid = (p1.x + p2.x) / 2;
	const y_mid = (p1.y + p2.y) / 2;
	const z_mid = (p1.z + p2.z) / 2;

	return [x_mid, y_mid, z_mid];
}

export const Intersects = {
	intersections: [] as Intersection[]
}

const Measure = () => {
	const [measure, setMeasure] = useRecoilState(measureState)
	// const [measureValue, setValue] = useState<number | undefined>()
	const textMeasure = useRecoilValue(measureValue)
	useEffect(() => {
		if (measure.length === 3) setMeasure([...measure].slice(2))
	}, [measure])

	const textPosition = useMemo<Number3 | undefined>(() => textMeasure ? findMidPoint(measure[0], measure[1]) : undefined, [textMeasure])

  const [x, y, z] = measure.length > 1 ? [
    Math.abs(measure[0].x - measure[1].x).toFixed(2).replace(/\.?0+$/, ''),
    Math.abs(measure[0].y - measure[1].y).toFixed(2).replace(/\.?0+$/, ''),
    Math.abs(measure[0].z - measure[1].z).toFixed(2).replace(/\.?0+$/, '')
  ] : [0, 0, 0]

	useEffect(() => {
		console.log({eto: Intersects.intersections})
	}, [Intersects.intersections])

	return <React.Suspense>
		<Pointer getNewPoint={(point: Vector3) => setMeasure([...measure, point])} />
		{measure.length && measure.map((point, key) => <Anchor key={key} position={point} />)}
		{measure.length === 2 && <LineMeasure p1={measure[0]} p2={measure[1]} />}
		{textMeasure && 
		<Text content={
      <div>
      d: {(textMeasure || 0).toFixed(2).replace(/\.?0+$/, '')} <br/>
        {!!Number(x) && <span style={{fontSize: '.8em'}} className="mini">x: {x}<br/></span>}
        {!!Number(y) && <span style={{fontSize: '.8em'}}  className="mini">y: {y}<br/></span>}
        {!!Number(z) && <span style={{fontSize: '.8em'}}  className="mini">z: {z}</span>}
      </div>
    } position={textPosition}/>}
	</React.Suspense>
}

export default Measure