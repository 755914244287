import React, { FC } from "react"
import Collada from "../../../Collada"
import { Validator } from "../.."
import { useMainState } from "../../../../../../recoil/hooks"

type Props = {
  setValidate: (validate: Validator) => void
}

const DAE: FC<Props> = ({setValidate}) => {
  const {daeWardrobe} = useMainState()

  return <React.Suspense>
    {
      daeWardrobe && <Collada validation={(validate: Validator) => setValidate(validate)} />
    }
  </React.Suspense>
}

export default DAE