import { Box, Edges } from '@react-three/drei'
import React, { Ref, useEffect, useMemo, useRef } from 'react'
import { Number3, Space, SpaceType } from '../../../../../../types'
import { Mesh, MeshPhysicalMaterial } from 'three'
import {
  matrixForRotation,
  matrixForTranslation,
} from '../../../../../../utils/toMatrix'
import { degToRad } from 'three/src/math/MathUtils'
import { PartType } from '../../../../../../types/parts'
import { useMainState } from '../../../../../../recoil/hooks'

type Props = {
  space: Space
}

const Spaces: React.FC<Props> = ({ space }) => {
  const {selected, configuration, transparents, selectedTarget} = useMainState()
  const groupRef = useRef<THREE.Group>()
  const material = useRef<MeshPhysicalMaterial>()
  const meshRef = useRef<Mesh>()
  const { spaces } = configuration!!
  const { id, orientation, color } = space
  const transparent = transparents.includes(space.id)
  const isWall = space.spaceType === SpaceType.WALL

  const size = useMemo(() => {
    return isWall ? [space.size[0], space.size[2], space.size[1]] as Number3 : space.size
  }, [isWall, space.size])

  useEffect(() => {
    if (material.current) {
      material.current.transparent = transparent
      material.current.needsUpdate = true
    }
  }, [transparent])

  const colorChange = useMemo(() => {
    if (spaces && Object.keys(spaces).find((one) => Number(one) === id)) {
      return spaces[id].color
    }
    return color
  }, [spaces, configuration])

  const isSelected = useMemo(() => selectedTarget && selected?.id === id, [selected])


  useEffect(() => {
    if (meshRef.current && groupRef.current) {
      const multiplier = space.multiplier || [1, 1, 1]
      const posMatrix = matrixForTranslation([size[0] / 2, (isWall ? 1 : -1) * size[1] / 2, ((isWall ? 1 : -1) *  size[2] / 2)])
      const groupRotMatrix = matrixForRotation(degToRad(orientation[2]))
      meshRef.current.position.set(0, 0, 0)
      meshRef.current.rotation.set(0, 0, 0)
      meshRef.current.scale.set(1, 1, 1)
      groupRef.current.position.set(0, 0, 0)
      // parentRef.current.position.set(0, 0, 0)
      groupRef.current.rotation.set(0, 0, 0)
      groupRef.current.scale.set(1, 1, 1)

      meshRef.current.applyMatrix4(posMatrix)
      // meshRef.current.applyMatrix4(rotMatrix)
      groupRef.current.applyMatrix4(groupRotMatrix)
      // groupRef.current.position.set(...space.position.map((item, i) => i === 2 && !isWall? item - space.size[2] : item) as Number3)
      groupRef.current.position.set(...space.position)
      groupRef.current.scale.set(...(isWall ? [multiplier[0], multiplier[2], multiplier[1]] : multiplier) as Number3)
    }
  }, [space, configuration, isWall])

  return (
      <group
        ref={groupRef as React.MutableRefObject<THREE.Group>}
        name={space.id + 'sp'}
      >
        <mesh ref={meshRef as React.Ref<Mesh>}>
          <Box
            name={space.id + ''}
            args={size}
            type={PartType.SPACE}
          >
            <meshPhysicalMaterial
              ref={material as Ref<MeshPhysicalMaterial>}
              color={colorChange}
              opacity={transparent ? 0.4 : 1}
            />
            {isSelected && <Edges color={'#FF0000'} />}
          </Box>
        </mesh>
      </group>
  )
}

export default Spaces
