import React from 'react'
import { Catalog, CatalogItem } from '../../../../types'
import Loading from '../Loading'
import './Tree.scss'
import Accordion from '../../Accordion'

type Props = {
  finition?: boolean,
  catalogs: Catalog[],
  update?: (catalogs: Catalog[]) => void,
  component: React.FC<{ items: CatalogItem[] }>,
  subCategory?: boolean
}

const Tree : React.FC<Props> = ({ catalogs, component: Component, update, subCategory = false }) => {
  return (
    <React.Fragment>
      {
        catalogs.map(({ uuid, label, catalogs, items }, i) => {
          return catalogs.length ? (
            <Accordion
              onOpen={async () => {
                const empties = catalogs.filter(({catalogs, items}) => !catalogs.length && !items.length)
                if(empties.length){
                  update && update(empties)
                }
              }}
              key={uuid + i}
              title={label}
            >
              {
                <div className="TreeData">
                  <Tree {...{update, subCategory, component: Component}} catalogs={catalogs} />
                </div>
              }
            </Accordion>
          ) : (
            subCategory ? <div key={uuid + i} className="TreeData">
              <span className='mini-title'>{label}</span>
              {items.length ? <Component items={items} /> : <Loading/>}
            </div> :
            <Accordion
              onOpen={async () => {
                // const empties = catalogs.filter(({catalogs, items}) => !catalogs.length && !items.length)
                // if(empties.length){
                //   update && update(empties)
                // }
              }}
              key={uuid || label}
              title={label}
            >
              {items.length ? <Component items={items} /> : <Loading/>}
            </Accordion>
          )
        })
      }
    </React.Fragment>
  )
}

export default Tree