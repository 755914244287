import React from "react"
import HeadOption from "../HeadOption"
import { Vector3 } from "three"
import './Vue.scss'
import { useMainState } from "../../../../recoil/hooks"


const views = [
  { camera: [0, -20000, 0], label: 'Vue FACE' },
  { camera: [0, 20000, 0], label: 'Vue ARRIERE' },
  { camera: [-20000, 0, 0], label: 'Vue GAUCHE' },
  { camera: [20000, 0, 0], label: 'Vue DROITE' },
  { camera: [0, 0, 20000], label: 'Vue DESSUS' },
]

type Props = {
  active: boolean
}

const Vue: React.FC<Props> = ({active}) => {
  const { updateState} = useMainState()

  return (
    <HeadOption
      title={'vue'}
      className={"Vue"}
      active={active}
    >
      <div className="selections">
        {
          views.map(({camera, label}) => {
            return (
              <div
                key={label}
                data-selct='front'
                className="selection"
                onClick={() => {
                  updateState({ cameraPos: new Vector3(camera[0], camera[1], camera[2]) })
                }}
              >{label}</div>
            )
          })
        }
      </div>
    </HeadOption>
  )
}

export default Vue