import React, { useCallback, useEffect, useMemo } from 'react'
import './Properties.scss'
import proprieteIcon from '../../../wcassets/icons/Proprietes.svg'
import TitleHeader from './TitleHeader'
import Instruction from './Instruction'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import request from '../../../utils/request'
import Accordion from '../../components/Accordion'
import MultiChoice from './MultiChoice'
import NumberInput from './NumberInput'
import SimpleInput from './SimpleInput'
import { selectionPrefState, selectionPreferences } from '../../../atoms/selectionState'
import ValuesPart from './ValuesPart'
import { Action, Preference } from '../../../types'
import SetSelectionParameters from '../../../utils/json/SetSelectionParameters'
import { Popup, PopupType } from '../../components/Three/Collada/Popup'
import { useMainState } from '../../../recoil/hooks'
import { Pieces } from '../../../views/components/Three/Cube/Components/Parts/partPiecesUtils'


type Props = {
  onclick?: () => void
}

const Properties: React.FC<Props> = () => {
  const { updateConfiguration, selectedTarget, updateState } = useMainState()
  const setPreferences = useSetRecoilState(selectionPreferences)
  const selectionPrefs = useRecoilValue(selectionPrefState)
  const myId = useMemo(() => selectedTarget, [])
  useEffect(() => {
    if(selectedTarget !== myId) updateState({activePopup: Action.EMPTY})
  }, [selectedTarget])

  const update = useCallback(async <T,>(item: Preference, value?: T, send = true) => {
    if (value !== undefined) setPreferences([{ ...item, value } as Preference])
    if (send || value === undefined) {
      const val = (value ? value : item.value) as string | number | boolean
      const cancel = Popup.popup({ message: `Sauvegarde de la propriété`, type: PopupType.WAITING })
      const res = await request(SetSelectionParameters(item.name, val)) as any

      cancel()
      if (res.configuration) {
        Popup.popup({ message: `Propriété sauvegardée avec succès`, type: PopupType.SUCCESS, duration: 3000 })
        const data = updateConfiguration(res)
        setPreferences([{ ...item, value: val, oldValue: val } as Preference])
        data?.configuration && Pieces.update(data.configuration)
      }
      else {
        setPreferences([{ ...item, value: item.oldValue } as Preference])
        Popup.popup({ message: `Une erreur est survenue lors de la sauvegarde`, duration: 3000, type: PopupType.ERROR })
      }
    }
  }, [])
  useEffect(() => {
    const ha = selectionPrefs.filter(({label, children}, i) => !label)
     console.log({ha, selectionPrefs})
  }, [selectionPrefs])
  
  return (
    <div className='Properties'>
      <TitleHeader background={proprieteIcon} text='PROPRIETES' />
      <div className="menu-content">
        <Instruction>
          Veuillez renseigner les infos ci-dessous pour prendre en compte des modifications
          sur le composant sélectionné si souhaité. <br />
          Puis validez. Sinon, les valeurs par défauts seront appliquées.
        </Instruction>


        <div className="sub-wrapper">

          <OverlayScrollbarsComponent defer className='scroll-area'>

            <div className="sub-part">
              {
                selectionPrefs.filter(({ label }, i) => label !== 'Finish' && label !== 'POIGNÉES').map(({ label, children }, i) => {
                  return (
                    <Accordion 
                      key={i} 
                      title={label ? label : (Object.values(children).some(child => child.visible === true) ? 'Global' : '')} 
                      defaultOpen={true}
                    >
                      {
                        children.map((item, i) => {
                          const { comment, label, type, value, values, visible } = item
                          return (
                            visible && <div className="sub-property" title={comment} key={i}>
                              <div className="sub-text">{label} : </div>
                              <div className="inputs-parent">
                                {
                                  values && <div className="values-wrapper">
                                    <ValuesPart
                                      value={value as string}
                                      values={values}
                                      onChange={(value) => {
                                        update(item, value)
                                      }}
                                    />
                                  </div>
                                }
                                {
                                  ['OUI', 'NON'].includes(value as string) && <MultiChoice
                                    value={value as string}
                                    onChange={(value) => update(item, value)}
                                  />
                                }
                                {type === 'bool' && (
                                  <MultiChoice
                                    value={(value === 'true' ? 'OUI' : 'NON') as string}
                                    onChange={(val) => {
                                      if (val === 'OUI') update(item, 'true');
                                      else update(item, 'false');
                                    }}
                                  />
                                )}
                                {
                                  type === 'double' && !values &&
                                  <NumberInput className='inputs' value={parseFloat(value as string)}
                                    onBlur={() => update(item)}
                                    onKeyDown={(e) => {
                                      if(e.key === "Enter") {
                                        update(item)
                                      }
                                    }}
                                    onChange={(value) => update(item, value, false)}
                                  />
                                }
                                {
                                  type === 'string' && !['OUI', 'NON'].includes(value as string) && !values &&
                                  <SimpleInput className='inputs' value={value as string}
                                    onBlur={() => update(item)}
                                    onChange={(value) => update(item, value, false)}
                                  />
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </Accordion>
                    
                  )
                })
              }
            </div>
          </OverlayScrollbarsComponent>
        </div>


      </div>

    </div >
  )
}


export default Properties;

// function updateConfiguration(data: any) {
//   throw new Error('Function not implemented.')
// }
