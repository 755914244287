import clsx from 'clsx'
import React, { useEffect, useState, useRef } from 'react'
import './Accordion.scss'
import { useMainState } from '../../../recoil/hooks'

type Props = {
  children: React.ReactNode,
  title: string,
  onOpen?: () => void,
  defaultOpen?: boolean 
}

const Accordion: React.FC<Props> = ({title = 'Accordion', onOpen, children, defaultOpen = false}) => {
  const { configuration } = useMainState()
  const [opened, setOpened] = useState(false)
  const computer = useRef<HTMLDivElement>()

  useEffect(() => {
    if(opened) {
      onOpen && onOpen()
    }
  }, [opened, configuration])

  useEffect(() => {
    if(computer.current){
      const div: HTMLDivElement = computer.current
      function outputsize() {
        const container = div.parentNode as HTMLDivElement
        container.style.setProperty('--max', div.offsetHeight + 'px')
      }
      outputsize()
      new ResizeObserver(outputsize).observe(computer.current)
    }
  }, [computer.current])
  return (
    <div className={clsx({Accordion: title}, {opened: opened || defaultOpen})}>
      <div onClick={() => setOpened(!opened)} className="acc-title">{title}</div>
      <div className="acc-container">
        <div
          ref={ref => { if(ref) computer.current = ref }}
          className="acc-computer"
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default Accordion