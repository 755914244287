import { Catalog, CatalogItemPlus, MachinedPiece as Piece, PresetItem } from './../types/index';
import LocalData, { DATA } from '../utils/LocalData';
import { atom, selector } from "recoil";
import { addParents } from '../utils/functions';

type Item = boolean | string | number | CatalogItemPlus

type Debit = {
  debits: Item[][],
  usinage: boolean,
  presets: { panels: PresetItem[], chant: PresetItem[]},
  info: string,
  pieces: Piece[],
  finishes: Catalog
}

const mainState = atom<Debit>({
  key: 'debitMainState',
  default: {
    pieces: [],
    usinage: true,
    debits: LocalData[DATA.PANELS] || [],
    presets: LocalData[DATA.PRESETS] || {
      chant: [],
      panels: []
    },
    info: '',
    finishes: LocalData[DATA.DEBIT] && addParents(LocalData[DATA.DEBIT])
  }
});

export const debitState = selector<Item[][]>({
  key: 'debitDebitsState',
  get: ({ get }) => get(mainState).debits,
  set: ({ set, get }, debits) => {
    LocalData[DATA.PANELS] = debits
    set(mainState, {
      ...get(mainState),
      debits: debits as Item[][]
    })
  }
})

export const debitFinishesState = selector<Catalog>({
  key: 'debitFinishesState',
  get: ({ get }) => get(mainState).finishes,
  set: ({ set, get }, finishes) => {
    // LocalData[DATA.DEBIT] = finishes
    set(mainState, {
      ...get(mainState),
      finishes: finishes as Catalog
    })
  }
})

export const debitUsinageState = selector<boolean>({
  key: 'debitUsinageState',
  get: ({ get }) => get(mainState).usinage,
  set: ({ set, get }, usinage) => {
    const state = get(mainState)
    set(mainState, {
      ...state,
      usinage: usinage as boolean,
      debits: usinage ? state.debits : state.debits.map(child => child.slice(0, 10))
    })
  }
})

export const debitPanelsPresetState = selector<PresetItem[]>({
  key: 'debitPanelPresetState',
  get: ({ get }) => get(mainState).presets.panels,
  set: ({ set, get }, panels) => {
    const state = get(mainState)
    const presets = {
      ...state.presets,
      panels: panels as PresetItem[]
    } as Debit['presets']
    LocalData[DATA.PRESETS] = presets
    set(mainState, {
      ...state,
      presets
    })
  }
})

export const debitChantPresetState = selector<PresetItem[]>({
  key: 'debitChantPresetState',
  get: ({ get }) => get(mainState).presets.chant,
  set: ({ set, get }, chant) => {
    const state = get(mainState)
    const presets = {
      ...state.presets,
      chant: chant as PresetItem[]
    } as Debit['presets']
    LocalData[DATA.PRESETS] = presets
    set(mainState, {
      ...state,
      presets
    })
  }
})



export const infoState = selector<string>({
  key: 'infoIconState',
  get: ({ get }) => get(mainState).info,
  set: ({ set, get }, info) => {
    const state = get(mainState)
    set(mainState, {
      ...state,
      info: info as string
    })
  }
})

export const pieceState = selector<Piece[]>({
  key: 'piecesState',
  get: ({ get }) => get(mainState).pieces,
  set: ({ set, get }, pieces) => {
    const state = get(mainState)
    set(mainState, {
      ...state,
      pieces: pieces as Piece[]
    })
  }
})


export default debitState