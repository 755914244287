import { atom, selector } from "recoil";
import { MachinedPiece } from "../types";

export const piecesMainState = atom<MachinedPiece[]>({
  key: 'piecesMainState',
  default: []
})

export const partPiecesState = selector<MachinedPiece[]>({
  key: 'partPiecesState',
  get: ({ get }) => get(piecesMainState),
  set: ({ set, get }, pieces) => {
    set(piecesMainState, [...(pieces as MachinedPiece[])])
  }
})