// export enum PartType {
//   SUBPART = "SUBPART",
//   ZONE = 'ZONE',
//   ASSEMBLY = 'ASSEMBLY',
//   COMPONENT = 'COMPONENT',
//   DRAWERCOMPONENT = 'DRAWERCOMPONENT',
//   ACCESSORYCOMPONENT = 'ACCESSORYCOMPONENT',
//   FOREIGNCOMPONENT = 'FOREIGNCOMPONENT'
// }

//TODO: From WCMODELER
export enum PartType {
  SUBPART = "SUBPART",
  ZONE = 'ZONE',
  ASSEMBLY = 'ASSEMBLY',
  COMPONENT = 'COMPONENT',
  DRAWERCOMPONENT = 'DRAWERCOMPONENT',
  ACCESSORYCOMPONENT = 'ACCESSORYCOMPONENT',
  FOREIGNCOMPONENT = 'FOREIGNCOMPONENT',
  SPACE = 'SPACE',
  FRONTCOMPONENT = 'FRONTCOMPONENT',
  CONNECTOR = 'CONNECTOR',
  MACHINED = 'MACHINED'
}