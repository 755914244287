import React, { useState, useEffect } from 'react'
import './Header.scss'

import Dimensions from "./Dimensions"
import Vue from "./Vue"
import Design from "./Design"
import Settings from "./Settings"
import request from '../../../utils/request'
import Undo from '../../../utils/json/Undo'
import Redo from '../../../utils/json/Redo'
import CreateNewConfiguration from '../../../utils/json/CreateNewConfiguration'
import Copy from '../../../utils/json/Copy'
import SaveWork from '../../components/SaveWork'
import { Popup, PopupType } from '../../components/Three/Collada/Popup'
import { useMainState } from '../../../recoil/hooks'
import { parseConfiguration } from '../../../utils/parser'
import { Price } from '../../../views/components/PriceInput'
import { Pieces } from '../../../views/components/Three/Cube/Components/Parts/partPiecesUtils'

enum HActive {
  NONE, SIZE, VIEW, DESIGN, SETTING
}

const Header = () => {
  const { selected, updateConfiguration, updateState, measure: measureMode } = useMainState()
  const [active, setActive] = useState<HActive>(HActive.NONE)

  useEffect(() => {
    const canvas = document.querySelector('canvas') as HTMLCanvasElement
    const ok = () => setActive(HActive.NONE)
    canvas.addEventListener('click', ok)
    return () => canvas.removeEventListener('click', ok)
  })
  return (
    <div className="Header">
      <div className="logo" />
      <div className="header-body">
        <span className='message'>Le configurateur des pros</span>
        <ul className="navbar">
          <li
            className='undo'
            onClick={async () => {
              const cancel = Popup.popup({ message: "Chargement en cours", type: PopupType.WAITING })
              const res = await request(Undo())
              if (res) {
                // Price.update()
                const configuration = parseConfiguration(res)
                await Pieces.update(configuration)
                updateState({configuration})
              }
              // updateConfiguration()
              cancel()
            }}
          />

          <li
            className='redo'
            onClick={async () => {
              const cancel = Popup.popup({ message: "Chargement en cours", type: PopupType.WAITING })
              const res = await request(Redo())
              if (res) {
                Price.update()
                const configuration = parseConfiguration(res)
                await Pieces.update(configuration)
                updateState({configuration})
              }
              cancel()
            }}
          />

          <li
            className='new'
            onClick={async () => {
              const cancel = Popup.popup({ message: "Nouvelle configuration", type: PopupType.WAITING })
              updateConfiguration(await request(CreateNewConfiguration()))
              cancel()
            }}
          />
          <li className='copy'
            onClick={async () => {
              selected && request(Copy())
            }}
          />
          <li
            className='screen'
            onClick={() => {
              if (!document.fullscreenElement) {
                document.querySelector('html')?.requestFullscreen()
              }
              else {
                document.exitFullscreen()
              }
            }}
          />
          <li
            className='save'
            onClick={e => {
              (e.target as HTMLLIElement).querySelector('input')?.focus()
            }}
          >
            <SaveWork />
          </li>
          {/* <li className='open' /> */}
          <li 
            className='logout'
            onClick={e => window.location.href = "/cart/show" }
           />
        </ul>
      </div>
      <ul className="center-nav">
        {/* <li
          className={clsx('up', { inactive: !selected00 })}
          onClick={() => {
            if (selected00) {
              // setSelectAllMeshes(true)
            }
          }}
        /> */}
        <li
          className='size'
          onClick={() => setActive(HActive.SIZE)}
        >
          <Dimensions active={active === HActive.SIZE}/>
        </li>

        <li
          className='view'
          onClick={() => setActive(HActive.VIEW)}
        >
          <Vue active={active === HActive.VIEW} />
        </li>

        <li
          className='design'
          onClick={() => setActive(HActive.DESIGN)}
        >
          <Design active={active === HActive.DESIGN} />
        </li>
        <li
          className='setting'
          onClick={() => setActive(HActive.SETTING)}
        >
          <Settings active={active === HActive.SETTING} />
        </li>
        <li
          className='screenshot'
          onClick={() => {
            const canvas = document.querySelector('canvas');
            const dataUrl = canvas!.toDataURL()
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'wckazed_capture.png';
            link.click();
            link.remove()
          }}
        >
        </li>

        <li
          className='measure'
          onClick={() => updateState({measure: !measureMode})}
        >
        </li>
      </ul>
    </div>
  )
}
export default Header
