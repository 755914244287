import { degToRad } from "three/src/math/MathUtils"
import { Component, Point, Space, Vertices, Number3 } from "../../../../types"
import { calculateRectangleVertices } from "./utilsForSnap"
import { Vector3 } from "three"


type ComponentEquation = {
  component: Component | Space,
  left: LineEquation,
  right: LineEquation,
  back: LineEquation,
  front: LineEquation,
  center: Point
}

export type LineEquation = {
  a?: number, b?: number, x?: number
}

type SnapType = 'front' | 'side' | undefined

export const pointsToEquation = (p0: Point, p1: Point): LineEquation => {
  const { x: x0, y: y0 } = p0
  const { x: x1, y: y1 } = p1
  const equation = {} as LineEquation
  if (x0 === x1) equation.x = x0
  else {
    equation.a = (y0 - y1) / (x0 - x1)
    equation.b = y0 - equation.a * x0
  }
  return equation
}

export const componentToEquations = (component: Component | Space): ComponentEquation => {
  const { position, orientation, size } = component
  const { topLeft, topRight, bottomLeft, bottomRight, center } = calculateRectangleVertices(position, size, degToRad(orientation[2]))
  return {
    component,
    left: pointsToEquation(topLeft, bottomLeft),
    right: pointsToEquation(topRight, bottomRight),
    back: pointsToEquation(topLeft, topRight),
    front: pointsToEquation(bottomLeft, bottomRight),
    center: center!
  }

}

export const positionsToEquations = ({ topLeft, topRight, bottomLeft, bottomRight }: Vertices): Omit<ComponentEquation, 'component' | 'center'> => {
  return {
    left: pointsToEquation(topLeft, bottomLeft),
    right: pointsToEquation(topRight, bottomRight),
    back: pointsToEquation(topLeft, topRight),
    front: pointsToEquation(bottomLeft, bottomRight)
  }
}

export const lineIntersection = (l1: LineEquation, l2: LineEquation): Point | undefined => { // tested

  if (l1.a === l2.a) return undefined
  const point = {} as Point
  if (l1.a && l2.a) {
    point.x = (l2.b! - l1.b!) / (l1.a - l2.a);
    point.y = l1.a * point.x + l1.b!;
  } else if (l1.a === undefined) {
    point.x = l1.x!;
    point.y = l2.b!;
  } else if (l2.a === undefined) {
    point.x = l2.x!;
    point.y = l1.b!;
  }
  return point
}

export const distancePointLine = (line: LineEquation, point: Point): number => {
  let distance = 0
  if (line.a === undefined) distance = Math.abs(line.x! - point.x)
  else distance = Math.abs(-line.a! * point.x + point.y - line.b!) / Math.sqrt(Math.pow(-line.a!, 2) + 1)
  return distance
}

const findEquationsOfPoint = (
  point: Point, vertices: Vertices, eqs: Omit<ComponentEquation, 'component' | 'center'>
) => {
  const { left, right, front, back } = eqs
  const { topLeft, topRight, bottomLeft, bottomRight } = vertices
  switch (point) {
    case topLeft: return { side: left, front: back }
    case topRight: return { side: right, front: back }
    case bottomLeft: return { side: left, front }
    case bottomRight: return { side: right, front }
    default: return { side: right, front }
  }
}

const minDist = 100;

// export const tryToSnap_old = (vertices: Vertices, others: ComponentEquation[], snapType: SnapType) => {
//   const { topLeft, topRight, bottomLeft, bottomRight } = vertices
//   const { left, right, front, back } = positionsToEquations(vertices)
//   const equations = others.reduce((eqs, { left, back, right, front }) => {
//     eqs.push(left, back, right, front)
//     return eqs
//   }, [] as LineEquation[])

//   const { equation, distance, chosen } = equations.reduce(({ distance, equation, chosen }, eq) => {
//     const points = [topLeft, topRight, bottomLeft, bottomRight] // movable
//     const mEqs = { left, right, front, back }

//     const dists = points.map(point => distancePointLine(eq, point))
//     const min = Math.min(...dists)
//     const point = points[dists.indexOf(min)]
//     return (!equation || min < distance) && min > 0 ? { equation: eq, distance: min, chosen: point } : { equation, distance, chosen }
//   }, { distance: 0 } as { equation: LineEquation | undefined, distance: number, chosen: Point | undefined })

//   if (distance < minDist && equation && chosen) {
//     let pointToSnapOn
//     if (snapType) {
//       const { front: frontLine, side } = findEquationsOfPoint(chosen, vertices, { left, right, front, back })
//       pointToSnapOn = lineIntersection(snapType === 'front' ? frontLine : side, equation)
//     }
//     else { // We'll now finding the one in Middle 
//       const perpEquation = { a: -equation.a!, b: chosen.y + equation.a! * chosen.x } as LineEquation
//       pointToSnapOn = lineIntersection(perpEquation, equation)
//     }
//   }
// }

export const distancePoints = (A: Point, B: Point): number => {
  const dx = B.x - A.x;
  const dy = B.y - A.y;
  return Math.sqrt(dx * dx + dy * dy);
}


type Computed = {
  mPoint: Point,
  line: LineEquation,
  detail: {
    line: LineEquation,
    distance: number,
    point: Point,
    type: SnapType
  }
}

const perpendicularFromLine = (point: Point, line: LineEquation): LineEquation => { // tested
  const perpendicular = {} as LineEquation
  const { a, b, x: lineX } = line
  const { x, y } = point

  if (a !== undefined) {
    perpendicular.a = a === 0 ? undefined : - 1 / a
    perpendicular.b = a === 0 ? undefined : y - perpendicular.a! * x
    perpendicular.x = a === 0 ? x : undefined
  } else if (a === undefined) {
    perpendicular.a = 0
    perpendicular.b = y
  }

  return perpendicular
}

export const tryToSnap = (vertices: Vertices, others: ComponentEquation[], snapType?: SnapType) => {
  const { topLeft, topRight, bottomLeft, bottomRight } = vertices
  const { left, right, front, back } = positionsToEquations(vertices)

  const equations = others.reduce((eqs, { left, back, right, front }) => { // liste de toutes les droites qui vont recevoir les snaps
    eqs.push(left, back, right, front)
    return eqs
  }, [] as LineEquation[])

  const closeComputed = equations.reduce((minimum, eq) => {
    const computed = [topLeft, topRight, bottomLeft, bottomRight].map((mPoint) => {
      let line: LineEquation, point: Point | undefined
      const { front: mFront, side } = findEquationsOfPoint(mPoint, vertices, { left, right, front, back })
      if (snapType) {
        line = snapType === 'front' ? mFront : side
        point = lineIntersection(eq, line)
      } else {
        line = perpendicularFromLine(mPoint, eq)
        point = lineIntersection(eq, line)
      }
      const distance = point ? distancePoints(point, mPoint) : 0
      if (distance < minDist && point) return {
        mPoint,
        line: eq,
        detail: {
          line,
          point,
          distance,
          type: snapType
        }
      } as Computed
    }).reduce((min, computable: Computed | undefined) => {
      if (!min) return computable
      else if (!computable) return min
      else if (computable) return computable.detail.distance < min.detail.distance ? computable : min
    }, undefined as Computed | undefined)

    if (!minimum) return computed
    else if (!computed) return minimum
    else return computed.detail.distance < minimum.detail.distance ? computed : minimum
  }, undefined as Computed | undefined)


  if (closeComputed) return {
    x: topLeft.x + closeComputed.detail.point.x - closeComputed.mPoint.x,
    y: topLeft.y + closeComputed.detail.point.y - closeComputed.mPoint.y
  } as Point
}

const makeAnglePositive = (angle: number) => {
  const fullCircle = 2 * Math.PI;
  while (angle < 0) {
    angle += fullCircle;
  }
  return angle % fullCircle;
}



export const snapForPoint = (snapPoint: Vector3, daeVertices: Vertices, typeToSnap: string, angle: number): Point => {
  const { x, y, z } = snapPoint
  const { x: tX, y: tY, z: tZ } = daeVertices.topLeft
  
  const sign = makeAnglePositive(angle) <= Math.PI ? 1 : -1

  switch (typeToSnap) {
    case 'topLeft': return { x, y, z }
    case 'topRight': return { x: x + (tX - daeVertices.topRight.x), y: y + (tY - daeVertices.topRight.y), z: tZ }
    case 'bottomRight': return { x: x + (tX - daeVertices.bottomRight.x), y: y + (tY - daeVertices.bottomRight.y), z: tZ }
    case 'bottomLeft': return { x: x + (tX - daeVertices.bottomLeft.x), y: y + (tY - daeVertices.bottomLeft.y), z: tZ }
    default: return { x, y, z }
  }
}

export type Zinfo = {
  z: number,
  height: number,
}

export const snapForHeight = (zComponents: Zinfo[], movable: Zinfo): number | undefined => {
  const coord = {} as Point
  for(const component of zComponents) {
    if(Math.abs(component.z - movable.z) < 100) {
      coord.z = component.z
      break
    }
    if(Math.abs(component.z + component.height - movable.z) < 100) {
      coord.z = component.z + component.height
      break
    }
    if(Math.abs(component.z + component.height - (movable.z + movable.height)) < 100) {
      coord.z = component.z + component.height - movable.height
      break
    }
  }

  return coord.z
}