import React from 'react'
import Tree from "../Tree"
import Loading from "../Loading"
import FinishList from "./FinishList"
import clsx from 'clsx'
import './Finitions.scss'
import { useMainState } from '../../../../recoil/hooks'

type Props = { finishesLoad: boolean }

const Finitions: React.FC<Props> = ({finishesLoad}) => {
  const { selected } = useMainState()
  const { finitions } = useMainState()
  // const active = useMemo(() => selected && selected.type === PartType.COMPONENT , [selected])
  return (
    <div className={clsx("Finitions", "is-active")}>
      <div className="disclaimer">Sélectionnez un composant au préalable</div>
      {finitions ? <Tree finition catalogs={finitions.catalogs} component={FinishList}/> : (finishesLoad ? <Loading/> : null)}
    </div>
  )
}

export default Finitions