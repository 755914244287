import clsx from "clsx";
import React, { useEffect, useState } from "react";
import OpenConfiguration from "../../../utils/json/OpenConfiguration";
import request from "../../../utils/request";
import './OpenWork.scss'
import { useMainState } from "../../../recoil/hooks";

const OpenWork: React.FC = () => {
  const [name, setName] = useState("")
  const { updateConfiguration } = useMainState()

  useEffect(() => {
    const name =  (window as any).uniqId as string,
          data = (window as any).data3d as string
    if(data){
      setName(name)
      request(OpenConfiguration(name, data)).then(conf => {
        setName("")
        updateConfiguration(conf)
      })
    }
  }, [])

  return <div className={clsx("OpenWorkContainer", !name && 'closed')}>
    {
      <div className="OpenWork">
        {<h3>Ouverture en cours</h3>}
      </div>
    }
  </div>
}

export default OpenWork