import React, { useEffect, useMemo, useState } from 'react'
import './Tools.scss'
import { OrbitControls } from '@react-three/drei'
import Ground from '../../components/Three/Environment/Ground'
import Sky from '../../components/Three/Environment/Sky'
import { Luminary } from '../../../types'
import Light from './Light'
import { useControls, folder, button } from 'leva'
import LocalData from '../../../utils/LocalData'
import { useMainState } from '../../../recoil/hooks'

type Render = {
  pixelRatio: number,
  receiveShadow: boolean
}

const Controls = () => {
  const { updateState, configuration } = useMainState()
  const [ctrlData, setCtrlData] = useState<Luminary & Render>(LocalData['controls'])

  const mat = useControls({
    rendus: folder({
      "Qualité": { value: ctrlData ? ctrlData.pixelRatio : 1, min: .6, max: 2, step: .2 },
      "Ombre portée": { value: ctrlData ? ctrlData.receiveShadow : false },
    }),
    lumière: folder({
      "Luminosité": { value: ctrlData ? ctrlData.luminosity : 0, min: -.4, max: 1 },
      "Intensité": { value: ctrlData ? ctrlData.intensity : 0, min: -.4, max: 1 },
    }),
    Réinitialiser: button(e => {
      setCtrlData({
        pixelRatio: 1,
        luminosity: 0,
        intensity: 0,
        receiveShadow: false,
      })
    }),
    Fermer: button(e => {
      updateState({ controls: false })
    })
  }, [configuration, ctrlData])

  const options: Luminary = useMemo(() => {
    return (
      {
        luminosity: mat['Luminosité'],
        intensity: mat['Intensité'],
        receiveShadow: mat['Ombre portée']
      }
    )
  }, [mat])

  useEffect(() => {
    LocalData['controls'] = { ...options, pixelRatio: mat['Qualité'] }
    updateState({ luminary: options as Luminary, pixelRatio: mat['Qualité'], receiveShadow: mat['Ombre portée'] })
  }, [options])

  return null
}

const Tools = () => {
  const {
    rayon, orbitControl, grids, controls,
    luminary: { intensity, luminosity }, sky
  } = useMainState()
  return (
    <group>
      {controls && <Controls />}
      {
        orbitControl && <OrbitControls
          maxPolarAngle={Math.PI / 2.1}
          up={[0, 0, 1]}
          minDistance={500}
          maxDistance={25000}
          zoomSpeed={1.6}
        />
      }
      {grids && <Ground />}
      {grids && <gridHelper args={[100 * rayon, rayon / 2, '#F397D9', '#e6e6e6']} rotation={[Math.PI / 2, 0, 0]} />}
      <ambientLight intensity={.5 + luminosity} />
      <Light castShadow={grids} angle={Math.PI / 4} position={[10000, -10000, 20000]} intensity={.5 + intensity} />
      {/* <AccumulativeShadows frames={80} color="black" rotation={[Math.PI / 2 , 0, 0]} opacity={2} scale={20000} position={[0, 1.04, 0]}>
        <RandomizedLight amount={8} radius={5} ambient={0.8} position={[10000, -10000, 20000]} bias={0.001} />
      </AccumulativeShadows>
      <Environment preset="city" resolution={512} /> */}
      {sky && <React.Suspense><Sky /></React.Suspense>}
    </group>
  )
}
export default Tools