import session from "./session"

export default () => {
  return {
    command: {
      sessionId: session,
      name: "Copy",
      id: "190"
    }
  }
}

