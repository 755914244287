import { useFrame } from "@react-three/fiber";
import React, { FC, Ref, useEffect, useRef, useState } from "react";
import { BufferGeometry, Material, Mesh, Vector3 } from "three";
import { useRecoilState } from "recoil";
import { measureState } from "../../../../../../../atoms/MeasureState";
import { Point } from "../../../../../../../types";
import { useMainState } from "../../../../../../../recoil/hooks";
import { Intersects } from "..";
import { PartType } from "../../../../../../../types/parts";


type Props = {
	getNewPoint: (point: Vector3) => void
}

export const chunk = (array: number[], count: number) => {
	if (count == null || count < 1) return [];
	const result = [];
	let i = 0
	const length = array.length;
	while (i < length) {
		result.push(array.slice(i, i += count));
	}
	return result;
}

const Pointer: FC<Props> = ({ getNewPoint }) => {
	const { configuration } = useMainState()
	const [show, setShow] = useState<boolean>(false)
	const posDown = useRef<Point>({ x: 0, y: 0 })
	const measure = useRecoilState(measureState)

	const ref = useRef<Mesh<BufferGeometry, Material | Material[]>>()
	useFrame(({ camera, scene, raycaster, mouse }) => {
		const camDistance = camera.position.distanceTo(ref.current?.position as Vector3)
		const d = camDistance <= 500 ? 500 : camDistance
		scene.getObjectsByProperty("type", "MEASURE").forEach(inters => {
			const scale = d / 6500
			inters.scale.set(scale, scale, scale)
		})
		raycaster.setFromCamera(mouse, camera)
		const intersects = Intersects.intersections
		if (intersects.length && configuration?.components) {
			const { components } = configuration
			const { point: { x, y, z }, object } = intersects[0]
			const { name, geometry, type } = object as any
			const verticesData = [...new Set(chunk(geometry.attributes.position.array, 3).map(pos => {
				const vertex = new Vector3(...pos)
				object.localToWorld(vertex)
				return vertex
			}))]

			let allowedVerticesData: Vector3[] = []

			if(type === PartType.SUBPART) {
				allowedVerticesData = verticesData
			}	else {
				const xVertices = [] as number[]
				const yVertices = [] as number[]
				const zVertices = [] as number[]
				verticesData.forEach(vertice => {
					xVertices.push(vertice.x)
					yVertices.push(vertice.y)
					zVertices.push(vertice.z)
				})
				const xVer = {min: Math.min(...xVertices), max: Math.max(...xVertices)}
				const yVer = {min: Math.min(...yVertices), max: Math.max(...yVertices)}
				const zVer = {min: Math.min(...zVertices), max: Math.max(...zVertices)}
				
				allowedVerticesData = [
					new Vector3(xVer.min, yVer.min, zVer.min), new Vector3(xVer.min, yVer.min, zVer.max),
					new Vector3(xVer.max, yVer.min, zVer.min), new Vector3(xVer.max, yVer.min, zVer.max),
					new Vector3(xVer.max, yVer.max, zVer.min), new Vector3(xVer.max, yVer.max, zVer.max),
					new Vector3(xVer.min, yVer.max, zVer.min), new Vector3(xVer.min, yVer.max, zVer.max),
				]

			}

			setShow(true)
			const movable = new Vector3(x, y, z)
			const verticeArray = allowedVerticesData.map((p) => ({ point: p, distance: movable.distanceTo(new Vector3(p.x, p.y, p.z)) }))
			const nearestPoint = verticeArray.find(one => one.distance === Math.min(...verticeArray.map(p => p.distance)))
			if (nearestPoint && nearestPoint.distance <= 150) {
				const { x: xV, y: yV, z: zV } = nearestPoint.point
				ref.current?.position.set(xV, yV, zV ? zV : z)
			}
		} else {
			setShow(false)
		}
	})

	useEffect(() => {
		const canvas = document.querySelector('canvas') as HTMLCanvasElement

		const verifyClick = (e: MouseEvent) => {
			e.preventDefault()
			posDown.current = { x: e.clientX, y: e.clientY }
			e.stopPropagation()
		}

		const addPoint = (e: MouseEvent) => {
			e.preventDefault()
			const posUp: Point = { x: e.clientX, y: e.clientY }
			if (posUp.x === posDown.current.x && posUp.y === posDown.current.y) getNewPoint({ ...ref.current!.position } as Vector3)
			e.stopPropagation()
		}

		canvas.addEventListener('mousedown', verifyClick)
		canvas.addEventListener('mouseup', addPoint)

		return () => {
			canvas.removeEventListener('mousedown', verifyClick)
			canvas.removeEventListener('mouseup', addPoint)
		}
	}, [measure])

	return <mesh
		type="MEASURE"
		visible={show}
		ref={ref as Ref<Mesh<BufferGeometry, Material | Material[]>>}
	// onClick={() => {

	// }}
	>
		<sphereGeometry args={[50, 100, 20]} />
		<meshBasicMaterial color={'#FF5385'} />
	</mesh>
}

export default Pointer