import React, { useMemo } from 'react';
import { Number3, Space } from '../../../../types';
import './SpaceOrientation.scss';
import { useMainState } from '../../../../recoil/hooks';

type Props = {};

const labels = ['x', 'y', 'z'];

const SpaceOrientation: React.FC<Props> = () => {
  const { updateComponent, selected, configuration } = useMainState()

  const space = useMemo(() => {
    if (selected?.type === 'SPACE') return selected as Space
  }, [selected, configuration]);

  return (
    <div className="edit-zone">
      {space &&
        space.orientation.map((or, key) => (
          key === 2 && <div key={key} className="edit-item">
          <label className="item-label">{labels[key]}</label>
          <div className="inputs">
            <input
              type="number"
              className="item-input"
              readOnly={!space}
              value={space.orientation[key] || ''}
              onChange={({ target: { value } }) => {
                const orientation = [...space.orientation] as Number3;
                
                orientation[key] = parseInt(value) || 0;
                updateComponent(space, { orientation })
              }}
            />
          </div>
        </div>
        ))}
    </div>
  );
};

export default SpaceOrientation;
