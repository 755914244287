import Configuration from './configuration';
import { PartType } from './parts';
import { Vector3 } from "three";
export type Number3 = [number, number, number]
export type SMatrix = [
  number, number, number, number,
  number, number, number, number,
  number, number, number, number
]

export enum Action {
  PROPERTIES = 'properties',
  dimension = 'dimension',
  SPACES = 'spaces',
  finishing = 'finition',
  machining = 'usinage',
  connectors = 'connecteurs',
  handling = 'poignees',
  EMPTY = ''
}

export enum SpaceEditType {
  Position = 'position',
  Dimension = 'dimension',
  Orientation = 'orientation'
}

export type CMenu = { x: number, y: number, done?: boolean}

export enum FinishesType {
  GLOBAL = 'global',
  DEBIT = 'merged',
  SINGLE = 'single'
}

export type FullMatrix = [
  number, number, number, number,
  number, number, number, number,
  number, number, number, number,
  number, number, number, number
]

export type ComponentPart = {
  id: number,
  name: string,
  parent: number,
  parentItem?: ComponentPart,
  type: PartType,
  component?: Component
}

export type Representation = {
  id: number,
  matrix: SMatrix
}

// export type Connector = {
//   colladaFileName: string,
//   id: number,
//   représentations: Representation[],
//   scale: number
// }
//TODO: From WCMODELER
export type Connector = ComponentPart & {
  collada: string,
  scale: number,
  matrix: SMatrix,
}

export type ForeignComponent = ComponentPart & {
  collada: string,
  color: string,
  matrix: SMatrix,
  accessory: ComponentPart
}

type DrawerComponent = Component & {

}

// export type SubPart = {
//   code: string,
//   connectors: Connector[],
//   id: number,
//   orientation: Number3,
//   position: Number3,
//   size: Number3,
//   type: PartType,
//   assembly: Assembly
// }
//TODO: From WCMODELER
export type SubPart = ComponentPart & {
  code: string,
  orientation: Number3,
  position: Number3,
  size: Number3,
  assembly: Assembly
}

export enum SpaceType {
  FLOOR = "0",
  WALL = "1"
}

export type Zone = ComponentPart & {
  childrenList: number[],
  orientation: Number3,
  position: Number3,
  selectable: boolean,
  size: Number3,
  visible: boolean
}

export type Space = ComponentPart & {
  color: string,
  orientation: Number3,
  position: Number3,
  size: Number3,
  spaceType: string,
  multiplier?: Number3
}

export type Finish = {
  code: string,
  path: string,
  label?: string
}

export type Assembly = ComponentPart & {
  finish: Finish,
  finishType: string,
  component: Component
}

// export type Component = ComponentPart & {
//   // children: (Zone | Assembly)[]
//   zones: Zone[],
//   assemblies: Assembly[],
//   parts: SubPart[],
//   accessories: ForeignComponent[],
//   configuration: Configuration,
//   components: Component[],
//   multiplier?: Number3
// }

export type Component = ComponentPart & {
  // children: (Zone | Assembly)[]
  zones: Zone[],
  parts: SubPart[],
  accessories: ForeignComponent[],
  configuration: Configuration,
  components: Component[],
  multiplier?: Number3,
  orientation: Number3,
  position: Number3,
  size: Number3,
  readOnly: boolean,
  connectors: Connector[]
}

export type ServiceConfig = {
  Selection: Partial<Selection>,
  components: ComponentPart[],
}


export type MachinedPiece = { id: number, collada: string, component: number, matrix?: SMatrix }

export type CatalogItem = {
  code: string,
  path: string,
  size: Number3,
  name: string,
  image: string,
  parent?: Catalog
}

export type PresetItem = {
  thickness: number,
  item: CatalogItem,
  type?: string
}

export type CatalogItemPlus = CatalogItem & {thickness: number}

export type Catalog = {
  image?: string,
  label: string,
  uuid: string,
  catalogs: Catalog[],
  items: CatalogItem[],
  parent?: Catalog,
  selected?: CatalogItem
}

export type Vertices = {
  topLeft: Point,
  topLeftZ?: Point,
  topRight: Point,
  topRightZ?: Point,
  bottomLeft: Point,
  bottomLeftZ?: Point,
  bottomRight: Point,
  bottomRightZ?: Point,
  center?: Point
}

export type AppContextType = {
  configuration?: Configuration,
  commands: number[],
  selected?: ComponentPart,
  selectedTarget?: number,
  daeWardrobe?: CatalogItem,
  models?: Catalog,
  modules?: Catalog,
  finitions?: Catalog,
  cameraPos?: Vector3,
  contextMenu?: CMenu,
  rayon: number,
  move: boolean,
  rotate: boolean,
  opacity: boolean,
  orbitControl: boolean,
  positionAssigned: { [id: number]: Vertices },
  transparents: number[],
  hidden: number[],
  grids: boolean,
  zones: boolean,
  openConfig: boolean,
  pixelRatio: number,
  receiveShadow: boolean,
  luminary: Luminary,
  controls: boolean,
  activePopup: Action,
  measure: boolean,
  sky: boolean
}

export type Luminary = {
  luminosity: number,
  intensity: number,
}

export type Properties = {
  position: Number3,
  size: Number3
}



export type Point = {
  x: number, y: number, z?: number
}

export type Preference = {
  comment: string, label: string, name: string, type: string, group: string,
  visible: boolean, values?: [],
  value: number | boolean | string,
  oldValue: number | boolean | string
}

export type PreferenceGroup = {
  children: Preference[],
  label: string
}

export type Line = {
  p1: Point;
  p2: Point;
  angle: (number | 0)
}

export type LineForSnap = [Line, Line, Line, Line]


export type PanelStock = {
  available: number[],
  nonAvailable: number[],
  width: { [k: string]: number[] },
  depth: { [k: string]: number[] },
  edges: number[]
}

export type ChantStock = {
  [type: string]: { [thickness: string]: number[] }
}


export type Stock = {
  panels: {
    [code: string]: PanelStock
  },
  chants: {
    [code: string]: ChantStock
  }
}