import { Line } from "@react-three/drei";
import React from "react";
import { Vector3 } from "three";

type Props = {
  p1: Vector3,
  p2: Vector3
}
const LineMeasure: React.FC<Props> = ({p1:{x: x1,y: y1,z: z1}, p2:{x: x2,y: y2,z: z2}}) => {
  return <Line points={[[x1, y1, z1], [x2, y2, z2]]} color={'#FF5385'} depthTest={false}/>
}

export default LineMeasure