import session from "./session";

export default (range: string, finishSet: string = "", finishType: string = "Panel") => {
  return {
    command:{
      sessionId: session,
      name: "GetAvailableFinishesTree",
      id: "522",
      params: {
        finishType: finishType,
        range: range,
        finishSet: finishSet
      }
    }
  }
}