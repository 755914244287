import session from "./session"

export default () => {
  return {
    command: {
      sessionId: session,
      name: "Delete",
      id: "110"
    }
  }
}