import React, { useEffect, useMemo, useRef, useState } from "react"
import * as THREE from 'three'
import { ColladaLoader } from "three/examples/jsm/loaders/ColladaLoader"
import { useFrame, useLoader, useThree } from '@react-three/fiber'
import { Number3, Point } from "../../../../types"
import { Validator } from "../Cube"
import { toDegree } from "../../../../utils/toMatrix"
import { degToRad } from "three/src/math/MathUtils"
import { calculateRectangleVertices, toGlued } from "./utilsForSnap"
import { componentToEquations, snapForPoint, tryToSnap } from "./snap2"
import { configurationState, daeWardRobeState, positionAssignedState } from "../../../../recoil/contextState"
import { useRecoilState, useRecoilValue } from "recoil"
import { urlModifier } from "../../../../utils/functions"
import { useMainState } from "../../../../recoil/hooks"

type Props = {
  validation: (validate: Validator) => void
}
type Mesh = THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>

const Collada: React.FC<Props> = ({ validation }) => {
  const positionAssigned = useRecoilValue(positionAssignedState)
  const [daeWardrobe, setdaeWardrobe] = useRecoilState(daeWardRobeState)
  const configuration = useRecoilValue(configurationState)
  const { grids } = useMainState()

  const ref = useRef<Mesh>()
  const shifted = useRef<boolean>(false)
  const rotateDirection = useRef(0)
  const [rotation, setRotation] = useState(0)
  const [inMove, setInMove] = useState(true)
  const { scene } = useLoader(ColladaLoader, daeWardrobe!.path, loader => {
    loader.manager.setURLModifier(urlModifier)
  })

  const sceneClone = useMemo(() => scene.clone(), [scene])
  const spaces = useMemo(() => configuration ? configuration.spaces : undefined, [configuration])

  const allPoints = useMemo<THREE.Vector3[]>(() => {
    if (positionAssigned && spaces) {
      const componentsVertices = Object.values(positionAssigned)
      const spacesVertices = spaces.map(({ position, size, orientation }) => calculateRectangleVertices(position.map((item, i) => i === 2 ? 0 : item) as Number3, size, degToRad(orientation[2])))
      return [...componentsVertices, ...spacesVertices].reduce((acc: THREE.Vector3[], item) => {
        Object.values(item).forEach((elem: Point) => acc.push(new THREE.Vector3(elem.x, elem.y, elem.z ? elem.z : 0)))
        return acc
      }, [] as THREE.Vector3[])
    }
    return []
  }, [positionAssigned, spaces])

  useFrame(({ mouse, raycaster, camera }) => {
    if (inMove) {
      raycaster.setFromCamera(mouse, camera)
      const vec = new THREE.Vector3()
      const pos = new THREE.Vector3()
      let snapPointFound = false

      vec.set((mouse.x), (mouse.y), 0)
      vec.unproject(camera)
      vec.sub(camera.position).normalize()
      const distance = - camera.position.z / vec.z
      pos.copy(camera.position).add(vec.multiplyScalar(distance))
      const point = new THREE.Vector3(pos.x, pos.y, pos.z)
      if (rotateDirection) setRotation(rotation + rotateDirection.current * 0.01)
      if (point) {
        const holder: { x: number, y: number } = { x: 0, y: 0 }
        const [cWidth, cDepth, cHeight] = daeWardrobe!.size
        let { x, y } = point
        x += cWidth / 2 - cWidth
        y += cDepth / 2
        const daeVertices = calculateRectangleVertices([x, y, 0], daeWardrobe!.size, ref.current?.rotation?.y || 0)

        if (shifted.current) {
          const values = configuration ? tryToSnap(daeVertices, [
            ...configuration.components,
            ...configuration.spaces
          ].map(componentToEquations)) : { x, y, z: 0 }

          if (allPoints.length) {
            const coord = {} as Point
            for (let i = 0, vertices = Object.values(daeVertices); i < vertices.length && !snapPointFound; i++) {
              const { x: vX, y: vY, z: vZ } = vertices[i]
              const verticeTypes = Object.keys(daeVertices)
              const pointVertice = new THREE.Vector3(vX, vY, vZ)
              const pointSnap = allPoints.find(one => one.distanceTo(pointVertice) < 150)
              if (pointSnap) {
                snapPointFound = true
                const { x: sX, y: sY, z: sZ } = snapForPoint(pointSnap, daeVertices, verticeTypes[i], ref.current?.rotation?.y || 0)
                coord.x = sX
                coord.y = sY
                coord.z = sZ
              }
            }
            if (coord.x !== undefined && coord.y !== undefined) ref.current!.position.set(coord.x, coord.y, coord.z ? coord.z : 0)
            else {
              ref.current!.position.set(x, y, point.z < 0 ? 0 : point.z)
            }
          }

          // if(values) {
          if (values && !snapPointFound) {
            ref.current!.position.set(values.x, values.y, point.z < 0 ? 0 : point.z)
          }
        }
        else {
          x = holder.x = toGlued(x)
          y = holder.y = toGlued(y)
          ref.current!.position.set(x, y, point.z < 0 ? 0 : point.z)
        }
      }
    }
  })

  useEffect(() => {
    const on = (e: KeyboardEvent) => {
      const sh = e.code.toLowerCase().indexOf('shift') >= 0
      if (sh) shifted.current = true
      if (e.code === 'ArrowLeft') rotateDirection.current = -1
      else if (e.code === 'ArrowRight') rotateDirection.current = 1
    }
    const off = (e: KeyboardEvent) => {
      const ok = e.code.toLowerCase().indexOf('shift') >= 0
      if (ok) shifted.current = false
      rotateDirection.current = 0
    }
    document.addEventListener('keydown', on)
    document.addEventListener('keyup', off)
    return () => {
      document.removeEventListener('keydown', on)
      document.removeEventListener('keyup', off)
    }
  }, [])

  useEffect(() => {
    if (daeWardrobe) {
      const canvas = document.querySelector('canvas')
      const pmesh = ref.current!
      let position: Number3
      const ok = () => {
        setInMove(false)
        const [cWidth, cDepth, cHeight] = daeWardrobe!.size
        const valide: Validator = {
          name: daeWardrobe.name,
          code: daeWardrobe.code,
          x: pmesh.position.x,
          y: pmesh.position.y,
          z: pmesh.position.z,
          zAngle: toDegree(ref.current?.rotation?.y || 0)
        }
        validation(valide)
        canvas?.removeEventListener('mousedown', ok)
      }
      canvas?.addEventListener('mousedown', ok)
      return () => canvas?.removeEventListener('mousedown', ok)
    }
    if (ref.current) ref.current.visible = true
    setInMove(true)
  }, [daeWardrobe])

  return (
    <mesh ref={ref as React.Ref<Mesh>} castShadow={grids} receiveShadow={grids} scale={4 * 250} rotation={[Math.PI / 2, 0 + rotation, 0]}>
      <primitive object={sceneClone} />
      <meshPhongMaterial attach={"material"} color="hotpink" />
    </mesh>
  )
}

export default Collada