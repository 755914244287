import Pako from "pako"
import { SetterOrUpdater } from "recoil"
import { MachinedPiece } from "../../../../../../types"
import Configuration from "../../../../../../types/configuration"
import GetMachinedParts from "../../../../../../utils/json/GetMachinedParts"
import request from "../../../../../../utils/request"
import { Popup, PopupType } from "../../../Collada/Popup"
import { topestComponent } from "../../../../../../utils/functions"

export const Pieces = {update: async (config: Configuration) => {}}

export const base64ToFileUrl = (base64: string) => {
  var strData = window.atob(base64);
  var charData = strData.split('').map(function (x) { return x.charCodeAt(0) }).filter((val, i) => i > 3)
  const buffer = Pako.inflate(new Uint8Array(charData))
  const file = new File([buffer], 'truc.dae')
  return URL.createObjectURL(file)
}

const toPieces = (config: Configuration, machinedColladas: any) => {
  return machinedColladas.map(({dataZippedCollada: base64, id: pId}: {dataZippedCollada: string, id: string}) => {
    const id = parseInt(pId)
    const collada = base64ToFileUrl(base64)
    const component = topestComponent(id, config)
    return {id, collada, component: component!.id} as MachinedPiece
  })
}

export const getMachinedAfterUpdatePart = async () => {
  const cancel = Popup.popup({ message: `Obtention des pièces 3D`, type: PopupType.WAITING })
  const { machinedCollada: parts } = (await request(GetMachinedParts())) as {
    machinedCollada: {
      dataZippedCollada: string,
      id: string
    }[]
  }
  cancel()
  return parts
}

export const updatePiecesPart = async (config: Configuration, updatePartPieces: SetterOrUpdater<MachinedPiece[]>) => {
  const parts = await getMachinedAfterUpdatePart()
  if (parts) {
    const pieces = toPieces(config, parts)
    updatePartPieces(pieces)
  }
}