import session from "./session"

export default (range?: string, global = true) => {
  const params: any = { type: "Component" }
  if(global){
    if((window as any).listeGammes) params.ranges = (window as any).listeGammes
    else params.range = range || 'WoodConfigurator online'
  }
  if(!params.ranges && !params.range) params.range = range
  return {
    command: {
      sessionId: session,
      name: "GetComponentCatalogTree",
      id: "305",
      params
    }
  }
}
