import clsx from 'clsx'
import React, {CSSProperties, useEffect} from 'react'
import { CatalogItem } from '../../../../../types'
import { pubUrl } from '../../../../../utils/functions'
import './ColladaList.scss'
import { useMainState } from '../../../../../recoil/hooks'
import { useRecoilState } from 'recoil'
import { modelsState } from '../../../../../recoil/contextState'

type Props = { items: CatalogItem[] }

const ColladaList: React.FC<Props > = ({ items }) => {
  const { updateState, daeWardrobe } = useMainState()
  const [models, setModels] = useRecoilState(modelsState)

  useEffect(() => {
    if (models) {
      setModels({...models, selected: daeWardrobe})
    }
    if (daeWardrobe) {
      const cancel = (e: KeyboardEvent) => {
        if (e.code === 'Escape') updateState({daeWardrobe: undefined})
      }
      document.addEventListener('keydown', cancel)
      return () => document.removeEventListener('keydown', cancel)
    }
  }, [daeWardrobe])
  
  
  return (
    <div className='ColladaList'>
      {
        items.map((item, index) => {
          const {code, image} = item
          return (
            <div
              key={index}
              className={clsx('collada-item', { selected: daeWardrobe?.code === code })}
              onClick={() => {
                updateState({daeWardrobe: item})
              }}
            >
              <div className="ci-container" style={{'--image': `url("${image}")`} as CSSProperties}>
                <span>{code}</span>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default ColladaList