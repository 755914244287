import './App.scss'
import Header from '../containers/Header'
import Scene from '../containers/Scene'
import { AppProvider} from '../../context'
import { RecoilRoot } from 'recoil'

function App() {

  return (
    <RecoilRoot>
      <AppProvider>
        <Header/>
        <Scene/>
      </AppProvider>
    </RecoilRoot>
  )
}

export default App
