import React, { useEffect, useMemo, useState } from 'react'
import { Number3, Space, SpaceType } from '../../../../types'
import { PartType } from '../../../../types/parts'
import './SpaceDimensions.scss'
import { useMainState } from '../../../../recoil/hooks'

type Props = {}

const labels = ['Largeur', 'Profondeur', 'Hauteur']
const wallLabels = ['Largeur', 'Hauteur', 'Profondeur']

const SpaceDimension: React.FC<Props> = () => {
  const {selected, selectedTarget, configuration, updateMultiplier} = useMainState()
  const [multiplier, setMultiplier] = useState<Number3>([1, 1, 1])

  const space = useMemo(() => {
    if (selected) {
      const space: Space = selected as Space
      if (space?.type === PartType.SPACE) {
        setMultiplier(space.multiplier ? [...space.multiplier] : [1, 1, 1])
        return space as Space
      }
    }
  }, [selectedTarget, selected])
  
  useEffect(() => {
    if(space) updateMultiplier(space, multiplier)
  }, [...multiplier])

  return (
    <div className="edit-zone">
      {
        (() => {
          const isWall = space?.spaceType  === SpaceType.WALL
          const dims = space && space.size.map((dimension, key) => {
            const value = Math.round(dimension * multiplier[key])
            return <div key={key} className="edit-item">
            <label className="item-label">{(isWall ? wallLabels : labels)[key]}</label>
            <div className="inputs">
              <input
                type="number"
                className="item-input"
                readOnly={!space}
                value={value || ''}
                onChange={({ target: { value: val } }) => {
                  const mult = [...multiplier]
                  // const myVal = val || '0'
                  mult[key] = Number(val) / dimension
                  setMultiplier([...mult] as Number3)
                }}
              />
            </div>
          </div>
          })
          return isWall || !dims ? dims : dims.reverse()
        })()
      }
    </div>
  )
}

export default SpaceDimension;
