import React, { useCallback, useEffect } from 'react'
import clsx from 'clsx'
import './Scene.scss'
import { Canvas } from '@react-three/fiber'
import Tools from '../Tools'
import Cube from '../../components/Three/Cube'
import SideMenu from '../../components/SideMenu'
import ContextMenu from '../../components/ContextMenu'
import OpenWork from '../../components/OpenWork'
import Handler from './Handler'
import DimensionInfo from '../../components/DimensionInfo'
import PriceInput from '../../components/PriceInput'
import Save from '../Header/CamPosSaving'
import Properties from '../Properties'
import PopupComponent, { Popup, PopupType } from '../../components/Three/Collada/Popup'
import { useMainState } from '../../../recoil/hooks'
import { Action } from '../../../types'
import SpaceEditorComponent from '../../components/Space'
import DisconnectedComponent from '../../components/Disconnected'
import request from '../../../utils/request'
import SelectObject from '../../../utils/json/SelectObject'

const prevent = (e: MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

const Scene = () => {
  const { updateState, pixelRatio, activePopup, grids } = useMainState()

  const setting = useCallback((option: string) => {
    if (option === 'save') {
      updateState({ openConfig: false })
    } else if (option === 'open') {
      updateState({ openConfig: true })
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const code = event.key.toLocaleLowerCase();
      if ((event.ctrlKey || event.metaKey) && code === 's') {
        event.preventDefault();
        setting('save')
      } else if ((event.ctrlKey || event.metaKey) && code === 'o') {
        event.preventDefault();
        setting('open')
      }
    };
    const html = document.querySelector('html') as HTMLHtmlElement
    html.addEventListener('contextmenu', prevent)

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      html.removeEventListener('contextmenu', prevent)
    }
  }, []);

  return (
    <div className={clsx('Scene')}>
      <PopupComponent />
      <ContextMenu />
      <OpenWork />
      <DisconnectedComponent />
      <Canvas
        // shadows={'soft'}
        shadows={grids}
        camera={{ fov: 50, near: 1, far: 100000, position: [-4000, -4000, 2000], up: [0, 0, 1] }}
        gl={{ preserveDrawingBuffer: true }} dpr={pixelRatio}
        style={{background: '#F2F4F7'}}
        onPointerMissed={missed => {
          const cancel = Popup.popup({ message: "Desélection", type: PopupType.WAITING })
          request(SelectObject(0)).then(res => {
            updateState({selected: undefined, selectedTarget: undefined})
            cancel()
          })
        }}
      >
        
        <Save />
        <Handler>
          <Tools />
          <Cube />
        </Handler>
      </Canvas>
      {activePopup === Action.PROPERTIES && <Properties />}
      {activePopup === Action.SPACES && <SpaceEditorComponent />}
      <DimensionInfo />
      <PriceInput />
      <SideMenu />
    </div>
  )
}
export default Scene