import session from "./session"

export type MoveParams = { x: number, y: number, z: number, zAngle: number }

export default (params: MoveParams) => {
  return {
    command: {
      sessionId: session,
      name: "MoveSelection",
      id: "560", params
    }
  }
}

