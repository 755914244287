import * as React from 'react'
import { fillFinishesContent, fillModelsContent, initConfiguration } from '../utils/functions';
import { useMainState } from '../recoil/hooks';
import { Load } from '../views/components/SideMenu';



type Props = { children?: React.ReactNode }

export const AppProvider: React.FC<Props> = ({children}) => {
  const {updateState} = useMainState()

  React.useEffect(() => {
    (async () => {
      const configuration = await initConfiguration()
      updateState({ configuration })
      fillModelsContent(configuration.range).then(models => updateState({models}))
      Load.setFinishesLoad(true)
      fillFinishesContent(configuration.range).then(finitions => {
        updateState({ finitions })
        Load.setFinishesLoad(false)
      })
    })()
  }, [])
  return (
    <React.Fragment>
      { children }
    </React.Fragment>
  )
}