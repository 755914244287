import React, { useEffect, useState } from "react"
import HeadOption from "../HeadOption"
import './Settings.scss'
import About from "./About"
import { Leva } from "leva"
import { useMainState } from "../../../../recoil/hooks"


type Props = {
  active: boolean,
  className?: string
}


const Settings: React.FC<Props> = ({ active }) => {
  const [about, setAbout] = useState(false)
  const { controls, updateState } = useMainState()
  useEffect(() => { active && setAbout(false) }, [active])

  return (
    <>
      <div >
        <Leva theme={
          {
            colors: {
              elevation1: 'rgba(66, 66, 66)',
              elevation2: '#fff',
              elevation3: '#96357422',
              accent1: '#424242',
              accent2: '#ce5aa6',
              accent3: '#ce5aa6',
              highlight1: '#fff',
              highlight2: '#424242',
              highlight3: '#fff',
              folderTextColor: "#963574",
              vivid1: '#ffcc00',
              folderWidgetColor: "#96357466"
            }
          }
        } />
      </div>

      <HeadOption
        active={active}
        className={"Settings"}
        title={about ? "à propos" : "Parametre"}
      >
        {
          about ? <About /> : <div className="s-options">
            <div>
              <span>ARRIERE PLAN SCENE</span>
            </div>
            <div
              onClick={() => updateState({ controls: !controls })}
            >
              <span>RENDU AVANCÉ</span>
            </div>
            <div
              onClick={() => setAbout(true)}
            >
              <span>A PROPOS</span>
            </div>
          </div>
        }
      </HeadOption>
    </>
  )
}

export default Settings