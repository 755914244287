import React, { CSSProperties, RefObject, useEffect, useMemo, useRef, useState } from "react";
import { PartType } from "../../../types/parts";
import Copy from "../../../utils/json/Copy";
import Delete from "../../../utils/json/Delete";
import Paste from "../../../utils/json/Paste";
import SelectObject from "../../../utils/json/SelectObject";
import request from "../../../utils/request";
import "./ContextMenu.scss"
import { useMainState } from "../../../recoil/hooks";
import { Action } from "../../../types";
import clsx from "clsx";
import { Price } from "../PriceInput";
import { Pieces } from "../Three/Cube/Components/Parts/partPiecesUtils";


const ContextMenu = () => {
  const ref = useRef<HTMLDivElement>()
  const [pos, setPos] = useState<{ x: number, y: number }>()
  const {
    contextMenu, selectedTarget, selected, updateState,
    transparents, hidden, updateConfiguration, configuration,
    rotate, move
  } = useMainState()

  useEffect(() => {
    if (contextMenu) {
      setPos(contextMenu)
      ref.current?.focus()
    }
  }, [(contextMenu?.x) || 0 * (contextMenu?.y || 0)])

  useEffect(() => {
    const init = () => updateState({ contextMenu: undefined })
    ref.current && ref.current.addEventListener('blur', init)
    return () => ref.current?.removeEventListener('blur', init)
  }, [ref.current])

  const canPaste = useMemo(() => {
    if(selectedTarget){
      return configuration!!.items[selectedTarget].type === PartType.ZONE
    }
    return false
  }, [selectedTarget])

  return (
    <div
      className={clsx("ContextMenu", {waiting: !contextMenu?.done})}
      ref={ref as RefObject<HTMLDivElement>}
      tabIndex={1}
      style={{
        '--x': `${pos?.x || 0}px`,
        '--y': `${pos?.y || 0}px`
      } as CSSProperties}
    >
      {
        !selected?.component && !move && <div
          className="context-item"
          onClick={() => {
            updateState({ move: true, rotate: false })
            ref.current?.blur()
          }}
        ><span>Déplacer</span></div>
      }

      {
        !selected?.component && !rotate && <div
          className="context-item"
          onClick={() => {
            updateState({ rotate: true, move: false })
            ref.current?.blur()
          }}
        ><span>Pivoter</span></div>
      }

      <div
        className="context-item"
        onClick={() => {
          if (selected) {
            if (transparents.includes(selected.id)) {
              updateState({ transparents: transparents.filter(id => id !== selected.id) })
            }
            else {
              updateState({ transparents: [...transparents, selected.id] })
            }
          }
          ref.current?.blur()
        }}
      ><span>{transparents.includes(selected?.id || 0) ? "Opaque" : "Transparent"}</span></div>
      <div
        className="context-item"
        onClick={() => {
          if (selected) {
            updateState({ hidden: [...hidden, selected.id] })
          }
          ref.current?.blur()
        }}
      ><span>{"Cacher"}</span></div>
      {
        selected?.type === PartType.COMPONENT && <div
          className="context-item"
          onClick={() => {
            updateState({activePopup: Action.PROPERTIES})
            ref.current?.blur()
          }}
        ><span>{"Propriétés"}</span></div>
      }

      <div
        className="context-item"
        onClick={() => {
          const nested = Boolean(selected?.component)
          request(Delete()).then(res => {
            const state = updateConfiguration(res)
            if(nested && state && state?.configuration){
              // Price.update()
              Pieces.update(state?.configuration)
            }
          })
          ref.current?.blur()
        }}
      ><span>Supprimer</span></div>
      <div
        className="context-item"
        onClick={() => {
          const li = document.querySelector('.Header li.size') as HTMLDivElement | undefined
          li?.click()
          ref.current?.blur()
        }}
      ><span>Dimensions</span></div>

      {
        !!selected && selected.type === "ZONE" && <div
          className="context-item"
          onClick={async () => {
              request(Copy()).then(console.log)
              ref.current?.blur()
          }}
        ><span>Copier</span></div>
      }
      {
        canPaste && <div
          className="context-item"
          onClick={async () => {
            if (selected) {
              ref.current?.blur()
              await request(SelectObject(selectedTarget!!))
              await request(Paste()).then(updateConfiguration)
            }
          }}
        ><span>Coller</span></div>
      }
    </div>
  )
}

export default ContextMenu