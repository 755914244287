import { Preference } from "../../types"
import session from "./session"

export default (name: string, value: Preference['value'], isForced: boolean = true) => {
  return {
    command: {
      sessionId: session,
      name: "SetSelectionParameters",
      id: "680",
      params: {
        name,
        value: '' + value,
        isForced: isForced ? 'True' : 'False'
      }
    }
  }
}