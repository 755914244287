import React, { useCallback, useEffect, useState } from "react";
import './MultiChoice.scss'
import clsx from "clsx";


type Props = Omit<JSX.IntrinsicElements['div'], 'onChange'> & {
  onChange: (value: string) => void
  value: string,
  values?: string[]
}

const defValues = ['OUI', 'NON']

const MultiChoice: React.FC<Props> = ({values = defValues, value, onChange, ...props}: Props) => {

  return (
    <div className='bool-part' {...props}>
      {
        values.map((val, i) => {
          return <div
            key={i}
            className={clsx("bp-item", { 'active': value === val })}
            onClick={() => onChange(val)}
          >{val}</div>
        })
      }
    </div>
  )
}

export default MultiChoice