import Tree from "../Tree"
import React, { useCallback, useMemo } from 'react'
import {clsx} from 'clsx'
import { PartType } from '../../../../types/parts'
import ModuleList from './ModuleList'
import './Modules.scss'
import Loading from "../Loading"
import { Catalog, CatalogItem } from "../../../../types"
import LocalData from "../../../../utils/LocalData"
import { useMainState } from "../../../../recoil/hooks"

const saveNewContents = (catalogs: Catalog[]) => {
  return catalogs.forEach(catalog => {
    const {items, uuid} = catalog
    LocalData[`modules.${uuid}`] = items
  })
}
const updateContentFromSaved = (catalogs: Catalog[]) => {
  return catalogs.map(catalog => {
    const {items, uuid} = catalog
    const saved = (LocalData[`modules.${uuid}`] || []) as CatalogItem[]
    items.splice(0, items.length, ...saved)
    return catalog
  })
}

type Props = {
  modulesLoad: boolean
}

const Modules: React.FC<Props> = ({modulesLoad}) => {
  const { modules, configuration, selected } = useMainState()
  
  const update = useCallback(async (catalogs: Catalog[]) => {
    // updateContentFromSaved(catalogs)
    // updateState({ modules: {...modules} as Catalog })
    // for (const catalog of catalogs) {
    //   try{ await fillItems(catalog) } 
    //   catch(err){ console.log(err, 'CANNOT GET DATA') }
    // }
    // updateState({ modules: {...modules} as Catalog })
    // saveNewContents(catalogs)
  }, [modules])

  const active = useMemo(() =>  selected?.type === PartType.ZONE, [selected]) // || selected?.component
  return (
    <div className={clsx("Modules", active && "is-active")}>
      <div className="disclaimer">Sélectionnez une zone 3D au préalable</div>
      {
        !!modules ? <Tree update={update} component={ModuleList} catalogs={[modules]}/> : (modulesLoad ? <Loading/> : null)
      }
    </div>
  )
}


export default Modules