// TODO: from WCMODELER:  import { Actions, AppContextType, Theme } from "../../types";

import { Action, AppContextType, Luminary } from "../../types";
import LocalData, { DATA, Options } from "../../utils/LocalData";
import { addParents } from "../../utils/functions";

const finitions = LocalData[DATA.FINITIONS]
const models = LocalData[DATA.MODELS]
finitions && addParents(finitions)
models && addParents(models)

// const queryParameters = new URLSearchParams(window.location.search);

export default {
  commands: [],
  selectedTarget: undefined,
  configuration: undefined,
  daeWardrobe: undefined,
  models,
  // modules: LocalData[DATA.MODULES],
  // finitions,
  contextMenu: undefined,
  move: false,
  rotate: false,
  opacity: false,
  orbitControl: true,
  positionAssigned: [],
  transparents: [],
  hidden: [],
  zones: true,
  openConfig: true,
  spaces: undefined,
  activeTab: Options.SPACE,
  controls: false,
  pixelRatio: 1,
  activePopup: Action.EMPTY,
  receiveShadow: false,
  measure: false,
  luminary: LocalData['controls']? LocalData['controls'] as Luminary : {
    luminosity: 0,
    intensity: 0,
  },
  rayon: 200,
  grids: true,
  sky: false
} as AppContextType

// export default {
//   models: LocalData['models'],
//   modules: LocalData['modules'],
//   finitions: LocalData['finitions'],
//   commands: [],
//   rayon: 200,
//   orbitControl: true,
//   positionAssigned: [],
//   rotate: false,
//   move: false,
//   daeWardrobe: undefined,
//   opacity: false,
//   transparents: [],
//   hidden: [],
//   grids: true,
//   zones: true,
//   save: false,
//   openConfig: true,
//   controls: false,
//   pixelRatio: 1,
//   activeWindow: false, // TODO: atao false 
//   receiveShadow: false,
//   measureMode: false,
//   luminary: LocalData['controls']? LocalData['controls'] as Luminary : {
//     luminosity: 0,
//     intensity: 0,
//   },
// } as AppContextType
